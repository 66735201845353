import React, { useState } from 'react';
import { OnChangeData } from '@carbon/react/lib/components/Dropdown/Dropdown';
import S from './styles';
import { IProps } from './types';

function ComboBox<T>({
	id,
	className,
	error,
	disabled,
	required,
	label,
	selectedItem,
	items,
	onChange,
	onInputChange,
	itemToString,
}: IProps<T>): React.ReactElement {
	const [focused, setFocused] = useState(false);

	const onFocus = () => {
		setFocused(true);
	};

	const onBlur = () => {
		if (!selectedItem) {
			setFocused(false);
		}
	};

	const _onChange = (onChangeData: OnChangeData<T>) => {
		onChange(id, onChangeData.selectedItem);
	};

	let filledInputClassName = className ? `${className} ` : '';
	if (focused || selectedItem) {
		filledInputClassName += 'filled';
	}
	let statusClassName = '';
	if (error) {
		statusClassName += ' invalid';
	}
	if (disabled) {
		statusClassName += ' disabled';
	}

	return (
		<S.ComboBox
			id={id}
			items={items}
			className={filledInputClassName + statusClassName}
			invalid={Boolean(error)}
			invalidText={error}
			$required={required}
			disabled={disabled}
			titleText={label}
			selectedItem={selectedItem}
			onChange={_onChange}
			onInputChange={onInputChange}
			label={' '}
			itemToString={itemToString}
			onFocus={onFocus}
			onBlur={onBlur}
			autoComplete="off"
		/>
	);
}

export default ComboBox;

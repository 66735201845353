// External imports
import styled from 'styled-components';
// @ts-expect-error: @carbon/react doesn't have full typescript support yet
import { TextInput } from '@carbon/react';
// Local imports
import { IStyledInputProps } from './types';
import { ClearInputIcon, InputContainer } from '../CustomCarbonCommons';

const Input = styled(TextInput)<IStyledInputProps>`
	position: relative;

	& > .cds--text-input__label-wrapper {
		position: absolute;
		margin: 0;
		line-height: 0;
		height: 2rem;
		z-index: 1;
		top: 17.5px;
		left: 20px;
		pointer-events: none;
		transition: transform 0.3s;

		& > .cds--label {
			color: ${({ theme }) => theme.colors.textoGrisPrimario};
			${({ theme }) => theme.typography.p3};
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: calc(100% - 55px);

			transition: color 0.3s, width 0.3s;

			&::after {
				content: '*';
				color: ${({ theme }) => theme.colors.textoNaranjaSecundario};
				margin-left: 5px;
				display: ${({ $required }) => ($required ? 'inline-block' : 'none')};
			}
		}
	}

	&.filled > .cds--text-input__label-wrapper {
		transform: translate(-7.3%, -0.5rem) scale(0.85);
		& > .cds--label {
			width: calc(100% - 20px);
			color: ${({ theme }) => theme.colors.textoGrisTerciario};
		}
	}

	&.invalid > .cds--text-input__label-wrapper {
		& > .cds--label {
			color: ${({ theme }) => theme.colors.alertasError};
			&::after {
				color: ${({ theme }) => theme.colors.alertasError};
			}
		}
	}

	& .cds--text-input__field-wrapper {
		& > svg {
			// hide error icon
			display: none;
		}

		& > input.cds--text-input {
			padding: 18px 55px 0 20px;
			height: 3.44rem;
			background: ${({ theme }) => theme.colors.white};
			color: ${({ theme }) => theme.colors.textoGrisPrimario};
			border-radius: 10px;
			border: 1px solid ${({ theme }) => theme.colors.azul05};
			caret-color: ${({ theme }) => theme.colors.azul02};

			&:focus {
				outline: 2px solid ${({ theme }) => theme.colors.azul02};
				outline-offset: -2px;
			}

			&.cds--text-input--invalid {
				background: ${({ theme }) => theme.colors.alertasErrorClaro};
			}
		}
	}

	&.disabled {
		& > .cds--text-input__label-wrapper > .cds--label {
			color: ${({ theme }) => theme.colors.textoGrisTerciario};
			&::after {
				color: ${({ theme }) => theme.colors.textoGrisTerciario};
			}
		}

		& .cds--text-input__field-wrapper > input.cds--text-input {
			border: 1px solid ${({ theme }) => theme.colors.gris};
			color: ${({ theme }) => theme.colors.textoGrisTerciario};
			outline: unset;
			background: ${({ theme }) => theme.colors.white};
		}
	}
`;

const S = {
	InputContainer,
	ClearInputIcon,
	Input,
};

export default S;

import React, { FunctionComponent, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { toast } from 'react-toastify';

import { checkFileType } from '../../utils/Utils';
import Modal from './Modal';
import ConfirmModal from './ConfirmModal';

interface FileUploaderProps {
	handleFile: (fileUploaded: any, errors: any) => void;
	label?: string;
	fileTypes?: string[];
	handleFileLoading?: boolean;
	multiple?: boolean;
}

const FileUploader: FunctionComponent<FileUploaderProps> = ({
	handleFile,
	label = 'Upload File',
	fileTypes = [],
	handleFileLoading = false,
	multiple = true,
}): JSX.Element => {
	const hiddenFileInput: any = useRef<HTMLInputElement>(null);
	const [showConfirmFileUploadModal, setShowConfirmFileUploadModal] = useState<boolean>(false);

	/**
	 * Clicking on a file upload input
	 */
	const handleClick = () => {
		if (handleFileLoading) {
			toast.warn('Uploading file, please wait a moment');
			return;
		}
		hiddenFileInput.current.click();
	};

	/**
	 * Selecting a file upload input
	 */
	const handleChange = () => {
		const files = hiddenFileInput.current.files;
		const { allowedFiles, errors } = checkFileType({ files, fileTypes });
		handleFile(allowedFiles, errors);
		setShowConfirmFileUploadModal(false);
	};

	const getModalText = (): JSX.Element => {
		const files = hiddenFileInput.current.files;
		const fileNames = [];
		for (const file of files) {
			fileNames.push(file.name);
		}
		return (
			<>
				<p>Do you want to upload these files?</p>
				{fileNames?.map((fileName, index) => (
					<p key={index}>{fileName}</p>
				))}
			</>
		);
	};

	return (
		<>
			<Button type="button" className="mx-1 billing-pre-order__statuses__btn-upload-file" onClick={handleClick}>
				{handleFileLoading && (
					<i className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></i>
				)}
				{!handleFileLoading && <i className="fa fa-upload mx-1"></i>}
				{label}
			</Button>
			<input
				type="file"
				style={{ display: 'none' }}
				ref={hiddenFileInput}
				onClick={() => (hiddenFileInput.current.value = null)}
				onChange={() => setShowConfirmFileUploadModal(true)}
				multiple={multiple}
			/>

			{showConfirmFileUploadModal && (
				<Modal
					dismiss={(): void => {
						setShowConfirmFileUploadModal(false);
					}}
				>
					<ConfirmModal
						onSubmit={async () => handleChange()}
						onCancelButtonClick={() => setShowConfirmFileUploadModal(false)}
						isSubmitting={handleFileLoading}
						modalText={getModalText()}
						denyText="No, go back"
						confirmText="Yes, upload these files"
					/>
				</Modal>
			)}
		</>
	);
};

export default FileUploader;

import React, { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';

import HeliosClient from '../../api/HeliosClient';
import { EditServiceData, Service } from '../../interfaces';
import ConfirmModal from '../Commons/ConfirmModal';
import Modal from '../Commons/Modal';
import { ConfirmButton, EditButton, EditCancelButton, EditConfirmButton, FinishButton } from './Buttons';
import { HELIOS_SERVICE_STATUS } from '../../utils/constants';

import './ActionButtons.scss';

interface ActionButtonsProps {
	service: Service;
	editServiceData: EditServiceData;
	clearEditServiceData: () => void;
	fetchService: () => Promise<void>;
	setEditMode: (option: boolean) => void;
	editMode: boolean;
}

const ActionButtons: FunctionComponent<ActionButtonsProps> = ({
	service,
	editServiceData,
	clearEditServiceData,
	fetchService,
	setEditMode,
	editMode,
}): JSX.Element => {
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [action, setAction] = useState<string>();

	const handleFinish = async () => {
		try {
			setIsSubmitting(true);
			await new HeliosClient().finish(service);
			await fetchService();
			toast.success('Service successfully finished');
		} catch (error) {
			toast.warn('An error has occurred, please try again');
		} finally {
			setIsSubmitting(false);
			setShowConfirmModal(false);
		}
	};

	const handleEdit = async () => {
		try {
			setIsSubmitting(true);
			const serviceId = service._id;
			if (!serviceId) throw new Error(`Service ${serviceId} does not exist`);
			const data = {
				schedule: editServiceData.schedule,
				serviceCost: editServiceData.serviceCost,
				driverId: editServiceData.driver?.id || editServiceData.driver?._id,
			};
			await new HeliosClient().updateService({ serviceId, data });
			await fetchService();
			toast.success('Service updated successfully');
		} catch (error) {
			toast.warn('An error has occurred, please try again');
		} finally {
			setIsSubmitting(false);
			setShowConfirmModal(false);
			setEditMode(false);
			clearEditServiceData();
		}
	};

	const handleConfirm = async () => {
		try {
			setIsSubmitting(true);
			const serviceId = service._id;
			if (!serviceId) throw new Error(`Service ${serviceId} does not exist`);
			const { data } = await new HeliosClient().markAsConfirmed(serviceId);
			if (data.status) {
				await fetchService();
				toast.success(data.message);
			} else {
				toast.warn(data.message);
			}
		} catch (error) {
			toast.warn('Something went wrong, please try again');
		} finally {
			setIsSubmitting(false);
			setShowConfirmModal(false);
		}
	};

	const handleOnSubmitConfirmModal = () => {
		switch (action) {
			case 'finish':
				handleFinish();
				break;
			case 'edit':
				handleEdit();
				break;
			default:
				handleConfirm();
				break;
		}
	};

	const actionLabel = action === 'finish' ? 'finish' : action === 'edit' ? 'edit' : 'confirm';
	const getConfirmModalText = `Do you want to ${actionLabel} this service? PO #${service.serviceNumber}`;
	const getConfirmText = `Yes, ${actionLabel} this service`;

	return (
		<div className="action-buttons-container">
			<span className="action-buttons-container">
				<EditButton editMode={editMode} setEditMode={setEditMode} status={service.status as HELIOS_SERVICE_STATUS} />
				<EditCancelButton editMode={editMode} setEditMode={setEditMode} clearEditServiceData={clearEditServiceData} />
				<EditConfirmButton editMode={editMode} setShowConfirmModal={setShowConfirmModal} setAction={setAction} />
			</span>

			<span className="action-buttons-container">
				<ConfirmButton
					editMode={editMode}
					setAction={setAction}
					setShowConfirmModal={setShowConfirmModal}
					status={service.status as HELIOS_SERVICE_STATUS}
					warnings={service.warnings}
				/>
				<FinishButton
					allowedToFinishFromEOS={service.allowedToFinishFromEOS}
					editMode={editMode}
					setAction={setAction}
					setShowConfirmModal={setShowConfirmModal}
					status={service.status as HELIOS_SERVICE_STATUS}
				/>
			</span>

			{showConfirmModal && (
				<Modal
					dismiss={(): void => {
						setShowConfirmModal(false);
					}}
				>
					<ConfirmModal
						onSubmit={async () => handleOnSubmitConfirmModal()}
						onCancelButtonClick={() => setShowConfirmModal(false)}
						isSubmitting={isSubmitting}
						modalText={getConfirmModalText}
						denyText="No, go back"
						confirmText={getConfirmText}
					/>
				</Modal>
			)}
		</div>
	);
};

export default ActionButtons;

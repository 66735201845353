import React, { FunctionComponent } from 'react';

interface TableProps {
	files: File[];
	xmlFile: File | null;
	handleRemoveFile: (index: number) => void;
	handleRemoveXmlFile: () => void;
	xmlErrors: string[];
}

const AttachmentList: FunctionComponent<TableProps> = ({
	files,
	xmlFile,
	handleRemoveFile,
	xmlErrors,
	handleRemoveXmlFile,
}): JSX.Element => {
	return (
		<div className="billing-preorder-attachments__list">
			<ul>
				{files.map((file, index) => (
					<li key={file.name}>
						<div className="file-element">
							{file.name}
							<button type="button" title="Remove file" onClick={(): void => handleRemoveFile(index)}>
								<i className="fa fa-trash"></i>
							</button>
						</div>
					</li>
				))}
				{xmlFile && (
					<li key={xmlFile.name}>
						<div className="file-element">
							{xmlFile.name}
							<button type="button" title="Remove file" onClick={(): void => handleRemoveXmlFile()}>
								<i className="fa fa-trash"></i>
							</button>
						</div>
					</li>
				)}
			</ul>
			{xmlErrors?.length ? (
				<div>
					<h6>XML Errors:</h6>
					<div className="billing-preorder-attachments__xml-errors-list">
						<ul>
							{xmlErrors.map((error, index) => (
								<li key={index}>
									<div>{error}</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default AttachmentList;

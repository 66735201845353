import React, { FunctionComponent } from 'react';
import isNil from 'lodash/isNil';

import '../../views/Service/styles.scss';

interface InputGroupProps {
	colSize: string;
	label: string;
	value?: string | number | undefined;
	children?: JSX.Element;
}

const InputGroup: FunctionComponent<InputGroupProps> = ({ colSize, label, value, children }): JSX.Element => {
	return (
		<div className={colSize}>
			<div className="input-group">
				<div className="input-group-prepend">
					<span className="input-group-text">{label}</span>
				</div>
				{!isNil(value) ? <input type="text" className="form-control form-control-dark" value={value} readOnly /> : null}
				{children}
			</div>
		</div>
	);
};

export default InputGroup;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import EosClient from '../../api/EosClient';
import CheckboxButton from '../../components/DesignSystem/CheckboxButton';
import { EosService } from '../../interfaces';

interface ButtonReviewProps {
	setEosService: (eosService: EosService) => void;
	eosService: EosService;
}

const ButtonReview = ({ setEosService, eosService }: ButtonReviewProps): JSX.Element | null => {
	const [isLoadingReview, setIsLoadingReview] = useState(false);

	const handleChangeReviewed = async (checked: boolean) => {
		setIsLoadingReview(true);
		try {
			const serviceResponse = await new EosClient().upsertEosServiceReview(eosService.serviceNumber, checked);
			if (serviceResponse.data?.success) {
				setEosService({ ...eosService, reviewed: checked });
			}
			toast.success('Service reviewed successfully');
		} catch (e) {
			toast.error('Something went wrong, please try again');
		} finally {
			setIsLoadingReview(false);
		}
	};

	if (!eosService?.serviceNumber) {
		return null;
	}

	return (
		<div className="m-3">
			<CheckboxButton
				isLoading={isLoadingReview}
				onChange={handleChangeReviewed}
				checked={!!eosService.reviewed}
				label={eosService.reviewed ? 'REVIEWED' : 'MARK AS REVIEWED'}
			/>
		</div>
	);
};

export default ButtonReview;

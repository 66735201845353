import React, { useContext, useEffect, useState } from 'react';

import { Context } from '../../context/Context';
import { AppContext } from '../../interfaces';
import { ROLE } from '../constants';
import { ALLOWED_FOR_ADMINS, FEATURE_FLAG } from './constants';

interface FeatureProps {
	name: FEATURE_FLAG; // Component children name, used to filter feature flags
	accountId?: string; // Account identifier of service, could be salesforce id
	children: any; // Component children to be rendered
}

const Feature = ({ name, accountId, children }: FeatureProps): JSX.Element | null => {
	const { userType, provider, user, featureFlags } = useContext(Context) as AppContext;

	const isAdmin = user?.roles.some((role: string) => role === ROLE.ADMIN_EOS);
	const isAllowedForAdmin = isAdmin && ALLOWED_FOR_ADMINS.includes(name);

	const roles: string[] = user?.roles || provider?.roles || [];

	const branch = provider?.branch || user?.branch;

	// El accountId que llega por parámetros se utiliza en los componentes donde el accountId no proviene del usuario logueado sino del servicio
	if (!accountId && user?.account) accountId = user.account;

	const [isChildrenAvailable, setIsChildrenAvailable] = useState(false);

	useEffect(() => {
		const features = featureFlags?.filter((feature) => feature.name === name);
		const childrenAvailable = features?.some(
			(feature) =>
				((!feature.userTypes?.length || feature.userTypes?.some((uType) => uType.name === userType)) &&
					(!feature.branches?.length || feature.branches?.some((b) => b.name === branch)) &&
					(!feature.accounts?.length || feature.accounts?.some((account) => account.accountId === accountId)) &&
					(!feature.roles?.length || feature.roles?.some((rol) => roles.includes(rol.name))) &&
					feature.enabled) ||
				isAdmin
		);
		setIsChildrenAvailable(childrenAvailable);
	}, [name, userType, provider, user, featureFlags]);

	if (isChildrenAvailable) {
		if (isAdmin && !isAllowedForAdmin) {
			return null;
		}
		return children;
	}

	return null;
};

export default Feature;

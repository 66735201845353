import { Schema, ValidationError } from 'yup';

type ErrorMessages<T> = Partial<Record<keyof T, string>>;

const validateSchema = async <T extends Record<string, unknown>>(
	schema: Schema,
	values: T,
	validationContext?: Record<string, any>
): Promise<ErrorMessages<T> | null> => {
	const schemaValidationOptions = {
		abortEarly: false,
		context: validationContext,
	};
	try {
		await schema.validate(values, schemaValidationOptions);
		return null;
	} catch (e) {
		if (ValidationError.isError(e)) {
			const validationError = e as ValidationError;
			const errors: ErrorMessages<T> = {};
			validationError.inner.forEach((err) => {
				let path = err.path;
				if (path?.includes('.')) {
					path = path.split('.')[0];
				}
				if (path) errors[path as keyof T] = err.errors[0];
			});
			return errors;
		}
		return null;
	}
};

export { validateSchema };
export type { ErrorMessages };

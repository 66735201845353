import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import localforage from 'localforage';
import { toast } from 'react-toastify';
import { Messages } from 'primereact/messages';

import Navbar from '../../components/Navbar';
import ReportTable from '../../components/Report/ReportTable';
import HeliosClient from '../../api/HeliosClient';
import { AppContext, Service } from '../../interfaces';
import { setUpInfo, validateEmail } from './Reports.utils';
import { BRANCH, DASHBOARD_SECTION, USER_TYPE } from '../../utils/constants';
import { Context } from '../../context/Context';
import {
	getReportTableProperty,
	noRecordsFoundsMsgData,
	specifyParametersEndDateMsg,
	specifyParametersStartDateMsg,
} from '../../components/Report/ReportTable.utils';

import './styles.scss';
import './bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

const Reports: FunctionComponent = (): JSX.Element => {
	const { situationTypes } = useContext(Context) as AppContext;

	const [statusFilter, setStatusFilter] = useState<string>('Finished');
	const [openDropdown, setOpenDropdown] = useState<boolean>(false);
	const [recipientEmail, setRecipientEmail] = useState<string>('');
	const [userType, setUserType] = useState<string>();
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [services, setServices] = useState<Service[]>([]);
	const [reportTableProperties, setReportTableProperties] = useState<any[] | null>(null);
	const [branch, setBranch] = useState<BRANCH>();
	const [loading, setLoading] = useState<boolean>(false);

	const specifyParametersMsg = useRef<Messages>(null);
	const noRecordsFoundsMsg = useRef<Messages>(null);

	useEffect(() => {
		handleSetReportTableProperties();
		if (startDate && endDate) {
			fetchServices();
		}
	}, [statusFilter]);

	useEffect(() => {
		getUserInfo();
		handleSetReportTableProperties();
	}, []);

	useEffect(() => {
		if (!startDate) {
			specifyParametersMsg.current?.replace(specifyParametersEndDateMsg);
		} else if (!endDate) {
			specifyParametersMsg.current?.replace(specifyParametersStartDateMsg);
		} else {
			specifyParametersMsg.current?.clear();
		}
	}, [startDate, endDate]);

	const handleSetReportTableProperties = () => {
		const reportTable = getReportTableProperty({ status: statusFilter, userType, situationTypes, branch });
		setReportTableProperties(reportTable);
	};

	const getUserInfo = async (): Promise<void> => {
		const userT: string | null = await localforage.getItem('userType');
		const user: any = await localforage.getItem(USER_TYPE.USER);
		const provider: any = await localforage.getItem(USER_TYPE.PROVIDER);

		if (provider) setBranch(provider?.branch);
		else if (user) setBranch(user?.branch);
		else setBranch(BRANCH.PRI);
		setUserType(userT as string);
	};

	const exportReport = (): void => {
		if (!recipientEmail) {
			toast.error('Recipient email is required');
			return;
		}
		if (!validateEmail(recipientEmail)) {
			toast.error('Please verify email.');
			return;
		}

		fetchServices(true);
	};

	const fetchServices = async (sendReport = false): Promise<void> => {
		if (!startDate || startDate === '' || !endDate || endDate === '') {
			toast.error('Start/End dates are required');
			return;
		}

		setLoading(true);
		setUpInfo([], setServices);

		const unixStartDate = dayjs(startDate).unix() * 1000;

		const unixEndDate = dayjs(endDate).add(1, 'day').unix() * 1000;
		const body = {
			status: statusFilter,
			start: unixStartDate,
			end: unixEndDate,
			sendReport: sendReport,
			recipientEmail: recipientEmail,
		};

		const {
			data: { data, status },
		} = await new HeliosClient().getByDateRange(userType as string, body);

		if (status) {
			if (sendReport) toast.success('Report send');
			setUpInfo(data, setServices);
			if (data.length === 0) {
				noRecordsFoundsMsg.current?.show(noRecordsFoundsMsgData);
			} else noRecordsFoundsMsg.current?.clear();
		}
		setLoading(false);
	};

	const statusFilterName = statusFilter === 'HoldInspection' ? 'Scheduled' : statusFilter;

	return (
		<div className="report-view">
			<Navbar />
			<div className="pl-4 pr-4 report-view__container">
				<div className="row">
					<div className="col">
						<div className="row">
							<div className="col-12 col-sm-12 col-md-4 col-lg-6">
								<p className="title">Report</p>
							</div>
							<div className="col-12 col-sm-12 col-md-8 col-lg-6">
								<div className="input-group mb-2 mt-md-5 date-range-filter">
									<div className="input-group-prepend">
										<button
											type="button"
											className="btn btn-info dropdown-toggle"
											onClick={(): void => setOpenDropdown(!openDropdown)}
										>
											{statusFilterName}
										</button>
										{openDropdown && (
											<div className="report-view__dropdown">
												<a
													onClick={(): void => {
														setStatusFilter(DASHBOARD_SECTION.FINISHED);
														setOpenDropdown(false);
													}}
													className="report-view__dropdown-item"
												>
													Finished
												</a>
												<a
													onClick={(): void => {
														setStatusFilter(DASHBOARD_SECTION.ACTIVE);
														setOpenDropdown(false);
													}}
													className="report-view__dropdown-item"
												>
													Active
												</a>
												<a
													onClick={(): void => {
														setStatusFilter(DASHBOARD_SECTION.CANCELLED);
														setOpenDropdown(false);
													}}
													className="report-view__dropdown-item"
												>
													Cancelled
												</a>
												<a
													onClick={(): void => {
														setStatusFilter('HoldInspection');
														setOpenDropdown(false);
													}}
													className="report-view__dropdown-item"
												>
													Scheduled
												</a>
												<a
													onClick={(): void => {
														setStatusFilter('Informative');
														setOpenDropdown(false);
													}}
													className="report-view__dropdown-item"
												>
													Informative
												</a>
												<a
													onClick={(): void => {
														setStatusFilter('Not Covered');
														setOpenDropdown(false);
													}}
													className="report-view__dropdown-item"
												>
													Not Covered
												</a>
											</div>
										)}
									</div>
									<input
										type="date"
										className="form-control"
										placeholder="Start Date"
										value={startDate}
										onChange={(evt): void => setStartDate(evt.target.value)}
									/>
									<div className="input-group-prepend">
										<span className="input-group-text">to</span>
									</div>
									<input
										type="date"
										className="form-control"
										placeholder="End Date"
										value={endDate}
										onChange={(evt): void => setEndDate(evt.target.value)}
									/>
									<div className="input-group-append">
										<button onClick={(): Promise<void> => fetchServices()} className="btn btn-primary">
											<i className="fa fa-search"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="row" style={{ marginLeft: '0px' }}>
							<input
								onChange={(evt): void => setRecipientEmail(evt.target.value)}
								type="email"
								className="col col-2 form-control"
								placeholder="Recipient Email"
							/>
							<button onClick={exportReport} className="col col-2 btn btn-info btn-info-expanded mb-2">
								<i className="fa fa-envelope"></i> Export CSV
							</button>
						</div>
						{services?.length > 0 && <ReportTable services={services} reportTableProperties={reportTableProperties} />}
						<Messages ref={specifyParametersMsg} />
						<Messages ref={noRecordsFoundsMsg} />
						{loading && (
							<div className="spinner-container">
								<span className="spinner-border"></span>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Reports;

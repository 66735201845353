// External imports
import React from 'react';
import styled from 'styled-components';
// Global imports
import bullet from 'assets/imgs/bullet.svg';

interface IProps {
	children: React.ReactNode;
}

const Container = styled.div`
	display: flex;
	justify-content: center;
	gap: 19px;
`;

const BulletContainer = styled.div`
	width: 20px;
	margin-top: 17px;
	& > img {
		width: 100%;
	}
`;

const LocationInput = ({ children }: IProps): React.ReactElement => {
	return (
		<Container>
			<BulletContainer>
				<img src={bullet} />
			</BulletContainer>
			{children}
		</Container>
	);
};

export default LocationInput;

// External imports
import { useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';
// Global imports
// Local imports
import { IUseMapReturnValue, Map, MapTools } from './types';

const LIBRARIES = ['places' as const];

const useMap = (): IUseMapReturnValue => {
	const [mapTools, setMapTools] = useState<MapTools>({ map: null, places: null, autocomplete: null, geocoder: null });
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY as string,
		preventGoogleFontsLoading: true,
		libraries: LIBRARIES,
	});

	const onMapLoad = (map: Map) => {
		const places = new window.google.maps.places.PlacesService(map);
		const autocomplete = new window.google.maps.places.AutocompleteService();
		const geocoder = new window.google.maps.Geocoder();
		const mapTools = {
			map,
			places,
			autocomplete,
			geocoder,
		};
		setMapTools(mapTools);
	};

	const onMapUnmount = () => {
		setMapTools({
			map: null,
			places: null,
			autocomplete: null,
			geocoder: null,
		});
	};

	return {
		mapLoaded: isLoaded,
		onMapLoad,
		onMapUnmount,
		mapTools,
	};
};

export default useMap;

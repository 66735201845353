import React, { ReactNode, MouseEvent, useState } from 'react';
import './styles.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	children: ReactNode;
	isLoading?: boolean;
	onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
	variant?: 'primary' | 'outlined';
	disabledTooltip?: string;
	icon?: ReactNode;
}

const Button = ({ children, onClick, isLoading, disabled, variant, disabledTooltip, icon, ...rest }: ButtonProps) => {
	const [isTooltipVisible, setTooltipVisible] = useState(false);

	const buttonClass = `custom-button ${variant === 'primary' ? 'primary' : ''}`;

	const handleMouseEnter = () => {
		if (disabled && disabledTooltip) {
			setTooltipVisible(true);
		}
	};

	const handleMouseLeave = () => {
		if (disabled && disabledTooltip) {
			setTooltipVisible(false);
		}
	};

	return (
		<div className="button-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<button disabled={isLoading || disabled} className={buttonClass} onClick={onClick} {...rest}>
				{isLoading && <i className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></i>}
				{!isLoading && <span className="mx-1">{icon}</span>}
				<span>{children}</span>
			</button>
			{disabled && disabledTooltip && isTooltipVisible && <div className="tooltip-button">{disabledTooltip}</div>}
		</div>
	);
};

export default Button;

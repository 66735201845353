import Axios, { AxiosRequestHeaders, AxiosStatic } from 'axios';
import HttpClient from '../../api/HttpClient';

import { CONNECT_SERVICES_API } from '../constants';

interface IPresignedUrlResponse {
	data: string;
	error: any;
	success: boolean;
}

export default class ConnectServices extends HttpClient {
	private headers: AxiosRequestHeaders;

	constructor(axios: AxiosStatic = Axios) {
		super(axios, CONNECT_SERVICES_API);
		this.headers = { 'Content-Type': 'application/json' };
	}

	public async getFileBlob(url: string): Promise<any> {
		const presignedUrl = await this.getPresignedUrl(url);
		const response = await fetch(presignedUrl);
		return response.blob();
	}

	private async getPresignedUrl(fileUrl: string): Promise<string> {
		const payload = { url: fileUrl };
		const {
			data: { data: data },
		} = await this.post<IPresignedUrlResponse>('/api/v1/media/presignedUrl', payload, { headers: this.headers });
		return data;
	}
}

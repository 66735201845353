/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionType, DispatchFunction, FeatureFlag, ServiceType, DropDownOption } from '../interfaces/';

export const setStatusFilter = (dispatch: DispatchFunction, data: string): void => {
	dispatch({ type: ActionType.SET_STATUS_FILTER, data });
};

export const setSearchTerm = (dispatch: DispatchFunction, data: string): void => {
	dispatch({ type: ActionType.SET_SEARCHTERM, data });
};

export const setSearchType = (dispatch: DispatchFunction, data: string): void => {
	dispatch({ type: ActionType.SET_SEARCHTYPE, data });
};

export const setSelectedProviders = (dispatch: DispatchFunction, data: any): void => {
	dispatch({ type: ActionType.SET_SELECTED_PROVIDERS, data });
};

export const setSelectedSocialReason = (dispatch: DispatchFunction, data: any): void => {
	dispatch({ type: ActionType.SET_SELECTED_SOCIAL_REASON, data });
};

export const setSelectedSituations = (dispatch: DispatchFunction, data: any): void => {
	dispatch({ type: ActionType.SET_SELECTED_SITUATIONS, data });
};

export const setSelectedDivisions = (dispatch: DispatchFunction, data: any): void => {
	dispatch({ type: ActionType.SET_SELECTED_DIVISIONS, data });
};

export const setCurrentPage = (dispatch: DispatchFunction, data: number): void => {
	dispatch({ type: ActionType.SET_CURRENT_PAGE, data });
};

export const setUserType = (dispatch: DispatchFunction, data: string): void => {
	dispatch({ type: ActionType.SET_USER_TYPE, data });
};

export const setProvider = (dispatch: DispatchFunction, data: any): void => {
	dispatch({ type: ActionType.SET_PROVIDER, data });
};

export const setUser = (dispatch: DispatchFunction, data: any): void => {
	dispatch({ type: ActionType.SET_USER, data });
};

export const setShowPreOrderDrawer = (dispatch: DispatchFunction, data: boolean): void => {
	dispatch({ type: ActionType.SET_SHOW_PREORDER_DRAWER, data });
};

export const setSelectedEosServices = (dispatch: DispatchFunction, data: any): void => {
	dispatch({ type: ActionType.SET_PREORDER_SERVICES, data });
};

export const setSelectedEosInvoices = (dispatch: DispatchFunction, data: any): void => {
	dispatch({ type: ActionType.SET_SELECTED_INVOICES, data });
};

export const setSelectedEosStatus = (dispatch: DispatchFunction, data: any): void => {
	dispatch({ type: ActionType.SET_EOS_STATUS, data });
};

export const setSelectedEosOtherStatus = (dispatch: DispatchFunction, data: any): void => {
	dispatch({ type: ActionType.SET_EOS_OTHER_STATUS, data });
};

export const setFeatureFlags = (dispatch: DispatchFunction, data: FeatureFlag[]): void => {
	dispatch({ type: ActionType.SET_FEATURE_FLAGS, data });
};

export const setSituationTypes = (dispatch: DispatchFunction, data: ServiceType[]): void => {
	dispatch({ type: ActionType.SET_SITUATION_TYPES, data });
};

export const setSelectedPolicies = (dispatch: DispatchFunction, data: DropDownOption[]): void => {
	dispatch({ type: ActionType.SET_POLICIES, data });
};

import React, { useEffect, useState } from 'react';
import { OnChangeData } from '@carbon/react/lib/components/Dropdown/Dropdown';
import S from './styles';
import { IProps } from './types';
import { defaultItemToString, getClearItem } from './utils';

function Dropdown<T>({
	id,
	className,
	error,
	disabled,
	required,
	label,
	selectedItem,
	items,
	clearable = true,
	clearLabel,
	...props
}: IProps<T>): React.ReactElement {
	const [_items, setItems] = useState<T[]>([]);

	useEffect(() => {
		if (clearable && Array.isArray(items) && items.length > 0) {
			const _itemToString = props.itemToString || defaultItemToString;
			const _clearLabel = clearLabel || label;
			const clearItem = getClearItem<T>(items[0], _itemToString, _clearLabel);
			setItems([clearItem, ...items]);
		} else {
			setItems(items);
		}
	}, [items, clearable]);

	const _onChange = (onChangeData: OnChangeData<T>) => {
		const selectedItem = onChangeData.selectedItem;
		const _itemToString = props.itemToString || defaultItemToString;
		const _clearLabel = clearLabel || label;
		if (typeof _itemToString === 'function' && _clearLabel && _itemToString(selectedItem) === _clearLabel) {
			props?.onChange(id, null);
		} else {
			props?.onChange(id, onChangeData.selectedItem);
		}
	};

	let filledInputClassName = className ? `${className} ` : '';
	if (selectedItem) {
		filledInputClassName += 'filled';
	}
	let statusClassName = '';
	if (error) {
		statusClassName += ' invalid';
	}
	if (disabled) {
		statusClassName += ' disabled';
	}

	return (
		<S.Dropdown
			{...props}
			id={id}
			items={_items}
			className={filledInputClassName + statusClassName}
			invalid={Boolean(error)}
			invalidText={error}
			$required={required}
			disabled={disabled}
			titleText={label}
			selectedItem={selectedItem}
			onChange={_onChange}
			label={' '}
		/>
	);
}

export default Dropdown;

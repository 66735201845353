import { FileReferences } from '../../interfaces';
import { BRANCH, FILE_TYPE, INVOICE_ATTACHMENTS, S3_EOS_BUCKET, S3_EOS_BUCKET_REGION } from '../../utils/constants';
import S3ManagerService from '../../utils/s3-manager';
import { FileTypeError, getExtension } from '../../utils/Utils';

export const fileUploaderLabelPdf = (): string => {
	return 'Upload PDF';
};

export const fileUploaderTypesPdf = (): string[] => {
	return [FILE_TYPE.PDF];
};

export const fileUploaderLabelXml = (): string => {
	return 'Upload XML';
};

export const fileUploaderTypesXml = (): string[] => {
	return [FILE_TYPE.XML, FILE_TYPE.TEXT_XML];
};

export const fileUploaderTypesPODetail = (): string[] => {
	return [FILE_TYPE.PDF, FILE_TYPE.JPEG];
};

export const pdfIsRequired = (branch: string): boolean => {
	return [BRANCH.CRC, BRANCH.PAN, BRANCH.MEX].includes(branch as BRANCH);
};

export const xmlIsRequired = (branch: string): boolean => {
	return [BRANCH.MEX, BRANCH.PAN, BRANCH.CRC].includes(branch as BRANCH);
};

export const uploadFile = async (file: File): Promise<FileReferences> => {
	const fileName = `${Date.now()}-${file.name}`;
	const fileExtension = getExtension(file.name);
	const filePath = await new S3ManagerService().uploadFile(
		S3_EOS_BUCKET,
		S3_EOS_BUCKET_REGION,
		fileName,
		fileExtension,
		INVOICE_ATTACHMENTS,
		file
	);
	return { fileName, filePath };
};

export const fileErrorMessage = (errors: Array<FileTypeError>): string => {
	const fileTypeError = errors.some((e) => e.error.includes('type')) ? 'Incorrect file type' : '';
	const fileSizeError = errors.some((e) => e.error.includes('size')) ? 'Max file size' : '';
	return fileTypeError + fileSizeError;
};

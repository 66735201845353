// External imports
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
// Local imports
import { Alpha2Code, BRANCH, branchAlpha2CodeMap } from './constants';

const getAlpha2Code = (branch: BRANCH): Alpha2Code | null => {
	const alpha2Code = branchAlpha2CodeMap[branch];
	return alpha2Code ?? null;
};

export const formatPhoneNumber = (phone: string, branch: BRANCH): string => {
	const alpha2Code = getAlpha2Code(branch);
	if (!phone) phone = '';

	if (!alpha2Code) return phone;

	if (isValidPhoneNumber(phone, alpha2Code)) {
		const parsedPhoneNumber = parsePhoneNumber(phone, alpha2Code);
		return parsedPhoneNumber.formatNational();
	}

	return phone;
};

export const validatePhoneNumber = (phone: string, branch: BRANCH): boolean => {
	const alpha2Code = getAlpha2Code(branch);
	if (!alpha2Code) return false;

	return isValidPhoneNumber(phone, alpha2Code);
};

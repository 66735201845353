// external imports
import React from 'react';
import styled from 'styled-components';
// global imports

interface IStyledLocationInputsErrorContainerProps {
	$hasError?: boolean;
	$addMarginTop?: boolean;
}

interface IProps {
	error?: string | null;
	addMarginTop?: boolean;
	children: React.ReactNode;
}

const LocationInputsErrorContainer = styled.div<IStyledLocationInputsErrorContainerProps>`
	padding: 5px 30px 10px 10px;
	margin: -5px -30px -10px -10px;
	${({ $addMarginTop }) => $addMarginTop && 'margin-top: 18px;'}
	@media only screen and (max-width: 1056px) {
		padding: 5px 10px 10px 10px;
		margin: -5px -10px -10px -10px;
	}

	${({ $hasError, theme }) =>
		$hasError &&
		`
    background: ${theme.colors.alertasErrorClaro};
    outline: 1px solid ${theme.colors.alertasError};
    border-radius: 8px;
  `}
`;

const LocationError = styled.p`
	${({ theme }) => theme.typography.p3};
	color: ${({ theme }) => theme.colors.alertasError};
	margin: 15px 5px 5px 3px;
	height: 2rem;
`;

const LocationInputError = ({ children, error, addMarginTop }: IProps): React.ReactElement => (
	<LocationInputsErrorContainer $hasError={Boolean(error)} $addMarginTop={addMarginTop}>
		{children}
		<LocationError>{error || ''}</LocationError>
	</LocationInputsErrorContainer>
);

export default LocationInputError;

import React, { FunctionComponent, useCallback, useState, useMemo, useEffect } from 'react';
import { getDistance, getRhumbLineBearing } from 'geolib';
import { GoogleMap, InfoBox, Marker, Polyline } from '@react-google-maps/api';
import { Service } from '../../interfaces';

import SituationIcon from '../../assets/imgs/s_location.png';
import DestinationIcon from '../../assets/imgs/d_location.png';
import ProviderIcon from '../../assets/imgs/p_location.png';

import './styles.scss';

import { getVehicleIcon } from '../../views/MapView/MapView.utils';
import HeliosClient from '../../api/HeliosClient';

interface MapProps {
	mapLoaded: boolean;
	hidden: boolean;
	service: Service;
}

const TIME_TO_REQUEST_DRIVER_LOCATION = 5000;

const Map: FunctionComponent<MapProps> = ({ mapLoaded, hidden, service }): JSX.Element => {
	const [driver, setDriver] = useState(service.driver || {});
	const [providerPosition, setProviderPosition] = useState(service.locations.provider);
	const [situationPosition, setSituationPosition] = useState(service.locations.situation);
	const [destinationPosition, setDestinationPosition] = useState(service.locations.destination);
	const [pathway, setPathway] = useState<{ lat: () => number; lng: () => number }[]>([]);
	const [previousCoords, setPreviousCoords] = useState<{ lat: number; lng: number }>({
		lat: driver?.lat,
		lng: driver?.lng,
	});
	useEffect(() => {
		const fetchDriver = async (): Promise<void> => {
			if (service?.driver?.id) {
				const {
					data: { data },
				} = await new HeliosClient().getDriverLocation(service.driver.id);
				setDriver(data);
			}
		};
		fetchDriver();

		const timer = setInterval(fetchDriver, TIME_TO_REQUEST_DRIVER_LOCATION);
		return (): void => clearInterval(timer);
	}, [service, mapLoaded]);

	useEffect(() => {
		if (driver?.lat === previousCoords.lat && driver?.lng === previousCoords.lng) return;
		if (!previousCoords?.lat || !previousCoords?.lng || !driver?.lat || !driver?.lng) return;
		const rotation = getRhumbLineBearing(previousCoords, driver);
		const driverDelta = getDistance(previousCoords, driver);
		if (driverDelta > 2) updateMarker(rotation);

		setPreviousCoords({ lat: driver?.lat, lng: driver?.lng });

		const deltas = [];
		for (const point of pathway) deltas.push(getDistance(driver, { lat: point.lat(), lng: point.lng() }));

		const min = Math.min(...deltas.slice(0, 10));
		const i = deltas.findIndex((d) => d === min);
		setPathway(pathway.slice(i, pathway.length));
	}, [driver, pathway, previousCoords]);
	const coords = useMemo(() => ({ lat: service?.locations?.situation?.lat, lng: service?.locations?.situation?.lng }), [
		service?.locations?.situation?.lat,
		service?.locations?.situation?.lng,
	]);
	const origin = useMemo(() => ({ lat: service?.locations?.provider?.lat, lng: service?.locations?.provider?.lng }), [
		service?.locations?.provider?.lat,
		service?.locations?.provider?.lng,
	]);
	const destination = useMemo(() => {
		if (service?.locations?.destination?.lat) {
			return `${service?.locations.destination.lat}, ${service?.locations.destination.lng}`;
		} else {
			return `${service?.locations?.situation?.lat}, ${service?.locations?.situation?.lng}`;
		}
	}, [
		service?.locations?.destination?.lat,
		service?.locations?.destination?.lng,
		service?.locations?.situation?.lat,
		service?.locations?.situation?.lng,
	]);
	const waypoints = useMemo(
		() =>
			service?.locations?.destination?.lat
				? [
						{
							location: { lat: service?.locations?.situation?.lat, lng: service?.locations?.situation?.lng },
							stopover: true,
						},
				  ]
				: undefined,
		[service?.locations?.situation?.lat, service?.locations?.situation?.lng]
	);

	const updateMarker = (rotation: number): void => {
		const vehicleIcon = getVehicleIcon(driver?.vehicleType, driver?.iconset);
		if (vehicleIcon) {
			rotation -= 90; // substract 90 degrees to align marker with bearing
			document
				.querySelector(`[src*=${vehicleIcon}]`)
				?.setAttribute('style', `transform: rotate(${rotation}deg); transition: transform 2s linear`);
		}
	};

	const fetchDistanceMatrixOSRM = async (locations: { lat: number | undefined; lng: number | undefined }[]) => {
		const coordinates = locations.map((location) => `${location.lng},${location.lat}`).join(';');

		const url = `${process.env.REACT_APP_OSRM_BASE_URL}/route/v1/driving/${coordinates}`;
		const params = {
			annotations: 'true',
			overview: 'full',
			geometries: 'geojson',
			steps: 'true',
			continue_straight: 'true',
		};

		const queryString = new URLSearchParams(params).toString();
		const response = await fetch(`${url}?${queryString}`);

		return await response.json();
	};

	useEffect(() => {
		const fetchRoute = async () => {
			const locations = [{ lat: origin.lat, lng: origin.lng }];

			if (waypoints && waypoints.length > 0) {
				waypoints.forEach((waypoint: any) => {
					locations.push(waypoint.location);
				});
			}

			const [destLat, destLng] = destination.split(',').map(Number);
			locations.push({ lat: destLat, lng: destLng });
			const response = await fetchDistanceMatrixOSRM(locations);
			const route = response.routes[0];
			const path = route.geometry.coordinates.map(([lng, lat]: [lng: number, lat: number]) => ({ lat, lng }));
			setPathway(path);
			if (path.length > 0) {
				setProviderPosition(path[0]);
				const lastPosition = path[path.length - 1];

				if (destinationPosition) {
					setDestinationPosition(lastPosition);
					const [lng, lat] = route.legs[0].steps.at(-1).geometry.coordinates.at(-1);
					setSituationPosition({ lng, lat });
				} else {
					setSituationPosition(lastPosition);
				}
			}
		};
		fetchRoute();
	}, [origin, destination, waypoints]);

	return (
		<div hidden={hidden} className="map" id="map-container">
			{mapLoaded && (
				<GoogleMap
					id="map"
					zoom={13}
					center={coords as google.maps.LatLngLiteral}
					options={{
						fullscreenControl: true,
						mapTypeControl: false,
					}}
				>
					{service?.locations?.provider?.lat && (
						<Marker
							position={providerPosition as google.maps.LatLngLiteral}
							icon={{
								anchor: { x: 20, y: 45 } as google.maps.Point,
								url: ProviderIcon,
								scaledSize: { width: 32, height: 50 } as google.maps.Size,
							}}
						/>
					)}
					{service?.locations?.situation?.lat && (
						<Marker
							position={situationPosition as google.maps.LatLngLiteral}
							icon={{
								anchor: { x: 20, y: 45 } as google.maps.Point,
								url: SituationIcon,
								scaledSize: { width: 32, height: 50 } as google.maps.Size,
							}}
						/>
					)}
					{service?.locations?.destination?.lat && (
						<Marker
							position={destinationPosition as google.maps.LatLngLiteral}
							icon={{
								anchor: { x: 10, y: 10 } as google.maps.Point,
								url: DestinationIcon,
								scaledSize: { width: 32, height: 50 } as google.maps.Size,
							}}
						/>
					)}
					{driver?.lat && driver?.lng && (
						<>
							<Marker
								position={{ lat: driver.lat, lng: driver.lng } as google.maps.LatLngLiteral}
								icon={{
									url: getVehicleIcon(driver?.vehicleType, driver?.iconset),
									anchor: { x: 20, y: 20 } as google.maps.Point,
									scaledSize: { width: 50, height: 50 } as google.maps.Size,
								}}
							/>
							<InfoBox
								position={{ lat: driver.lat, lng: driver.lng }}
								options={{ closeBoxURL: '', enableEventPropagation: true }}
							>
								<div className={`info-box info-box--${driver.tripStatus}`}>
									{Number(driver.activeId ? 1 : 0) + (driver.acceptedServices?.length || 0)}
									{' / '}
									{driver.email.substring(0, 2)}
								</div>
							</InfoBox>
						</>
					)}
					<Polyline
						path={pathway as google.maps.LatLng[]}
						options={{
							strokeColor: '#5BABF1',
							strokeWeight: 5,
							strokeOpacity: 1,
						}}
					/>
				</GoogleMap>
			)}
		</div>
	);
};

export default Map;

// External imports
import styled from 'styled-components';

type BorderType = 'shadow' | 'shadow-highlight' | 'rounded';
interface IProps {
	borderType: BorderType;
}

const borders: Record<BorderType, string> = {
	shadow: `
    box-shadow: 0px -1px 6px 0px #0000001F;
    border-radius: 0;
  `,
	'shadow-highlight': `
    box-shadow: 0px 1px 10px 4px #0000001A;
    border-radius: 0;
  `,
	rounded: `
    border: 1px solid #EBF1F5;
    border-radius: 10px;
  `,
};

const BorderedContainer = styled.div<IProps>`
	${({ borderType: type }) => borders[type]}
`;

export default BorderedContainer;

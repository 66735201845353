import React, { createContext, useReducer } from 'react';
import { Action, AppContext } from '../interfaces/index';
import { reducer } from './reducer';

export const Context = createContext({});

export const cleanState = {
	searchTerm: '',
	searchType: '',
	selectedSituations: [],
	selectedProviders: [],
	selectedSocialReason: [],
	selectedDivisions: [],
	status: 'Active',
	currentPage: 0,
	showPreOrderDrawer: false,
	showRejectPODrawer: false,
	selectedEosServices: [],
	selectedEosInvoices: {},
	selectedEosStatus: [],
	selectedEosOtherStatus: [],
	situationTypes: [],
	selectedPolicies: [],
};

export const AppContextProvider = (props: { state: any; children: any }): JSX.Element => {
	return <Context.Provider value={props.state}>{props.children}</Context.Provider>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AppState = (props: any): JSX.Element => {
	const dispatch = (action: Action): void => _dispatch(action);
	const stateBackup = JSON.parse(localStorage.getItem('state') || '{}');
	const data: AppContext = {
		dispatch,
		...cleanState,
		...stateBackup,
	};

	const [state, _dispatch] = useReducer(reducer, data);
	localStorage.setItem('state', JSON.stringify(state));

	return <AppContextProvider state={state}>{props.children} </AppContextProvider>;
};

export const { Consumer } = Context;

export default AppState;

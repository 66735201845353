// external imports
import styled from 'styled-components';
// global imports
import BorderedContainer from 'components/Commons/BorderedContainer';
import BaseComboBox from 'components/Commons/ComboBox';
import BaseDropdown from 'components/Commons/Dropdown';
import BaseInput from 'components/Commons/Input';

interface IStyledInputSeparatorProps {
	$hasErrors: boolean;
}

interface IStyledInputProps {
	$gridArea?: string;
}

const FormContainer = styled(BorderedContainer)`
	display: flex;
	position: relative;
`;

const InputsContainer = styled.div`
	margin: 73px 65px 100px 55px;
	flex-grow: 1;
	display: flex;
	gap: 15px;
	width: calc(50% - 120px);

	@media only screen and (max-width: 1056px) {
		margin: 25px 18px 30px 15px;
		width: calc(50% - 33px);
	}

	@media only screen and (max-width: 760px) {
		margin-bottom: calc(20vh);
	}
`;

const LocationInputsContainer = styled.div`
	flex-grow: 1;
`;

const LocationInputsTitle = styled.h2`
	${({ theme }) => theme.typography.mobileH2};
	color: ${({ theme }) => theme.colors.textoGrisPrimario};
`;

const ComboBox = styled(BaseComboBox)`
	flex-grow: 1;
` as typeof BaseComboBox;

const Dropdown = styled(BaseDropdown)`
	flex-grow: 1;
` as typeof BaseDropdown;

const OptionalInputSeparator = styled.p`
	${({ theme }) => theme.typography.p3}
	color: ${({ theme }) => theme.colors.textoGrisPrimario};
	text-align: center;
	margin: 25px 0 25px 40px;
`;

const InputSeparator = styled.div<IStyledInputSeparatorProps>`
	height: ${({ $hasErrors }) => ($hasErrors ? '90px' : '110px')};
`;

const MapContainer = styled.div`
	width: 50%;
	background: gray;
	& > #map {
		width: 100%;
		height: 100%;
	}

	@media only screen and (max-width: 760px) {
		position: fixed;
		z-index: 1;
		top: 70vh;
		width: calc(100% - 60px);
		height: 30vh;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 10px 4px;
	}
`;

const LoadingContainer = styled.span`
	display: inline-block;
	margin-left: 10px;
`;

const Input = styled(BaseInput)<IStyledInputProps>`
	flex-grow: 1;
	margin-bottom: 10px;
`;

const S = {
	FormContainer,
	InputsContainer,
	LocationInputsContainer,
	LocationInputsTitle,
	ComboBox,
	Dropdown,
	OptionalInputSeparator,
	InputSeparator,
	MapContainer,
	LoadingContainer,
	Input,
};

export default S;

import React, { FunctionComponent, useState, useContext, useMemo } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';

import {
	HDI_MASCOTAS_Y_GENERALES_ID,
	QUALITAS_ID,
	SURA_ID,
	USER_TYPE,
	SURAMERICANA,
	VANTI,
} from '../../utils/constants';
import { Service, AppContext } from '../../interfaces';
import { Context } from '../../context/Context';

import Check from '../../assets/imgs/check.svg';
import HeliosClient from '../../api/HeliosClient';
import { toast } from 'react-toastify';
import Feature from '../../utils/FeatureFlags';
import { ACCOUNTS_CANNOT_WRITE_NOTES_WHEN_USER_IS_CLIENT, FEATURE_FLAG } from '../../utils/FeatureFlags/constants';

interface ServiceNotesProps {
	service: Service;
	allowAddNotes?: boolean;
}

function sortNotesByDate(notes: any[]) {
	return notes.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
}

const filterNotes = (notes: any[], userType: string, sfIdAccount: string) => {
	return notes.filter((note) => {
		if (userType === USER_TYPE.USER) {
			switch (sfIdAccount) {
				case QUALITAS_ID:
				case HDI_MASCOTAS_Y_GENERALES_ID:
				case SURAMERICANA:
				case VANTI:
					return !note.d2iNote && !note.hideNote && note.user.type === USER_TYPE.USER;
				case SURA_ID:
				default:
					return note.d2iNote && !note.hideNote;
			}
		}
		return !note.d2iNote && !note.hideNote;
	});
};

const ServiceNotes: FunctionComponent<ServiceNotesProps> = ({ service, allowAddNotes }) => {
	const { userType, user, provider } = useContext(Context) as AppContext;
	const [note, setNote] = useState<string>('');
	const [sortedNotes, setSortedNotes] = useState(() => {
		if (!service.notes) return [];
		return sortNotesByDate(service.notes);
	});

	const filteredNotes = useMemo(() => filterNotes(sortedNotes, userType, service.sfIdAccount), [
		sortedNotes,
		userType,
		service.sfIdAccount,
	]);

	// Assume by default that the user can write notes
	let CanWriteNotes = allowAddNotes;
	// Check if the account is restricted from writing notes and the user type is 'USER' (Coorporate Client)
	if (
		allowAddNotes &&
		ACCOUNTS_CANNOT_WRITE_NOTES_WHEN_USER_IS_CLIENT.includes(service.sfIdAccount) &&
		userType === USER_TYPE.USER
	) {
		CanWriteNotes = false;
	}

	const saveNote = async (): Promise<void> => {
		if (note) {
			const noteBody = {
				employeeId: user?._id ?? provider?._id,
				serviceId: service._id,
				body: note,
				userType: userType,
			};

			const {
				data: { status, data },
			} = await new HeliosClient().addNote(noteBody);

			if (status) {
				toast.info('Note Saved');
				setNote('');
				setSortedNotes(sortNotesByDate(data));
			}
		} else {
			toast.error('Notes field empty');
		}
	};

	return (
		<>
			<div className="container service-view__notes custom-scrollbar">
				{filteredNotes.length === 0 ? (
					<div className="nothing-found-alert">
						<p>No notes found</p>
					</div>
				) : (
					filteredNotes.map((note: any, idx: number) => (
						<div key={idx} className="row">
							<div className={cn('col-12', { 'bottom-line': idx + 1 < filteredNotes.length })}>
								<div className="row sum-gutters">
									<div className="col">
										<p className="service-view__notes__subtitle">
											From: <span className="text-bold">{note.employeeName}</span>
										</p>
									</div>
									<div className="col">
										<p className="service-view__notes__subtitle">
											Date: <span className="text-bold">{dayjs(note.date).format('MMMM D, YYYY, h:mm:ss a')}</span>
										</p>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<p className="service-view__notes-msg">{note.msg}</p>
									</div>
								</div>
								{note.audioUrl && (
									<div className="row">
										<div className="col">
											<audio controls>
												<source src={note.audioUrl} type="audio/wav" />
												Your browser does not support the audio element.
											</audio>
										</div>
									</div>
								)}
							</div>
						</div>
					))
				)}
			</div>
			{CanWriteNotes && (
				<Feature name={FEATURE_FLAG.SERVICE_DETAIL__NOTES_SECTION__WRITE} accountId={service.sfIdAccount}>
					<div className="mt-3" style={{ marginBottom: '10px' }}>
						<div className="row">
							<div className="col col-12">
								<div style={{ display: 'flex' }}>
									<textarea
										style={{ flexGrow: 1 }}
										className="form-control form-control-dark form-control-outline"
										maxLength={5000}
										placeholder="Note"
										value={note}
										name="note"
										onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => setNote(event.target.value)}
									></textarea>
									<div className="ml-2 mr-2" style={{ alignSelf: 'flex-end' }}>
										<button onClick={saveNote} style={{ flexGrow: 1 }} className="btn btn-circle btn-success">
											<img src={Check} alt="Check icon" style={{ height: '30px' }} />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Feature>
			)}
		</>
	);
};

export default ServiceNotes;

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/es';
dayjs.extend(timezone);

export const getLastPeriod = (tz: string): string => {
	const now = dayjs().tz(tz);
	let periodStart: dayjs.Dayjs;
	let periodEnd: dayjs.Dayjs;

	if (now.date() <= 15) {
		periodStart = now.subtract(1, 'month').date(16);
		periodEnd = now.subtract(1, 'month').endOf('month');
	} else {
		periodStart = now.startOf('month');
		periodEnd = now.date(15);
	}

	const formatPeriod = (date: dayjs.Dayjs) => date.format('D MMM YYYY');
	return `[${periodStart.date()} - ${formatPeriod(periodEnd)}]`;
};

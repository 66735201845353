// External imports
import React, { FunctionComponent } from 'react';

// Internal imports
import { BRANCH, HELIOS_SERVICE_STATUS } from 'utils/constants';
import { centsToCurrency, ProviderCostFields } from 'utils/Utils';

// Local imports
import InputGroup from './InputGroup';
import { IBranchConfig } from 'interfaces';

interface ProviderCost {
	name: string;
	value: number;
	total?: number;
	tax?: number;
}

interface ProviderCostsProps {
	providerCosts?: ProviderCost[];
	branch: BRANCH;
	status?: string;
	branchConfig: IBranchConfig;
}

/**
 * Parses the provider costs based on branch configuration.
 *
 * This function retrieves the branch configuration and filters the provider costs
 * based on the visibility of each field.
 *
 * @param {IBranchConfig} branchConfig - Current Branch Confguration.
 * @returns {Promise<Array<{ placeholder: string, fieldName: string }>>} - A promise that resolves to an array of objects containing the field name and editable status.
 */
const parseOtherCostsByBranchConfig = (branchConfig: IBranchConfig) => {
	const providerCosts = [...ProviderCostFields];

	const result = providerCosts.filter(({ fieldName }) => {
		const fieldConfig = branchConfig.otherCostsFieldsConfig[fieldName];
		return fieldConfig?.visible;
	});

	return result;
};

const ProviderCosts: FunctionComponent<ProviderCostsProps> = ({
	providerCosts,
	branch,
	status,
	branchConfig,
}): JSX.Element => {
	if (!providerCosts || providerCosts?.length === 0 || !branchConfig.otherCostsFieldsConfig) {
		return <></>;
	}

	const providerCostFields = parseOtherCostsByBranchConfig(branchConfig);
	const providerCostsCombinedArray = providerCostFields.map((field) => {
		const cost = providerCosts.find((cost) => cost.name === field.fieldName);

		const totalLabel = centsToCurrency(cost?.total || 0, branch);
		const value =
			status === HELIOS_SERVICE_STATUS.FINISHED
				? `${totalLabel} (Tax:${centsToCurrency(cost?.tax || 0, branch)})`
				: totalLabel;
		return { ...field, value };
	});

	return (
		<>
			{providerCostsCombinedArray.map((providerCost, index) => (
				<InputGroup key={index} colSize="col-4" label={providerCost.placeholder} value={providerCost.value} />
			))}
		</>
	);
};

export default ProviderCosts;

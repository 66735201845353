import React, { FunctionComponent, useContext, useRef, useState } from 'react';
import cn from 'classnames';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import './DashboardFilter.scss';
import { Context } from '../../context/Context';
import { AppContext } from '../../interfaces';
import { setSearchType } from '../../context/actions';

interface DashboardFilterProps {
	items: string[];
}

const DashboardFilter: FunctionComponent<DashboardFilterProps> = ({ items }): JSX.Element => {
	const { dispatch, searchType } = useContext(Context) as AppContext;
	const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);

	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef, setDropdownOpened);

	const selectOption = (option: string): void => {
		setSearchType(dispatch, option);
		setDropdownOpened(false);
	};

	return (
		<div className="h-filter-container">
			<div onClick={(): void => setDropdownOpened(true)} className="filter">
				<span>Search By: {searchType || items[1]}</span>
				<i className={cn({ 'fa fa-chevron-up': dropdownOpened, 'fa fa-chevron-down': !dropdownOpened })}></i>
			</div>
			{dropdownOpened && (
				<div ref={wrapperRef} className="filter-dropdown">
					{items.map((search, idx) => (
						<div onClick={(): void => selectOption(search)} key={idx} className="filter-dropdown__item">
							<span>{search}</span>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default DashboardFilter;

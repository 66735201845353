import React from 'react';
// @ts-expect-error: @carbon/react doesn't have full typescript support yet
import { Button as CarbonButton, Loading } from '@carbon/react';
import { IProps } from './types';
import S from './styles';

const Button = ({ kind, children, disabled, loading, className, onClick }: IProps): React.ReactElement => (
	<CarbonButton kind={kind} disabled={loading || disabled} onClick={onClick} className={className}>
		{loading && (
			<S.LoadingContainer>
				<Loading withOverlay={false} small />
			</S.LoadingContainer>
		)}
		{children}
	</CarbonButton>
);

export default Button;

// external imports
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
// Global imports
import { Context } from 'context/Context';
import { AppContext } from 'interfaces';
import { validateSchema } from 'utils/validations';
import { filterServiceTypesByAccount } from 'utils/Utils';
import { BRANCH, DASHBOARD_SECTION, DEFAULT_VEHICLE_MAKE_MODEL, VANTI_ID, VEHICLE_TYPES } from 'utils/constants';
import CommonAPIClient from 'api/CommonAPIClient';
import { dispatchServiceParseParams } from 'api/HeliosClient.utils';
import HeliosClient from 'api/HeliosClient';
import { setStatusFilter } from 'context/actions';
// Local imports
import { DispatchFormContext } from '../context/Context';
import { serviceDetailsSchema, serviceLocationsSchema } from '../schema';
import { IUseCreateServiceReturnValue } from '../types';
import { steps } from '../constants';

const useServiceForm = (): IUseCreateServiceReturnValue => {
	const {
		currentStep,
		setSelectedStep,
		client,
		vehicle,
		service,
		contract,
		locations,
		setServiceTypes,
		setVehicleMakes,
		setVehicleModels,
		setVehicleAttribute,
		setDetailsErrors,
		setLocationsErrors,
		setLoading,
	} = useContext(DispatchFormContext);
	const history = useHistory();
	const { user, situationTypes, dispatch } = useContext(Context) as AppContext;

	const initAccountServiceTypes = () => {
		let filteredServiceTypes;
		if (!user?.branch && !user?.otherServicesAllowed) {
			filteredServiceTypes = situationTypes;
		} else {
			const serviceTypesToProcess = user.account === VANTI_ID ? user.homeServicesAllowed : user.otherServicesAllowed;
			filteredServiceTypes = filterServiceTypesByAccount({
				serviceTypes: situationTypes,
				accountId: user.account,
				accountOtherServicesAllowed: serviceTypesToProcess as string[],
			});
		}
		setServiceTypes(filteredServiceTypes);
	};

	const getVehicleDataProps = {
		branch: user.branch as BRANCH,
		vehicleType: VEHICLE_TYPES.AUTO,
	};

	const initVehicleMakes = async () => {
		const {
			data: { data },
		} = await new CommonAPIClient().getVehicleMakes({ ...getVehicleDataProps });
		const makes: string[] = [...data.map((make) => make.make), DEFAULT_VEHICLE_MAKE_MODEL];
		setVehicleMakes(makes);
	};

	const setVehicleModelsByMake = async (make: string) => {
		if (make === DEFAULT_VEHICLE_MAKE_MODEL) {
			setVehicleModels([]);
			setVehicleAttribute('model', DEFAULT_VEHICLE_MAKE_MODEL);
			return;
		}

		const {
			data: { data },
		} = await new CommonAPIClient().getVehicleModels({ ...getVehicleDataProps }, make);
		const models: string[] = [...data.map((model) => model.model), DEFAULT_VEHICLE_MAKE_MODEL];
		setVehicleModels(models);
	};

	const validateServiceDetailsAndContinue = async (): Promise<void> => {
		setDetailsErrors(null);
		setLoading(true);
		const serviceDetailsFields = { ...client, ...vehicle, ...service };
		const errors = await validateSchema(serviceDetailsSchema, serviceDetailsFields, {
			branch: user.branch,
			account: user.account,
		});
		if (errors) {
			setDetailsErrors(errors);
		} else {
			setSelectedStep(steps.SERVICE_LOCATIONS);
		}
		setLoading(false);
	};

	const validateServiceLocationsAndSubmit = async (): Promise<void> => {
		setLocationsErrors(null);
		setLoading(true);
		const locationsFields = { ...locations };
		const destinationRequired = Boolean(service.situation?.fields?.destination?.required);
		const errors = await validateSchema(serviceLocationsSchema, locationsFields, {
			account: user.account,
			destinationRequired,
		});
		if (errors) {
			setLocationsErrors(errors);
		} else {
			await submitService();
		}
		setLoading(false);
	};

	const submitService = async () => {
		const serviceRequestParams = dispatchServiceParseParams({
			client,
			service,
			vehicle,
			locations,
			contract,
			accountId: user.account,
		});
		try {
			const response = await new HeliosClient().dispatchService(serviceRequestParams);
			if (response.status) {
				toast.success(`Servicio solicitado con éxito. PO# ${response.data.eventId}`, { autoClose: 20000 });
				setStatusFilter(dispatch, DASHBOARD_SECTION.HOLD_INSPECTION);
				history.push('/dashboard');
			} else {
				console.log({ response });
				toast.error('Ha ocurrido un error al solicitar el servicio');
			}
		} catch (error) {
			console.log(error);
			toast.error('Ha ocurrido un error al solicitar el servicio');
		}
	};

	return {
		initAccountServiceTypes,
		initVehicleMakes,
		currentStep,
		setVehicleModelsByMake,
		setSelectedStep,
		validateServiceDetailsAndContinue,
		validateServiceLocationsAndSubmit,
	};
};

export { useServiceForm };

import React, { useEffect, useState } from 'react';
import { ToggleButton } from 'primereact/togglebutton';
import { toast } from 'react-toastify';
import cn from 'classnames';

import ConfirmModal from '../../Commons/ConfirmModal';
import Modal from '../../Commons/Modal';

interface ConfirmActionProps {
	confirmValue: boolean;
	onClickAction: () => Promise<{ status: boolean; message: string }>;
	disabled: boolean;
}

const ConfirmAction = ({ confirmValue, onClickAction, disabled }: ConfirmActionProps) => {
	const [confirm, setConfirm] = useState<boolean>();
	const [loading, setLoading] = useState<boolean>(false);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

	useEffect(() => {
		handleSetConfirm(confirmValue);
	}, [confirmValue]);

	const handleSetConfirm = (value: boolean) => {
		setConfirm(() => !value);
	};

	const handleConfirm = async (value: boolean) => {
		try {
			setLoading(true);
			const { status, message } = await onClickAction();
			handleSetConfirm(value);
			if (status) toast.success(message);
			else toast.warn(message);
		} catch (error) {
			toast.error('Something went wrong, please try again');
		} finally {
			setLoading(false);
			setShowConfirmModal(false);
		}
	};

	const shouldShowConfirmModal = () => {
		if (confirm) {
			toast.warn('Service is already confirmed');
			return;
		}
		setShowConfirmModal(true);
	};

	return (
		<>
			<ToggleButton
				onLabel="Confirmed"
				offLabel="Confirm"
				checked={confirm}
				onChange={() => shouldShowConfirmModal()}
				className={cn('btn btn-sm', { 'p-disabled': disabled })}
			/>
			{showConfirmModal && (
				<Modal
					dismiss={(): void => {
						setShowConfirmModal(false);
					}}
				>
					<ConfirmModal
						onSubmit={async () => handleConfirm(true)}
						onCancelButtonClick={() => setShowConfirmModal(false)}
						isSubmitting={loading}
						modalText="Are you sure to confirm this service?"
						denyText="No, go back"
						confirmText="Yes, confirm this service"
					/>
				</Modal>
			)}
		</>
	);
};

export default ConfirmAction;

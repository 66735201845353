import React, { FunctionComponent } from 'react';

interface PaginationProps {
	currentPage: number;
	pageLimit: number;
	totalServices: number;
	onNext?: React.MouseEventHandler<HTMLButtonElement>;
	onPrevious?: React.MouseEventHandler<HTMLButtonElement>;
	onReturn?: React.MouseEventHandler<HTMLButtonElement>;
	loading?: boolean;
}

const Pagination: FunctionComponent<PaginationProps> = ({
	currentPage,
	pageLimit,
	totalServices,
	onNext,
	onPrevious,
	onReturn,
	loading,
}): JSX.Element => {
	const currentStart = currentPage * pageLimit + 1;

	const hidePrevBtn = currentPage === 0;
	const hideNextBtn = totalServices < 51;

	return (
		<div>
			<div className="dashboard__pagination">
				<button
					hidden={hidePrevBtn}
					className="btn--primary btn--round"
					onClick={onReturn}
					aria-label="firstPage"
					disabled={loading}
				>
					<i className="dashboard__pagination__icon fa fa-chevron-left"></i>
					<i className="dashboard__pagination__icon fa fa-chevron-left"></i>
				</button>
				<span>&nbsp;</span>
				<button
					hidden={hidePrevBtn}
					className="btn--primary btn--round"
					onClick={onPrevious}
					aria-label="previousPage"
					disabled={loading}
				>
					<i className="dashboard__pagination__icon fa fa-chevron-left"></i>
				</button>
				<span className="dashboard__pagination__pages" hidden={hidePrevBtn}>
					{currentPage}
				</span>
				<span className="dashboard__pagination__pages" hidden={hidePrevBtn}>
					-
				</span>
				<span className="dashboard__pagination__pages__main">{currentPage + 1} </span>
				<span className="dashboard__pagination__pages" hidden={hideNextBtn}>
					-
				</span>
				<span className="dashboard__pagination__pages" hidden={hideNextBtn}>
					{currentPage + 2}
				</span>
				<button
					hidden={hideNextBtn}
					className="btn--primary btn--round"
					onClick={onNext}
					aria-label="nextPage"
					disabled={loading}
				>
					<i className="dashboard__pagination__icon fa fa-chevron-right"></i>
				</button>
			</div>
			<div className="dashboard__pagination__space"></div>
		</div>
	);
};

export default Pagination;

import Axios, { AxiosResponse, AxiosStatic } from 'axios';
import { ConnectServicesClientUploadParams } from '../interfaces';

import { CONNECT_SERVICES_API, CONNECT_SERVICES_TOKEN, S3_BUCKET, S3_BUCKET_REGION } from '../utils/constants';
import HttpClient from './HttpClient';

// Usaremos Connect Services para traer los ficheros de S3 vinculados a un PO
export default class ConnectServicesClient extends HttpClient {
	constructor(axios: AxiosStatic = Axios) {
		super(axios, CONNECT_SERVICES_API);
	}

	async getImages(serviceNumber: number, bucket?: string, region?: string): Promise<AxiosResponse> {
		if (bucket && region) {
			return await this.get(`/api/v1/media/${serviceNumber}?bucketName=${bucket}&bucketRegion=${region}`);
		}
		return await this.get(`/api/v1/media/${serviceNumber}`);
	}

	/**
	 * Legacy code - SURA
	 */
	async getSuraImages(serviceNumber: number): Promise<AxiosResponse> {
		return await this.get(`/api/v1/images/PAN/all/${serviceNumber}`);
	}

	async upload({
		folderRoot,
		fileName,
		fileExtension,
		file,
		bucketName = S3_BUCKET,
		bucketRegion = S3_BUCKET_REGION,
	}: ConnectServicesClientUploadParams): Promise<AxiosResponse> {
		const formData = new FormData();
		formData.append('FolderRoot', folderRoot);
		formData.append('FileDataStream', file);
		formData.append('FileName', fileName);
		formData.append('FileExtension', fileExtension);
		if (bucketName) formData.append('BucketName', bucketName);
		if (bucketRegion) formData.append('BucketRegion', bucketRegion);

		return this.post('/api/v1/media', formData, {
			headers: {
				'content-type': 'multipart/form-data',
				Authorization: CONNECT_SERVICES_TOKEN,
			},
		});
	}
}

// my-theme.ts
import { DefaultTheme } from 'styled-components';

const FONT = "'Inter', sans-serif";

const styledComponentsTheme: DefaultTheme = {
	font: FONT,
	typography: {
		h2: `
      font-family: ${FONT};
      font-size: 3.75rem;
      font-weight: 700;
      line-height: 80px;
      letter-spacing: -1.5px;
    `,
		mobileH2: `
      font-family: ${FONT};
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0.15px;
      text-align: left;

    `,
		p2: `
      font-family: ${FONT};
      font-size: 1rem;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.4px;
    `,
		p3: `
      font-family: ${FONT};
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.25px;
    `,
		buttonMedium: `
      font-family: ${FONT};
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.5px;
    `,
	},
	colors: {
		white: '#FFF',
		naranjaConnect: '#F87000',
		naranja01: '#A84526',
		naranja02: '#DD620D',
		naranja03: '#FA9D51',
		naranja04: '#FDCBA2',
		naranja05: '#FFF3EA',
		textoGrisPrimario: '#111B22',
		textoGrisTerciario: '#8D8E91',
		textoAzulPrimario: '#254E70',
		textoNaranjaSecundario: '#DD620D',
		azul02: '#4799B4',
		azul04: '#CCE2E9',
		azul05: '#EBF1F5',
		alertasError: '#F44336',
		alertasErrorClaro: '#FFF6F6',
		gris: '#C6C7C8',
	},
};

export { styledComponentsTheme };

// External imports
import styled from 'styled-components';
// Global import
import BaseButton from 'components/Commons/Button';
// Local import
import { IStypedProps } from './types';

const NavigationButtonsContainer = styled.div`
	margin-left: 20px;
`;

const Button = styled(BaseButton)<IStypedProps>`
	width: ${({ kind }) => (kind === 'ghost' ? '100px' : '295px')};
	margin-left: ${({ $last }) => ($last ? '7px' : '0')};

	@media only screen and (max-width: 1350px) {
		width: ${({ kind }) => (kind === 'ghost' ? '100px' : '210px')};
	}

	@media only screen and (max-width: 760px) {
		width: ${({ kind }) => (kind === 'ghost' ? '100px' : '180px')};
	}
`;

const S = { NavigationButtonsContainer, Button };

export default S;

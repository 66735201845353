import React from 'react';

import { Service, ServiceType } from '../../interfaces';
import { BRANCH, USER_TYPE } from '../../utils/constants';
import { getSituationType } from '../../utils/Utils';
import { distanceFormat } from '../../views/Service/Service.util';

import './styles.scss';

interface TablePropertiesI {
	header: string;
	hiddenColumn: boolean;
	value: ({ serviceIndex, service }: { serviceIndex: number; service: Service }) => number | string | JSX.Element;
	showHeader?: boolean;
}

const getDefaultTableProperties = ({
	userType,
	situationTypes,
	branch,
}: {
	userType?: string;
	situationTypes: ServiceType[];
	branch?: BRANCH;
}): TablePropertiesI[] => {
	return [
		{
			header: '#',
			hiddenColumn: false,
			value: ({ serviceIndex }) => <b>{serviceIndex + 1}</b>,
			showHeader: false,
		},
		{
			header: 'Dispatched Date',
			hiddenColumn: false,
			value: ({ service }) => service.dispatchedDateDay,
			showHeader: true,
		},
		{
			header: 'Dispatched Time',
			hiddenColumn: false,
			value: ({ service }) => service.dispatchedDateTime,
			showHeader: true,
		},
		{
			header: 'Name',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service.firstname + ' ' + service.lastname,
			showHeader: true,
		},
		{
			header: 'Phone',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service.phone1,
			showHeader: true,
		},
		{
			header: 'Service #',
			hiddenColumn: false,
			value: ({ service }) => service.serviceNumber,
			showHeader: true,
		},
		{
			header: 'Situation',
			hiddenColumn: false,
			value: ({ service }) => getSituationType(service.situation, situationTypes),
			showHeader: true,
		},
		{
			header: 'Policy',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service?.insurance?.policyNumber || service?.policyNumber,
			showHeader: true,
		},
		{
			header: 'Policy Pin',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service?.insurance?.policyType || service?.customerContract?.policy?.symbol,
			showHeader: true,
		},
		{
			header: 'Policy Sub-Section',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service?.insurance?.subSection || service?.insurance?.policyUnit,
			showHeader: true,
		},
		{
			header: 'Policy Status',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service.insurance && service.insurance.status,
			showHeader: true,
		},
		{
			header: 'Plate',
			hiddenColumn: false,
			value: ({ service }) => service.plate,
			showHeader: true,
		},
		{
			header: 'Make',
			hiddenColumn: false,
			value: ({ service }) => service.make,
			showHeader: true,
		},
		{
			header: 'Model',
			hiddenColumn: false,
			value: ({ service }) => service.model,
			showHeader: true,
		},
		{
			header: 'VIN',
			hiddenColumn: false,
			value: ({ service }) => service.vin,
			showHeader: true,
		},
		{
			header: 'On-Route Time',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service.onRouteTime,
			showHeader: true,
		},
		{
			header: 'Arrived Time',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service.arrivedTime,
			showHeader: true,
		},
		{
			header: 'Towed Time',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service.towedTime,
			showHeader: true,
		},
		{
			header: 'Finished/Cancelled Time',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service.finishedTime,
			showHeader: true,
		},
		{
			header: 'Arrival Duration',
			hiddenColumn: false,
			value: ({ service }) => service.arrivalDuration,
			showHeader: true,
		},
		{
			header: 'Province',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service.locations && service.locations.situation && service.locations.situation.province,
			showHeader: true,
		},
		{
			header: 'Canton',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service.locations && service.locations.situation && service.locations.situation.canton,
			showHeader: true,
		},
		{
			header: 'District',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service.locations && service.locations.situation && service.locations.situation.district,
			showHeader: true,
		},
		{
			header: 'Situation Address',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service.locations && service.locations.situation && service.locations.situation.address,
			showHeader: true,
		},
		{
			header: 'Trip Status',
			hiddenColumn: branch === BRANCH.CRC,
			value: ({ service }) => service.trip.statusLabel,
			showHeader: true,
		},
		{
			header: 'Driver',
			hiddenColumn: false,
			value: ({ service }) =>
				service.driver && service.driver.name ? service.driver.name : service.driverName || 'N/A',
			showHeader: true,
		},
		{
			header: 'Start Address',
			hiddenColumn: userType !== USER_TYPE.PROVIDER,
			value: ({ service }) => service.pinStartRoadAddress || service.pinSituationAddress,
			showHeader: true,
		},
		{
			header: 'Finish Address',
			hiddenColumn: userType !== USER_TYPE.PROVIDER,
			value: ({ service }) => service.pinFinishRoadAddress || 'N/A',
			showHeader: true,
		},
		{
			header: 'City',
			hiddenColumn: false,
			value: ({ service }) => service.serviceCity,
			showHeader: true,
		},
		{
			header: 'Year',
			hiddenColumn: userType !== USER_TYPE.PROVIDER,
			value: ({ service }) => service.year,
			showHeader: true,
		},
		{
			header: 'Provider Distance',
			hiddenColumn: userType !== USER_TYPE.PROVIDER && branch !== BRANCH.PRI,
			value: ({ service }) => distanceFormat(service.branch, service.providerDistance),
			showHeader: true,
		},
		{
			header: 'Distance',
			hiddenColumn: userType !== USER_TYPE.PROVIDER,
			value: ({ service }) => distanceFormat(service.branch, service.distance),
			showHeader: true,
		},
		{
			header: 'ETA',
			hiddenColumn: userType !== USER_TYPE.PROVIDER,
			value: ({ service }) => (service.trip && service.trip.live ? service.trip.live.eta : '00:00'),
			showHeader: true,
		},
		{
			header: 'Arrived Time',
			hiddenColumn: userType !== USER_TYPE.PROVIDER,
			value: ({ service }) => service.arrivedTime,
			showHeader: true,
		},
		{
			header: 'Towed Time',
			hiddenColumn: userType !== USER_TYPE.PROVIDER,
			value: ({ service }) => service.towedTime,
			showHeader: true,
		},
		{
			header: 'Elapsed Time',
			hiddenColumn: userType !== USER_TYPE.PROVIDER,
			value: ({ service }) => service.elap,
			showHeader: true,
		},
		{
			header: 'Latitud',
			hiddenColumn: userType !== USER_TYPE.USER && branch !== BRANCH.CRC,
			value: ({ service }) =>
				service?.locations?.situation && service?.locations?.situation.lat ? service?.locations?.situation.lat : '',
			showHeader: true,
		},
		{
			header: 'Longitud',
			hiddenColumn: userType !== USER_TYPE.USER && branch !== BRANCH.CRC,
			value: ({ service }) =>
				service?.locations?.situation && service?.locations?.situation.lng ? service?.locations?.situation.lng : '',
			showHeader: true,
		},
		{
			header: 'Claim #',
			hiddenColumn: userType !== USER_TYPE.USER && branch !== BRANCH.CRC,
			value: ({ service }) =>
				service.insurance && service.insurance.reportNumber ? service.insurance.reportNumber : '',
			showHeader: true,
		},
		{
			header: 'External ID',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service?.external?.id,
			showHeader: true,
		},
	];
};

const getHoldInspectionTableProperties = ({
	userType,
	situationTypes,
}: {
	userType?: string;
	situationTypes: ServiceType[];
	branch?: BRANCH;
}): TablePropertiesI[] => {
	return [
		{
			header: '#',
			hiddenColumn: false,
			value: ({ serviceIndex }: { serviceIndex: number }) => <b>{serviceIndex + 1}</b>,
			showHeader: false,
		},
		{
			header: 'Service #',
			hiddenColumn: false,
			value: ({ service }) => service.serviceNumber,
			showHeader: true,
		},
		{
			header: 'Situation',
			hiddenColumn: false,
			value: ({ service }) => getSituationType(service.situation, situationTypes),
			showHeader: true,
		},
		{
			header: 'Dispatched Date',
			hiddenColumn: false,
			value: ({ service }) => service.dispatchedDateDay,
			showHeader: true,
		},
		{
			header: 'Dispatched Time',
			hiddenColumn: false,
			value: ({ service }) => service.dispatchedDateTime,
			showHeader: true,
		},
		{
			header: 'Driver',
			hiddenColumn: false,
			value: ({ service }) =>
				service.driver && service.driver.name ? service.driver.name : service.driverName || 'N/A',
			showHeader: true,
		},
		{
			header: 'Start Address',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service.pinStartRoadAddress || service.pinSituationAddress || 'N/A',
			showHeader: true,
		},
		{
			header: 'Finish Address',
			hiddenColumn: userType !== USER_TYPE.USER,
			value: ({ service }) => service.pinFinishRoadAddress || 'N/A',
			showHeader: true,
		},
		{
			header: 'Account',
			hiddenColumn: false,
			value: ({ service }) => service.account,
			showHeader: true,
		},
		{
			header: 'City',
			hiddenColumn: false,
			value: ({ service }) => service?.serviceCity ?? 'N/A',
			showHeader: true,
		},
		{
			header: 'Plate',
			hiddenColumn: false,
			value: ({ service }) => service?.plate ?? 'N/A',
			showHeader: true,
		},
	];
};

export const getReportTableProperty = ({
	status,
	userType,
	situationTypes,
	branch,
}: {
	status: string;
	userType?: string;
	situationTypes: ServiceType[];
	branch?: BRANCH;
}) => {
	const defaultProperties = getDefaultTableProperties({ userType, situationTypes, branch });

	const tableProperties: { [key: string]: any } = {
		Finished: [...defaultProperties],
		Active: [...defaultProperties],
		Cancelled: [
			...defaultProperties,
			{
				header: 'Cancellation Reason',
				hiddenColumn: false,
				value: ({ service }: { service: Service }) => service.cancellationReason,
				showHeader: true,
			},
		],
		HoldInspection: getHoldInspectionTableProperties({ userType, situationTypes }),
		'Not Covered': [...defaultProperties],
		Informative: [...defaultProperties],
	};

	return tableProperties[status];
};

const msgDefaultValue = {
	sticky: true,
	severity: 'info',
	closable: false,
};

export const specifyParametersStartDateMsg = {
	...msgDefaultValue,
	detail: 'Specify report parameters first: Start date',
};

export const specifyParametersEndDateMsg = {
	...msgDefaultValue,
	detail: 'Specify report parameters first: End date',
};

export const noRecordsFoundsMsgData = {
	...msgDefaultValue,
	severity: 'warn',
	detail: 'No services found. Please try searching with a different start/end date or status.',
};

// External imports
import React, { useEffect, useContext } from 'react';
// @ts-expect-error: @carbon/react doesn't have full typescript support yet
import { Loading } from '@carbon/react';
import { GoogleMap, Marker } from '@react-google-maps/api';
// Global imports
import startLocationPin from 'assets/imgs/start-location-pin.svg';
import endLocationPin from 'assets/imgs/end-location-pin.svg';
import { Context } from 'context/Context';
import { AppContext, FixedLocation } from 'interfaces';
// Local imports
import DecorativeLocationsLegend from './DecorativeLocationsLegend';
import S from './styles';
import T from '../../texts';
import LocationInput from './LocationInput';
import { AutocompletePrediction } from './types';
import useLocation from './useLocation';
import { AFIRME_MEXICO_ID, ATLAS_MEXICO_ID, BRANCH, VANTI_ID } from 'utils/constants';
import { DEFAULT_MAP_CENTER } from './constants';
import useMap from './useMap';
import { DispatchFormContext } from 'views/CreateService/context/Context';
import LocationInputError from './LocationInputError';

const ServiceLocations = (): React.ReactElement => {
	const { user } = useContext(Context) as AppContext;
	const { service, locationsErrors: errors, loading, setLocationsAttribute, locations } = useContext(
		DispatchFormContext
	);
	const { mapLoaded, onMapLoad, onMapUnmount, mapTools } = useMap();
	const situation = useLocation(
		'situation',
		user.branch as BRANCH,
		mapLoaded,
		mapTools.places,
		mapTools.autocomplete,
		mapTools.geocoder
	);
	const destination = useLocation(
		'destination',
		user.branch as BRANCH,
		mapLoaded,
		mapTools.places,
		mapTools.autocomplete,
		mapTools.geocoder
	);
	const destinationVisible = Boolean(service.situation?.fields?.destination?.visible);
	const destinationRequired = Boolean(service.situation?.fields?.destination?.required);
	const displayFixedLocations = Array.isArray(user.fixedLocations) && user.fixedLocations.length > 0;
	const vantiHogarReferenceAddressVisible = VANTI_ID === user.account;

	useEffect(() => {
		if (mapLoaded) {
			if (!situation.location && !destination.location) {
				mapTools.map?.setCenter(DEFAULT_MAP_CENTER[user.branch as BRANCH]);
				mapTools.map?.setZoom(13);
			} else {
				const bounds = new window.google.maps.LatLngBounds();
				if (situation.location) {
					bounds.extend(situation.location);
				}
				if (destination.location) {
					bounds.extend(destination.location);
				}
				if (mapTools.map) {
					mapTools.map.fitBounds(bounds);
				}
			}
		}
	}, [mapLoaded, situation.location, destination.location]);

	return (
		<S.FormContainer borderType="shadow-highlight">
			<S.InputsContainer>
				<DecorativeLocationsLegend
					destinationFieldVisible={destinationVisible}
					secondaryInputsVisible={displayFixedLocations}
				/>
				<S.LocationInputsContainer>
					<LocationInputError error={errors?.situation ?? errors?.referenceAddress} addMarginTop={!destinationVisible}>
						<S.LocationInputsTitle>
							{destinationVisible ? T.locations.originTitle : T.locations.locationTitle}
							{situation.loading && (
								<S.LoadingContainer>
									<Loading withOverlay={false} small />
								</S.LoadingContainer>
							)}
						</S.LocationInputsTitle>
						{vantiHogarReferenceAddressVisible && (
							<LocationInput>
								<S.Input
									id={T.locations.referenceAddress.id}
									labelText={T.locations.referenceAddress.label}
									value={locations.referenceAddress as string}
									onChange={setLocationsAttribute}
									required={user.account === VANTI_ID}
								/>
							</LocationInput>
						)}
						{!vantiHogarReferenceAddressVisible && (
							<LocationInput>
								<S.ComboBox<AutocompletePrediction>
									id={T.locations.situationAutocomplete.id}
									label={T.locations.situationAutocomplete.label}
									onInputChange={situation.searchAutocompleteOptions}
									items={situation.autocompleteOptions}
									selectedItem={situation.selectedAutocompleteOption}
									onChange={(_, val) => {
										situation.selectAutocompleteOption(val);
									}}
									itemToString={(item) => item?.description}
									required={![ATLAS_MEXICO_ID, AFIRME_MEXICO_ID].includes(user.account)}
									disabled={loading}
								/>
							</LocationInput>
						)}

						{displayFixedLocations && (
							<>
								<S.OptionalInputSeparator>{T.locations.optionalInputSeparator}</S.OptionalInputSeparator>
								<LocationInput>
									<S.Dropdown<FixedLocation>
										id={T.locations.situationFixedLocation.id}
										label={T.locations.situationFixedLocation.label}
										items={user.fixedLocations}
										selectedItem={situation.selectedFixedLocation}
										onChange={situation.updateLocationFromFixedLocation}
										disabled={loading}
									/>
								</LocationInput>
							</>
						)}
					</LocationInputError>
					<S.InputSeparator $hasErrors={Boolean(errors?.situation)} />
					{destinationVisible && (
						<>
							<LocationInputError error={errors?.destination}>
								<S.LocationInputsTitle>
									{T.locations.destinationTitle}
									{destination.loading && (
										<S.LoadingContainer>
											<Loading withOverlay={false} small />
										</S.LoadingContainer>
									)}
								</S.LocationInputsTitle>
								<LocationInput>
									<S.ComboBox<AutocompletePrediction>
										id={T.locations.destinationAutocomplete.id}
										label={T.locations.destinationAutocomplete.label}
										onInputChange={destination.searchAutocompleteOptions}
										items={destination.autocompleteOptions}
										selectedItem={destination.selectedAutocompleteOption}
										onChange={(_, val) => {
											destination.selectAutocompleteOption(val);
										}}
										itemToString={(item) => item?.description}
										required={![ATLAS_MEXICO_ID, AFIRME_MEXICO_ID].includes(user.account) && destinationRequired}
										disabled={loading}
									/>
								</LocationInput>
								{displayFixedLocations && (
									<>
										<S.OptionalInputSeparator>{T.locations.optionalInputSeparator}</S.OptionalInputSeparator>
										<LocationInput>
											<S.Dropdown<FixedLocation>
												id={T.locations.situationFixedLocation.id}
												label={T.locations.situationFixedLocation.label}
												items={user.fixedLocations}
												selectedItem={destination.selectedFixedLocation}
												onChange={destination.updateLocationFromFixedLocation}
												disabled={loading}
											/>
										</LocationInput>
									</>
								)}
							</LocationInputError>
							<S.InputSeparator $hasErrors={Boolean(errors?.destination)} />
						</>
					)}
				</S.LocationInputsContainer>
			</S.InputsContainer>
			<S.MapContainer>
				{mapLoaded && (
					<GoogleMap
						id="map"
						zoom={13}
						center={DEFAULT_MAP_CENTER[user.branch as BRANCH]}
						options={{
							fullscreenControl: false,
							mapTypeControl: false,
						}}
						onLoad={onMapLoad}
						onUnmount={onMapUnmount}
					>
						{situation.location && (
							<Marker
								position={situation.location}
								icon={{
									url: destinationVisible ? startLocationPin : endLocationPin,
								}}
								draggable={!loading}
								onDragEnd={situation.updateLocationAndComboBoxPredictions}
							/>
						)}
						{destinationVisible && destination.location && (
							<Marker
								position={destination.location}
								icon={{
									url: endLocationPin,
								}}
								draggable={!loading}
								onDragEnd={destination.updateLocationAndComboBoxPredictions}
							/>
						)}
					</GoogleMap>
				)}
			</S.MapContainer>
		</S.FormContainer>
	);
};

export default ServiceLocations;

import CommonAPIClient from 'api/CommonAPIClient';
import { BRANCH, DEFAULT_VEHICLE_MAKE_MODEL, VEHICLE_TYPES } from 'utils/constants';

interface VehicleInfo {
	make: string;
	model: string;
	otherMake: string;
	otherModel: string;
}

/**
 * It returns the vehicle data (make/model), which if it does not match the vehicle data in our database, sets the OTHER Make/Model fields respectively
 */
export const getVehicleMakeModel = async (
	vehicleMakes: string[],
	make: string,
	model: string,
	branch: BRANCH
): Promise<VehicleInfo> => {
	const defaultInfo: VehicleInfo = {
		make: DEFAULT_VEHICLE_MAKE_MODEL,
		model: DEFAULT_VEHICLE_MAKE_MODEL,
		otherMake: '',
		otherModel: '',
	};

	if (!make && !model) {
		return defaultInfo;
	}

	if (!make) {
		return {
			...defaultInfo,
			model: DEFAULT_VEHICLE_MAKE_MODEL,
			otherModel: model,
		};
	}

	const foundVehicleMake = vehicleMakes.includes(make);
	if (foundVehicleMake) {
		if (model) {
			const models = await fetchVehicleModelsByMake(make, branch);
			const foundVehicleModel = models.includes(model);
			return {
				make,
				model: foundVehicleModel ? model : DEFAULT_VEHICLE_MAKE_MODEL,
				otherModel: foundVehicleModel ? '' : model,
				otherMake: '',
			};
		} else {
			return {
				make,
				model: DEFAULT_VEHICLE_MAKE_MODEL,
				otherMake: '',
				otherModel: '',
			};
		}
	}

	return {
		...defaultInfo,
		otherMake: make,
		otherModel: model,
	};
};

const fetchVehicleModelsByMake = async (make: string, branch: BRANCH): Promise<string[]> => {
	const getVehicleDataProps = {
		branch,
		vehicleType: VEHICLE_TYPES.AUTO,
	};
	const {
		data: { data },
	} = await new CommonAPIClient().getVehicleModels({ ...getVehicleDataProps }, make);
	return data.map((model) => model.model);
};

// External imports
import styled from 'styled-components';
// @ts-expect-error: @carbon/react doesn't have full typescript support yet
import { CloseFilled } from '@carbon/react/icons';
// Local imports

const InputContainer = styled.div`
	position: relative;
`;

const ClearInputIcon = styled(CloseFilled)`
	cursor: pointer;
	position: absolute;
	color: ${({ theme }) => theme.colors.azul05};
	z-index: 1;
	top: 17.5px;
	right: 20px;

	& > path[data-icon-path='inner-path'] {
		opacity: 1;
		fill: ${({ theme }) => theme.colors.textoAzulPrimario};
	}

	&.invalid {
		&:first-child {
			opacity: 1;
			fill: ${({ theme }) => theme.colors.alertasError};
		}
		& > path[data-icon-path='inner-path'] {
			opacity: 0;
		}
	}

	&.disabled {
		cursor: not-allowed;
		&:first-child {
			opacity: 1;
			fill: ${({ theme }) => theme.colors.gris};
		}
		& > path[data-icon-path='inner-path'] {
			opacity: 0;
		}
	}
`;

export { InputContainer, ClearInputIcon };

import React, { FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { GoogleMap, InfoBox, Marker, useLoadScript } from '@react-google-maps/api';

import Navbar from '../../components/Navbar';
import DriverInfoBox from '../../components/Map/DriverInfoBox';

import HeliosClient from '../../api/HeliosClient';
import { getServicesCount, getVehicleIcon } from './MapView.utils';
const LIBRARIES = ['places'];

import './styles.scss';
import { AppContext, HttpDriver, ActiveService, Provider } from '../../interfaces';
import { BRANCH } from '../../utils/constants';
import { Context } from '../../context/Context';

export const getVisibleDrivers = (
	drivers: HttpDriver[],
	activeServices: ActiveService[],
	provider?: Provider | undefined
): any => {
	if (!drivers || !activeServices) return {};
	const isProvider = Boolean(provider);

	const driversArr: any = [];
	for (const driver of drivers) {
		if (isProvider && driver.provider.vendorId === provider?.vendorId) {
			driversArr.push(driver);
		} else if (!isProvider) {
			// is corporate user
			for (const service of activeServices) {
				if (service.driver.id === driver._id) driversArr.push(driver);
			}
		}
	}
	const driversObj: any = {};
	for (const driver of driversArr) {
		driver.initials = driver.email.substring(0, 2);
		driver.position = [driver.lat, driver.lng];
		driver.iconset = driver.isSura ? 'sura' : driver.iconset;
		driver.status = driver.status || '';
		driver.tripStatus = driver.tripStatus || '';
		driversObj[driver._id] = driver;
	}
	return driversObj;
};

const MapView: FunctionComponent = (): JSX.Element => {
	const { provider, user, userType } = useContext(Context) as AppContext;
	const [coords, setCoords] = useState<{ lat: number; lng: number }>({ lat: 18.470181, lng: -66.124236 });
	const [displayDriverInfo, setDisplayDriverInfo] = useState<boolean>(false);
	const [selectedDriver, setSelectedDriver] = useState<HttpDriver | null>();
	const mapInstanceRef = useRef<any>(null);
	const [activeServices, setActiveServices] = useState<ActiveService[]>([]);
	const [drivers, setDrivers] = useState<HttpDriver[]>([]);
	const [staticDrivers, setStaticDrivers] = useState<HttpDriver[]>([]);

	const branch = provider?.branch || user?.branch;

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY as string,
		preventGoogleFontsLoading: true,
		libraries: LIBRARIES as any,
	});

	const onLoad = useCallback(function onLoad(mapInstance) {
		mapInstanceRef.current = mapInstance;
	}, []);

	useEffect(() => {
		setInitialMapView();
	}, []);

	useEffect(() => {
		getLiveDrivers();
		getActiveServices();
		getStaticDrivers();
		const liveDriversTimer = setInterval(getLiveDrivers, 10000);
		const activeServicesTimer = setInterval(getActiveServices, 30000);
		return () => {
			clearInterval(activeServicesTimer);
			clearInterval(liveDriversTimer);
		};
	}, []);

	const getStaticDrivers = async (): Promise<void> => {
		const {
			data: { data },
		} = await new HeliosClient().getStaticDrivers(branch);
		setStaticDrivers(data);
	};

	const getActiveServices = async (): Promise<void> => {
		const queryParams: any = {
			branch: branch,
		};

		const {
			data: { data },
		} = await new HeliosClient().getActiveServices(queryParams);
		setActiveServices(data);
	};

	const getLiveDrivers = async (): Promise<void> => {
		const {
			data: { data },
		} = await new HeliosClient().getActiveDrivers(branch);
		setDrivers(data);
	};

	const setInitialMapView = async (): Promise<void> => {
		switch (branch) {
			case BRANCH.PAN:
				setCoords({ lat: 9.002204, lng: -79.513971 });
				break;
			case BRANCH.CRC:
				setCoords({ lat: 9.931216, lng: -84.101158 });
				break;
			case BRANCH.COL:
				setCoords({ lat: 4.71031, lng: -74.10688 });
				break;
			case BRANCH.MEX:
				setCoords({ lat: 19.50106194686252, lng: -99.12402313925045 });
				break;
			case BRANCH.PRI:
			default:
				setCoords({ lat: 18.470181, lng: -66.124236 });
				break;
		}
	};

	const getDriverService = async (driver: HttpDriver): Promise<void> => {
		setSelectedDriver(driver);
		setDisplayDriverInfo(!displayDriverInfo);
	};

	const getSelectedServices = (driver: HttpDriver): ActiveService[] | undefined => {
		return activeServices.filter((service: any) => service.driver.id === driver._id && service.trip.status !== 'new');
	};

	const closeDriverInfo = (): void => {
		setDisplayDriverInfo(false);
		setSelectedDriver(null);
	};

	const getStampStatusName = (status: string): string => {
		const result = status.charAt(0).toUpperCase() + status.slice(1);
		return result.replace('_', '');
	};

	const visibleDrivers = getVisibleDrivers([...staticDrivers, ...drivers], activeServices, provider);

	return (
		<div className="map-view">
			<Navbar />
			{isLoaded && (
				<GoogleMap
					onLoad={onLoad}
					id="map"
					zoom={13}
					center={coords}
					options={{
						fullscreenControl: true,
						mapTypeControl: false,
					}}
				>
					{displayDriverInfo && selectedDriver && (
						<DriverInfoBox
							userType={userType}
							selectedDriver={selectedDriver}
							selectedServices={getSelectedServices(selectedDriver)}
							closeDriverInfo={closeDriverInfo}
							getStampStatusName={getStampStatusName}
						/>
					)}
					{Object.keys(visibleDrivers).length > 0 &&
						Object.values(visibleDrivers).map((driver: any, idx: number) => (
							<React.Fragment key={idx}>
								<Marker
									position={{ lat: driver.lat, lng: driver.lng }}
									icon={{
										url: getVehicleIcon(driver?.vehicleType, driver?.iconset),
										scaledSize: { width: 55, height: 55 } as google.maps.Size,
									}}
									onClick={(): Promise<void> => getDriverService(driver)}
								/>
								<InfoBox
									position={{ lat: driver.lat, lng: driver.lng }}
									options={{ closeBoxURL: '', enableEventPropagation: true }}
								>
									<div
										className={`info-box info-box--${driver.tripStatus}
									 info-box--${driver.status}`}
									>
										{getServicesCount(driver, activeServices)} / {driver.initials}
									</div>
								</InfoBox>
							</React.Fragment>
						))}
				</GoogleMap>
			)}
		</div>
	);
};

export default MapView;

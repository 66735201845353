import React, { FunctionComponent, useContext, useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import flatten from 'lodash/flatten';

import { Context } from '../../context/Context';
import { AppContext, Invoice } from '../../interfaces';
import TableRow from './TableRow';
import Drawer from '../Commons/Drawer';
import Backdrop from '../Commons/Backdrop';
import InvoiceDetails from '../Invoice/InvoiceDetails';
import Feature from '../../utils/FeatureFlags';

import { centsToCurrency, formatPads } from '../../utils/Utils';
import { DASHBOARDS_DATE_FORMAT, EOS_STATUS, USER_TYPE } from '../../utils/constants';
import { formatTimestampToCountryTz, formatUTC, isValidDate } from 'utils/datetime.utils';
import { setSelectedEosInvoices } from '../../context/actions';
import Button from '../DesignSystem/Button';
import { FEATURE_FLAG } from '../../utils/FeatureFlags/constants';
import EosClient from '../../api/EosClient';
import { toast } from 'react-toastify';

interface InvoiceTableProps {
	eosInvoices?: Invoice[];
}

const InvoiceTable: FunctionComponent<InvoiceTableProps> = ({ eosInvoices: eosInvoices = [] }) => {
	const { dispatch, userType, selectedEosInvoices, user } = useContext(Context) as AppContext;
	const [showDrawer, setShowDrawer] = useState(false);
	const [invoiceId, setInvoiceId] = useState('');
	const [allSelected, setAllSelected] = useState<boolean>(false);
	const [isLoadingPay, setIsLoadingPay] = useState<boolean>(false);

	const onInvoiceClick = (id: string) => {
		setInvoiceId(id);
		setShowDrawer(!showDrawer);
	};
	const onDismissDrawer = () => {
		setShowDrawer(!showDrawer);
		setInvoiceId('');
	};

	const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const selected = e.target?.checked;
		setAllSelected(selected);
		const currentInvoicesSelected = { ...selectedEosInvoices };
		eosInvoices?.map((invoice) => {
			if (selected && invoice.status === EOS_STATUS.PENDING) {
				currentInvoicesSelected[invoice.id] = invoice;
			} else {
				delete currentInvoicesSelected[invoice.id];
			}
		});
		setSelectedEosInvoices(dispatch, currentInvoicesSelected);
	};

	const selectable = true;

	const payInvoices = async () => {
		try {
			setIsLoadingPay(true);
			const services = map(selectedEosInvoices, (invoice) => map(invoice.service, 'serviceNumber'));
			const invoiceIds = map(selectedEosInvoices, 'id');
			await new EosClient().upsertEosInvoicePayment({
				invoiceIds,
				services: flatten(services),
				user,
			});
			setSelectedEosInvoices(dispatch, {});
			toast.success(`Invoices ${invoiceIds.join(', ')} has been successfully paid`);
		} catch (error) {
			console.log(error);
			toast.error('Something went wrong, please try again');
		} finally {
			setIsLoadingPay(false);
		}
	};

	const handleCheckInvoice = (e: React.ChangeEvent<HTMLInputElement>, invoice: Invoice) => {
		const selected = e.target?.checked;
		const currentInvoicesSelected = { ...selectedEosInvoices };
		if (selected) {
			currentInvoicesSelected[invoice.id] = invoice;
		} else {
			delete currentInvoicesSelected[invoice.id];
		}
		setSelectedEosInvoices(dispatch, currentInvoicesSelected);
	};

	useEffect(() => {
		const pendingInvoices = eosInvoices?.filter((invoice) => invoice.status === EOS_STATUS.PENDING);
		if (pendingInvoices?.every((eosInvoice) => !!selectedEosInvoices[eosInvoice.id])) {
			setAllSelected(true);
		} else {
			setAllSelected(false);
		}
	}, [selectedEosInvoices]);

	const someSelectedItemOnCurrentPage = () => {
		const pendingInvoices = eosInvoices?.filter((invoice) => invoice.status === EOS_STATUS.PENDING);
		return pendingInvoices?.some((eosInvoice) => !!selectedEosInvoices[eosInvoice.id]);
	};

	return (
		<>
			<div>
				{!isEmpty(selectedEosInvoices) && (
					<div className="table-actions">
						<span>{`${Object.keys(selectedEosInvoices).length} items selected.`}</span>
						<Button variant="outlined" isLoading={isLoadingPay} onClick={payInvoices}>
							CONFIRM PAYMENT
						</Button>
					</div>
				)}
			</div>
			<div className="eos-table eos-table--hover">
				<Drawer size="lg" direction="left" show={showDrawer}>
					<InvoiceDetails invoiceId={invoiceId} dismiss={onDismissDrawer} />
				</Drawer>
				{showDrawer && <Backdrop />}
				<div className="table-head">
					<div className="table-row">
						<Feature name={FEATURE_FLAG.DASHBOARD_INVOICE__PAY_MULTIPLE_INVOICES}>
							{selectable && (
								<div className="eos-table__header" style={{ width: '20px' }}>
									<label>
										<input
											ref={(input) => {
												if (input) {
													input.indeterminate = (!allSelected && someSelectedItemOnCurrentPage()) || false;
												}
											}}
											checked={allSelected}
											type="checkbox"
											onChange={handleSelectAll}
										/>
									</label>
								</div>
							)}
						</Feature>
						<div className="eos-table__header">External Invoice #</div>
						<div className="eos-table__header">Invoice #</div>
						<div className="eos-table__header">Status</div>
						<div className="eos-table__header">Invoice Date</div>
						<div className="eos-table__header">Payment Date</div>
						{userType !== USER_TYPE.PROVIDER && <div className="eos-table__header">Provider</div>}
						<div className="eos-table__header">Total</div>
						{/* <div className="eos-table__header"></div> */}
					</div>
				</div>
				{eosInvoices?.slice(0, 51).map((invoice: any) => (
					<TableRow key={invoice.id} onClick={() => onInvoiceClick(invoice.id)}>
						{selectable && (
							<Feature name={FEATURE_FLAG.DASHBOARD_INVOICE__PAY_MULTIPLE_INVOICES}>
								<div className="table-cell">
									{invoice.status === EOS_STATUS.PENDING && (
										<input
											onChange={(e) => handleCheckInvoice(e, invoice)}
											type="checkbox"
											checked={!!selectedEosInvoices[invoice.id]}
											readOnly
											onClick={(e) => e.stopPropagation()}
											aria-labelledby={`checkInvoice-${invoice.id}`}
										/>
									)}
								</div>
							</Feature>
						)}
						<div data-label="External Invoice Number" className="table-cell">
							{invoice.invoiceNum ? formatPads(invoice?.invoiceNum, 6) : ''}
						</div>
						<div data-label="Invoice Number" className="table-cell">
							{formatPads(invoice.preorderNum, 6)}
						</div>
						<div data-label="Status" className="table-cell">
							<div className={`eos-table__btn eos-table__btn--lg ${invoice.status.toLowerCase()}`}>
								{invoice.status}
							</div>
						</div>
						<div data-label="Creation Date" className="table-cell">
							{isValidDate(invoice.createdDate)
								? formatTimestampToCountryTz(invoice.createdDate, invoice.branch, DASHBOARDS_DATE_FORMAT)
								: null}
						</div>
						<div data-label="Payment Date" className="table-cell">
							{isValidDate(invoice.paymentDate) ? formatUTC(invoice.paymentDate, DASHBOARDS_DATE_FORMAT) : null}
						</div>
						{userType !== USER_TYPE.PROVIDER && <div className="table-cell">{invoice.providerName}</div>}
						<div data-label="Total" className="table-cell">
							{centsToCurrency(invoice.paymentsTotal, invoice.branch)}
						</div>
						{/* <div className="table-cell">
							{getStatusLabel(invoice) === 'Cancelled' && (
								<button className="eos-table__btn declined">
									<i className="fa fa-info-circle"></i> Details
								</button>
							)}
						</div> */}
					</TableRow>
				))}
			</div>
		</>
	);
};

export default InvoiceTable;

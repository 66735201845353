import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import EosClient from '../../../../api/EosClient';
import Button from '../../../../components/DesignSystem/Button';
import { getLastPeriod } from './helper';
import { Timezones } from '../../../../utils/constants';
import { FEATURE_FLAG } from '../../../../utils/FeatureFlags/constants';
import Feature from '../../../../utils/FeatureFlags';
import './styles.scss';
import { Context } from '../../../../context/Context';
import { AppContext } from '../../../../interfaces';

type GenerateInvoicesButtonProps = {
	branch: string;
};
const GenerateInvoicesButton = ({ branch }: GenerateInvoicesButtonProps) => {
	const { user } = useContext(Context) as AppContext;
	const [buttonEnabled, setButtonEnabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const checkIfLastPeriodWasProcessed = async () => {
			setIsLoading(true);
			const { data } = await new EosClient().checkIfLastPeriodWasProcessed(branch);
			if (!data.wasLastPeriodProcessed) {
				setButtonEnabled(true);
			} else {
				setButtonEnabled(false);
			}
			setIsLoading(false);
		};

		checkIfLastPeriodWasProcessed();
	}, [branch]);

	const handleGenerateInvoices = async () => {
		try {
			setIsLoading(true);
			await new EosClient().generateInvoices(branch, user);
			setButtonEnabled(false);
			toast.success('Invoices will be processed and will be ready soon.');
		} catch (error) {
			toast.error(error?.response?.data?.error || 'Something went wrong, please try again');
			console.error('Error generating invoices:', error.message);
		} finally {
			setIsLoading(false);
		}
	};

	const getLastPeriodForBranch = (): string => {
		return getLastPeriod(Timezones[branch as keyof typeof Timezones]);
	};

	const getTooltip = () => {
		if (isLoading) {
			return '';
		}

		if (!buttonEnabled) {
			return 'The Invoices for this period have already been generated.';
		}

		return '';
	};

	return (
		<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_GENERATE_INVOICES}>
			<div className="generate-invoices-button-container">
				<Button
					disabledTooltip={getTooltip()}
					variant="primary"
					disabled={!buttonEnabled}
					isLoading={isLoading}
					onClick={handleGenerateInvoices}
					icon={<i className="fa fa-paper-plane mx-1 mr-2" />}
				>
					<span>Generate Invoices {getLastPeriodForBranch()} </span>
				</Button>
			</div>
		</Feature>
	);
};

export default GenerateInvoicesButton;

import React, { FunctionComponent } from 'react';
import './RejectInvoiceModal.scss';

interface RejectInvoiceModalProps {
	onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
	onTextareaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onCancelButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
	rejectReason: string;
	isSubmitting: boolean;
}

const RejectInvoiceModal: FunctionComponent<RejectInvoiceModalProps> = ({
	onSubmit,
	onTextareaChange,
	onCancelButtonClick,
	rejectReason,
	isSubmitting,
}): JSX.Element => (
	<form onSubmit={onSubmit} className="reject-invoice-modal__form">
		<div className="reject-invoice-modal__reason-textarea">
			<label htmlFor="reasonTextarea" className="reject-invoice-modal__label">
				Why do you want to reject this invoice?
			</label>
			<textarea
				className="reject-invoice-modal__textarea form-control"
				value={rejectReason}
				onChange={onTextareaChange}
				disabled={isSubmitting}
				required
				id="reasonTextarea"
				maxLength={250}
				rows={4}
			/>
		</div>
		<div className="reject-invoice-modal__btns">
			{!isSubmitting && (
				<button
					type="button"
					className="reject-invoice-modal__btn reject-invoice-modal__cancel-btn"
					onClick={onCancelButtonClick}
				>
					Cancel
				</button>
			)}
			<button
				type="submit"
				disabled={isSubmitting}
				className="reject-invoice-modal__btn reject-invoice-modal__reject-invoice-btn"
			>
				{isSubmitting ? <div className="btn-loader" /> : 'Reject Invoice'}
			</button>
		</div>
	</form>
);

export default RejectInvoiceModal;

import React, { FunctionComponent } from 'react';

import ProgressSpinner from './ProgressSpinner';

import './ConfirmModal.scss';

interface ConfirmModalProps {
	onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
	onCancelButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
	isSubmitting: boolean;
	modalText: string | JSX.Element;
	denyText: string;
	confirmText: string;
}

const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({
	onSubmit,
	onCancelButtonClick,
	isSubmitting,
	modalText,
	denyText,
	confirmText,
}): JSX.Element => (
	<div className="confirm-modal">
		<p className="confirm-modal__text">{modalText}</p>
		<div className="confirm-modal__btns">
			<button type="button" className="confirm-modal__btn confirm-modal__cancel-btn" onClick={onCancelButtonClick}>
				{denyText}
			</button>
			<button
				type="submit"
				disabled={isSubmitting}
				className="confirm-modal__btn confirm-modal__confirm-btn"
				onClick={onSubmit}
			>
				{confirmText}
				{isSubmitting ? <ProgressSpinner /> : null}
			</button>
		</div>
	</div>
);

export default ConfirmModal;

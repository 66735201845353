import { TimestampOption } from './types';
import { Service } from '../../../interfaces';
import { isTow } from '../../../utils/constants';

const TimestampOptions = [
	{
		key: 'new',
		label: 'New',
	},
	{
		key: 'accepted',
		label: 'Accepted',
	},
	{
		key: 'on_route',
		label: 'On Route',
	},
	{
		key: 'arrived',
		label: 'Arrived',
	},
	{
		key: 'towed',
		label: 'Towed',
	},
];

export const setUpTimestamps = (service: Service): TimestampOption[] => {
	if (service.trip?.stamps?.length === 0) return []; // Some services from Hold to Active doesn't have an initial stamp status
	const tripStamps = service.trip.stamps;
	const lastStamp = tripStamps[tripStamps.length - 1];
	const isTowService = isTow(service.situation);
	const timestampOptions = TimestampOptions.filter((option) => isTowService || option.key !== 'towed');
	const lastStampIndex = timestampOptions.findIndex((option) => option.key === lastStamp.status);
	return timestampOptions.slice(lastStampIndex + 1);
};

import React, { FunctionComponent, useEffect, useState } from 'react';

import { PreOrderService } from '../../../../interfaces';
import { BRANCH } from '../../../../utils/constants';
import TableDetail from './TableDetail';
import { getIVALabel, getRetentionsByBranch, retentionLabels } from '../../../../utils/Utils';
import Feature from '../../../../utils/FeatureFlags';
import { FEATURE_FLAG } from '../../../../utils/FeatureFlags/constants';

interface TableProps {
	branch: BRANCH;
	services: Array<PreOrderService>;
}

const Table: FunctionComponent<TableProps> = ({ branch, services }): JSX.Element => {
	const [showIVAColumn, setShowIVAColumn] = useState(false);

	useEffect(() => {
		setShowIVAColumn(branch === BRANCH.CRC || branch === BRANCH.MEX || branch === BRANCH.PAN);
	}, [branch]);

	return (
		<div className="table">
			<div className="table__header">
				<h6>Service #</h6>
				<h6>Account</h6>
				<h6>Situation</h6>
				<h6>Provider Distance</h6>
				<h6>Situation Distance</h6>
				<h6>Subtotal</h6>
				<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_BREAKDOWN}>
					{getRetentionsByBranch(branch).map((retention) => (
						<h6 key={retention}>{retentionLabels[retention]}</h6>
					))}
				</Feature>
				<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_TOTAL}>
					<h6>Retention</h6>
				</Feature>
				{showIVAColumn ? <h6>{getIVALabel(branch)}</h6> : null}
				<h6>Total</h6>
			</div>
			<div className="table__body">
				{Object.values(services).map((service: PreOrderService, index) => (
					<TableDetail key={index} service={service} branch={branch} showIVAColumn={showIVAColumn} />
				))}
			</div>
		</div>
	);
};

export default Table;

import { setSituationTypes } from '../context/actions';
import HeliosClient from '../api/HeliosClient';
import { DispatchFunction } from '../interfaces';
import { BRANCH } from './constants';
import filter from 'lodash/filter';

export const fetchSituationTypes = async (dispatch: DispatchFunction, branch: BRANCH): Promise<void> => {
	const {
		data: { data },
	} = await new HeliosClient().getTypes();
	const result = filter(data, (item) => item.branches?.includes(branch));

	setSituationTypes(dispatch, result.sort((a: any, b: any) => (a.label > b.label ? 1 : -1)) || []);
};

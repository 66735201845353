import dayjs from 'dayjs';
import { Service } from '../../interfaces';
import { formatServiceLabel } from '../Service/Service.util';
import { searchType, DASHBOARD_SECTION } from '../../utils/constants';

export const setUpInfo = (services: Service[], setServices: (value: Service[]) => void, status: string): void => {
	let servicesArray: any[] = [];
	for (const service of services) {
		if (!service.trip) {
			service.trip = {};
		}
		service.trip.statusLabel = service.trip ? formatTimestampLabel(service.trip.status) : '';
		service.situationLabel = formatServiceLabel(service.situation);
		service.createDate = dayjs(service.date).format('DD/MM/YYYY, hh:mm:ss A');
		service.scheduleDate = service.schedule ? dayjs(service.schedule).format('DD/MM/YYYY, hh:mm:ss A') : 'No due date';
		service.arrivedTime = '00:00';
		service.towedTime = '00:00';
		service.elap = calculateElapsedTime(service, status);

		if (service.serviceSource === 'eos-client-dispatch') {
			service.serviceSource = 'EOS Client Dispatch';
		}
		if (service.trip && service.trip.live) {
			const date = new Date(+service.trip.live.eta * 1000);
			service.trip.live.eta = date.toISOString().substr(11, 5);
		} else {
			service.trip.live = {
				eta: '00:00',
			};
		}
		if (!service.trip || !service.trip.stamps) {
			service.arrivedTime = '';
		} else {
			for (const stamp of service.trip.stamps) {
				if (stamp.status === 'arrived') {
					service.arrivedTime = new Date(Number(stamp.time)).toLocaleString().split(' ').splice(1, 2).join(' ');
				}
				if (stamp.status === 'towed') {
					service.towedTime = new Date(Number(stamp.time)).toLocaleString().split(' ').splice(1, 2).join(' ');
				}
			}
		}
		service.arrivalDuration = getArrivalDuration(service);
		servicesArray = [...servicesArray, service];
	}
	setServices(servicesArray);
};

export const getServicesElapsedTime = (
	services: Service[],
	setServices: (value: Service[]) => void,
	status: string
): void => {
	for (const service of services) {
		service.elap = calculateElapsedTime(service, status);
	}
	setServices([...services]);
};

const calculateElapsedTime = (service: any, status: string) => {
	if ([DASHBOARD_SECTION.CANCELLED, DASHBOARD_SECTION.FINISHED].includes(status)) return '00:00';

	let finishedTime = null;
	if (service?.trip?.stamps) {
		for (const stamp of service.trip.stamps) {
			if (stamp.status === 'finished') finishedTime = stamp.time;
		}
	}
	let delta = Math.abs((finishedTime || Date.now()) - service.created) / 1000;
	// calculate (and subtract) whole days
	const days = Math.floor(delta / 86400);
	delta -= days * 86400;
	// calculate (and subtract) whole hours
	const hours = Math.floor(delta / 3600) % 24;
	delta -= hours * 3600;
	// calculate (and subtract) whole minutes
	const minutes = Math.floor(delta / 60) % 60;
	delta -= minutes * 60;
	// what's left is seconds
	const seconds = Math.floor(delta % 60);
	return `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};

export const getArrivalDuration = (service: Service): string => {
	if (service.status === 'Cancelled' || !service.trip || !service.trip.stamps) {
		return '00:00';
	}

	const dispatchedTime = service.created;
	let arrivedTime = null;
	for (const stamp of service.trip.stamps) {
		if (stamp.status === 'arrived') {
			arrivedTime = stamp.time;
		}
	}
	return formatTime(dispatchedTime as number, arrivedTime);
};

export const formatTime = (startTime: number, endTime: number, realtime = null): string => {
	if (!startTime) {
		return '00:00';
	}
	let delta;
	if (realtime) {
		delta = Math.abs((endTime || Date.now()) - startTime) / 1000;
	} else if (endTime) {
		delta = Math.abs(endTime - startTime) / 1000;
	} else {
		return '00:00';
	}
	// calculate (and subtract) whole days
	const days = Math.floor(delta / 86400);
	delta -= days * 86400;

	// calculate (and subtract) whole hours
	const hours = Math.floor(delta / 3600) % 24;
	delta -= hours * 3600;

	// calculate (and subtract) whole minutes
	const minutes = Math.floor(delta / 60) % 60;
	delta -= minutes * 60;

	// what's left is seconds
	const seconds = Math.floor(delta % 60);

	return `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};

export const formatTimestampLabel = (timestamp: string): string => {
	switch (timestamp) {
		case 'new':
			return 'New';
		case 'accepted':
			return 'Accepted';
		case 'on_route':
			return 'On Route';
		case 'arrived':
			return 'Arrived';
		case 'towed':
			return 'Towed';
		case 'finished':
			return 'Finished';
		case 'cancelled':
			return 'Declined';
		case 'cancelled_by_driver':
			return 'Cancelled By Driver';
		default:
			return '';
	}
};

export const parseSearchTerm = (term: string): string => {
	const lowerTerm = term?.toLowerCase();
	switch (lowerTerm) {
		case 'lockout':
		case 'lockout service':
			return 'locksmith';
		case 'flat':
		case 'flat tire':
			return 'flatTire';
		case 'fuel':
		case 'fuel delivery':
			return 'fuelDelivery';
		case 'jump':
		case 'jump start':
			return 'jumpStart';
		case 'tow-breakdown':
		case 'tow breakdown':
		case 'towbreakdown':
			return 'towBreakdown';
		case 'tow-collision':
		case 'tow collision':
		case 'towcollision':
			return 'towCollision';
		case 'tow-tire':
		case 'tow tire':
		case 'towtire':
			return 'towTire';
		case 'tow-extraction':
		case 'tow extraction':
		case 'towextraction':
			return 'winching';
		case 'inspection':
			return 'inspections';
		default:
			return term;
	}
};

export const parseSearchType = (filter: string, type: string = searchType.HELIOS): string | undefined => {
	if (type === searchType.HELIOS) {
		switch (filter) {
			case 'plate':
				return 'plate';
			case 'phone':
				return 'phone1';
			case 'driver':
				return 'driver.name';
			case 'name':
				return 'name';
			case 'po#':
				return 'serviceNumber';
			case 'vin#':
				return 'vin';
			case 'id card':
				return 'idCard';
			case 'external id':
				return 'externalId';
			case 'policy id':
				return 'policyNumber';
			default:
				return filter;
		}
	} else if (type === searchType.EOS) {
		switch (filter) {
			case 'plate':
				return 'plate';
			case 'phone':
				return 'phone';
			case 'driver':
				return 'driverName';
			case 'name':
				return 'customerName';
			case 'po#':
				return 'serviceNumber';
			case 'vin':
				return 'vin';
			case 'external-invoice##':
				return 'invoiceNum';
			case 'invoice#':
				return 'preorderNum';
			default:
				return undefined;
		}
	} else {
		return undefined;
	}
};

export const SEARCHES = {
	DEFAULT_SEARCHES: ['Plate', 'Phone', 'PO#', 'VIN', 'Policy ID', 'ID Card', 'External ID'],
	BILLING_SEARCHES: ['Plate', 'Phone', 'PO#', 'VIN', 'Policy ID'],
	INVOICE_SEARCHES: ['Invoice#', 'External-Invoice#', 'PO#'],
};

// External imports
import Axios, { AxiosResponse, AxiosStatic } from 'axios';
// Global imports
import { COMMON_API } from 'utils/constants';
import { parseObjToQuery } from 'utils/Utils';
// Local imports
import HttpClient from './HttpClient';
import { GetVehicleMakeResponse, GetVehicleModelResponse, GetVehicleParams } from './interfaces';

export default class CommonAPIClient extends HttpClient {
	constructor(axios: AxiosStatic = Axios) {
		super(axios, COMMON_API);
	}

	async getVehicleMakes(params: GetVehicleParams): Promise<AxiosResponse<GetVehicleMakeResponse>> {
		return await this.get(`/api/vehicles/makes${parseObjToQuery(params)}`);
	}

	async getVehicleModels(params: GetVehicleParams, make: string): Promise<AxiosResponse<GetVehicleModelResponse>> {
		return await this.get(`/api/vehicles/makes/${make}/models${parseObjToQuery(params)}`);
	}
}

import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

interface IProps {
	to: string;
	icon: string;
	label: string;
}

interface IStyledProps {
	$currentPath: string;
}

const StyledLink = styled(Link)<IStyledProps>`
	height: 35px;
	width: 35px;
	border-radius: 100%;
	background: ${({ to, $currentPath, theme }) =>
		to === $currentPath ? theme.colors.naranjaConnect : '#59595c'} !important;
	padding: 8px;
	display: flex;
	align-items: center;
	justify-content: center;

	& > img {
		height: 100%;
	}
`;

const NavLink = ({ to, icon, label }: IProps): React.ReactElement => {
	const { pathname } = useLocation();
	return (
		<StyledLink to={to} $currentPath={pathname}>
			<img src={icon} aria-label={label} />
		</StyledLink>
	);
};

export default NavLink;

import styled from 'styled-components';
// @ts-expect-error: @carbon/react doesn't have full typescript support yet
import { ProgressIndicator as BaseProgressIndicator } from '@carbon/react';

interface IStyledStepContainer {
	$activeStep: boolean;
}

const CreateServiceContainer = styled.div`
	min-height: 100vh;
	background: ${({ theme }) => theme.colors.white};
`;

const MainContentContainer = styled.div`
	padding: 128px 140px 152px 140px;
	@media only screen and (max-width: 1056px) {
		padding: 25px 30px 35px 30px;
	}
`;

const MainTitle = styled.h1`
	margin-bottom: 48px;
	color: ${({ theme }) => theme.colors.naranja02};
	${({ theme }) => theme.typography.h2}

	@media only screen and (max-width: 1056px) {
		font-size: 40px;
	}
`;

const NavigationContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 86px;
	justify-content: space-between;

	@media only screen and (max-width: 800px) {
		margin-bottom: 20px;
	}
`;

const ProgressIndicator = styled(BaseProgressIndicator)`
	flex-grow: 1;
	max-width: 670px;

	@media only screen and (max-width: 800px) {
		width: 100%;
		margin-bottom: 30px;
	}
`;

const StepContainer = styled.div<IStyledStepContainer>`
	display: ${({ $activeStep }) => ($activeStep ? 'block' : 'none')};
`;

const S = {
	CreateServiceContainer,
	MainContentContainer,
	MainTitle,
	NavigationContainer,
	ProgressIndicator,
	StepContainer,
};

export default S;

import { BRANCH, BRANCH_CODES, WARNING_TYPES } from '../../utils/constants';
import HeliosClient from '../../api/HeliosClient';
import { EosService, Driver, Service, ServiceTypeCategoryToIcon, Situations, SituationsToIcon } from '../../interfaces';
import OtherServices from 'assets/imgs/service-detail-icons/other.svg';

export const getServiceIcon = (service?: Service | EosService): string => {
	const assets = require.context('assets/imgs/service-detail-icons', true);

	if (service?.category) {
		const iconName = getServiceTypeIconName(service, assets);
		return iconName;
	} else if (SituationsToIcon.includes(String(service?.situation))) {
		let situationIcon = '';
		try {
			situationIcon = assets(`./${service?.situation}.svg`);
			return situationIcon;
		} catch (error) {
			return OtherServices;
		}
	}

	return OtherServices;
};

/**
 * Returns the icon name given a service type.
 * @returns the service type icon name.
 */
export const getServiceTypeIconName = (
	service: Service | EosService,
	assets: __WebpackModuleApi.RequireContext
): string => {
	if (
		service.virtualInspector &&
		service.virtualInspector === 'Yes' &&
		service.viClientAccepted &&
		service.viClientAccepted === 'Yes'
	) {
		return Situations.VIRTUAL_INSPECTIONS;
	} else {
		const iconName = ServiceTypeCategoryToIcon.get(String(service.category));
		return (iconName ? assets(`./${iconName}.svg`) : OtherServices) ?? OtherServices;
	}
};

export const evaluateInspectionLabel = (service: Service): Situations => {
	if (
		service.situation === Situations.INSPECTIONS &&
		service.virtualInspector === 'Yes' &&
		service.viClientAccepted === 'Yes'
	) {
		return Situations.VIRTUAL_INSPECTIONS;
	} else {
		return service.situation;
	}
};

export const distanceFormat = (branch: string, meters: number | undefined, justFormat = false): string => {
	const isPR = branch === BRANCH.PRI || branch === BRANCH_CODES['Puerto Rico'];
	if (!meters) return isPR ? '0 mi' : '0 km';
	if (justFormat) return isPR ? `${meters} mi` : `${meters} km`;
	return isPR ? `${Math.round(meters / 1609.344)} mi` : `${Math.round(meters / 1000)} km`;
};

export const timestampLabels: { [timestampLabel: string]: string } = {
	new: 'New',
	accepted: 'Accepted',
	on_route: 'On Route',
	arrived: 'Arrived',
	towed: 'Towed',
	finished: 'Finished',
	cancelled: 'Declined',
};

export const formatTimestampLabel = (timestamp: string): string => {
	return timestampLabels[timestamp] ?? '';
};

export const isServiceVial = (situation: Situations): boolean => {
	switch (situation) {
		case 'locksmith':
		case 'flatTire':
		case 'fuelDelivery':
		case 'jumpStart':
		case 'batteryDiagnostic':
		case 'towExtraction':
		case 'inspections':
			return true;
		case 'towCollision':
		case 'towBreakdown':
		case 'towTire':
		case 'grua_ebriedad_local':
			return false;
		default:
			return true;
	}
};

export const isPetSituation = (situation: Situations, account: string): boolean => {
	return ['pet_grooming'].includes(situation) || ['SURAMERICANA'].includes(account);
};

export const serviceLabels: { [serviceLabel: string]: string } = {
	accompaniment_at_home_due_to_disability: 'Accompaniment At Home Due To Disability',
	accompaniment_to_clinical_tests: 'Accompaniment To Clinical Tests',
	advanced_ambulance: 'Advanced Ambulance',
	agenda_management: 'Agenda Management',
	ambulance: 'Ambulance',
	asistencia_dental_en_exterior: 'Asistencia dental en exterior',
	asistencia_medica_y_de_hospitalizacion_en_el_extranjero: 'Asistencia medica y de hospitalización en el extranjero',
	attentionHomeCare: 'Attention-Home Care',
	baggage: 'Baggage',
	basic_brake_service: 'Basic Brake Service',
	basic_mechanics: 'Basics Mechanics',
	basic_motorcycle_brake_service_and_liquid_brakes: 'Basic Motorcycle Brake Service + Liquid Brakes',
	basic_motorcycle_washing_and_disinfection_home: 'Basic Motorcycle Washing And Disinfection Home',
	basic_motorcycle_washing_and_disinfection_on_site: 'Basic Motorcycle Washing And Disinfection On Site',
	basic_motorcycle_washing_home: 'Basic Motorcycle Washing Home',
	basic_motorcycle_washing_on_site: 'Basic Motorcycle Washing On Site',
	basic_tech_support: 'Basic Tech Support',
	basic_truck_brake_service_and_liquid_brakes: 'Basic Truck Brake Service + Liquid Brakes',
	basic_truck_washing_and_disinfection_home: 'Basic Truck Washing And Disinfection Home',
	basic_truck_washing_and_disinfection_on_site: 'Basic Truck Washing And Disinfection On Site',
	basic_truck_washing_home: 'Basic Truck Washing Home',
	basic_truck_washing_on_site: 'Basic Truck Washing On Site',
	basic_vehicle_brake_service_and_liquid_brakes: 'Basic Vehicle Brake Service + Liquid Brakes',
	basic_vehicle_review_and_diagnostic: 'Basic Vehicle Review and Diagnostic',
	basic_vehicle_washing_and_disinfection_home: 'Basic Vehicle Washing And Disinfection Home',
	basic_vehicle_washing_and_disinfection_on_site: 'Basic Vehicle Washing And Disinfection On Site',
	basic_vehicle_washing_home: 'Basic Vehicle Washing Home',
	basic_vehicle_washing_on_site: 'Basic Vehicle Washing On Site',
	battery_sale: 'Battery Sale',
	batteryDiagnostic: 'Diagnostic & Battery',
	canine_walker: 'Canine walker',
	car_rental: 'Car Rental',
	car_workshop: 'Car Workshop',
	chip_installation: 'Chip installation',
	compensation_for_baggage: 'Compensation For Baggage',
	conductor_designado: 'Conductor designado',
	coordinacion_de_citas_medicas: 'Coordinación de citas medicas',
	coordinación_equipaje_extraviado: 'Coordinación Equipaje extraviado',
	coordinacion_perdida_de_documentacion_en_viajes: 'Coordinación perdida de documentación en viajes',
	coordinacion_y_reparacion_de_fugas_de_gas: 'Coordinación y reparación de fugas de gas',
	courier_for_disability: 'Courier For Disability',
	courier: 'Courier',
	covid_legal: 'COVID Legal',
	cremation: 'Cremation',
	custodia_del_vehiculo_asegurado: 'Custodia del vehículo asegurado',
	defensa_legal: 'Defensa legal (Accidente vehiculo)',
	delivery: 'Delivery',
	dental_cleaning: 'Dental Cleaning',
	designated_driver: 'Designated Driver',
	diagnostico_dental: 'Diagnostico Dental',
	discount_service: 'Discount Service',
	doctor: 'Doctor',
	domestic_service_due_to_hospitalization: 'Domestic Service Due To Hospitalization',
	domestic_service: 'Domestic Service',
	educational_virtual_orientation: 'Educational Virtual Orientation',
	electricity: 'Electricity',
	emergencias_dentales: 'Emergencias dentales',
	emergency_veterinary_consultation_at_home: 'Emergency veterinary consultation at home',
	emergency_veterinary_consultation_in_clinic: 'Emergency veterinary consultation in clinic',
	emotional_support: 'Emotional Support',
	envio_de_medicamentos_a_domicilio: 'Envio de medicamentos a domicilio',
	euthanasia: 'Euthanasia',
	examen_de_bienvenida_mamografias_papanicolaou_hemograma:
		'Examen de Bienvenida (Mamografias, Papanicolaou, Y Hemograma)',
	flatTire: 'Flat Tire',
	flea_pipette: 'Flea pipette',
	fono_chef: 'Fono Chef',
	fono_taller: 'Fono Taller',
	fuelDelivery: 'Fuel Delivery',
	furniture_transportation: 'Furniture Transportation',
	garage_to_garage: 'Garage to Garage',
	gas_escape: 'Gas Escape',
	glass_replacement: 'Glass Replacement',
	glassworks: 'Glassworks',
	good_garden: 'Good Garden',
	handyman: 'Handyman',
	hexavalent_vaccination: 'Hexavalent vaccination',
	home_beauty_service: 'Home Beauty Service',
	home_decor: 'Home Decor',
	home_disinfection: 'Home Disinfection',
	home_locksmith: 'Home Locksmith',
	home_nurse: 'Home Nurse',
	home_security_system: 'Home Security System',
	home_teacher: 'Home Teacher',
	hotel_por_averia_o_accidente: 'Hotel por avería o accidente',
	hotel_por_convalecencia_medica_o_por_perdida_de_vuelo: 'Hotel por convalecencia medica o por perdida de vuelo',
	hotel_por_eventos_naturales_o_catastroficos: 'Hotel por eventos naturales o catastroficos',
	hotel_por_inhabitabilidad_del_hogar: 'Hotel por in habitabilidad del hogar',
	hotel_por_robo_o_hurto_del_vehiculo: 'Hotel por robo o hurto del vehículo',
	hotel_stay_due_to_inhabitable_home: 'Hotel Stay Due To Inhabitable Home',
	hotel: 'Hotel',
	impound_lot: 'Impound Lot',
	informacion_previa_a_viajes: 'Información previa a viajes',
	information: 'Information',
	informative_call: 'Informative Call',
	injury_or_sickness: 'Injury or Sickness',
	inspections: 'Inspection',
	interstate: 'Interstate',
	jumpStart: 'Jump Start',
	kindergarten: 'Kindergarten',
	legal: 'Legal',
	location_and_shipment_of_spare_parts: 'Location and Shipment of Spare Parts',
	locksmith: 'Lockout Service',
	lost_pet: 'Lost pet',
	luggage: 'Luggage',
	medic_tel: 'MedicTel',
	medical_assistance_abroad: 'Medical Assistance Abroad',
	medical_care_services: 'Medical Care Services',
	motorcycle_alignment_and_balancing: 'Motorcycle alignment and balancing',
	nutritional_orientation_plan: 'Nutritional Orientation Plan',
	onsite_legal_assistance: 'On site legal assistance',
	orientacion_culinaria: 'Orientación culinaria',
	orientacion_legal_telefonica: 'Orientación legal telefónica',
	orientacion_medica_telefonica: 'Orientación medica telefónica',
	orientacion_y_asesoria_telefonica_pc: 'Orientación y asesoría telefónica PC',
	orientation_it_support: 'Orientation - IT Support',
	pediatric_orientation: 'Pediatric Orientation',
	pet_grooming: 'Pet grooming',
	pet_transfer: 'Pet Transfer',
	pet_vaccination_report: 'Pet vaccination report',
	pharmacy_delivery: 'Pharmacy Delivery',
	plumbing: 'Plumbing',
	psychological_attention: 'Psychological Attention',
	psychological_orientation_death_of_pet: 'Psychological orientation death of pet',
	rabies_vaccination: 'Rabies vaccination',
	reference: 'Reference',
	referencia_y_coordinacion_servicios_varios:
		'Referencia y coordinación servicios varios (Restaurantes, floristerías, centros culturales, legal, recurso laboral, mecánicos y lineas aéreas)',
	referencias_mecanicas_talleres: 'Referencias Mecánicas/Talleres',
	referencias_medicas_clinicas: 'Referencias Medicas / Clínicas',
	referencias_y_coordinacion_con_profesionales: 'Referencias y coordinación con profesionales (Hogar)',
	remote_assistance_it_support: 'Remote Assistance - IT Support',
	remote_tech_assistance: 'Remote Tech Assistance',
	renta_de_vehiculo_por_averia_o_accidente: 'Renta de vehículo por avería o accidente',
	renta_de_vehiculo_por_robo_total_del_vehiculo: 'Renta de vehículo por robo total del vehículo',
	reparacion_menor_in_situ: 'Reparación menor ¨in situ¨',
	repatriation: 'Repatriation',
	roadways_report: 'Roadways Report',
	rx: 'Rx',
	school_courier: 'School Courier',
	school_transport: 'School Transport',
	security: 'Security',
	sending_list_school_supplies: 'Sending List School Supplies',
	servicio_domestico_por_hospitalizacion_prolongada:
		'Servicio domestico por hospitalización prolongada (más de 7 días)',
	sickness_expenses: 'Sickness expenses',
	special_transport_patients: 'Special Transport Patients',
	sterilization_of_pets: 'Sterilization of pets',
	supplies_delivery: 'Supplies Delivery',
	taxi_due_to_collision: 'Taxi Due To Collision',
	taxi_to_airport: 'Taxi To Airport',
	taxi: 'Taxi',
	telephone_information: 'Telephone Information',
	telephone_nutritional_orientation: 'Telephone nutritional orientation',
	towBreakdown: 'Tow-Breakdown',
	towCollision: 'Tow-Collision',
	towCollission: 'Tow-Collision',
	towExtraction: 'Winching',
	towHeavyWeight: 'Tow Heavy Weight',
	towTire: 'Tow-Tire',
	transporte_de_taxi_por_robo_o_hurto: 'Transporte de taxi por robo o hurto',
	transporte_en_ambulancia_o_taxi_cita_programada: 'Transporte en ambulancia o taxi cita programada',
	transporte_en_ambulancia_por_accidente: 'Transporte en ambulancia por accidente',
	trip_continuation: 'Trip Continuation',
	truck_alignment_and_balancing: 'Truck alignment and balancing',
	truck_wash_and_alignment_and_balancing_and_brake_service: 'Truck Wash + Alignment And Balancing + Brake Service',
	various_pets: 'Various pets',
	vehicle_alignment_and_balancing: 'Vehicle Alignment And Balancing',
	vehicle_disinfection: 'Vehicle Disinfection',
	vehicle_fire_extinguisher_review: 'Vehicle Fire Extinguisher Review',
	vehicle_maintenance_at_home: 'Vehicle Maintenance at Home',
	vehicle_parts_delivery: 'Vehicle Parts Delivery',
	vehicle_wash_and_alignment_and_balancing_and_brake_service: 'Vehicle Wash + Alignment And Balancing + Brake Service',
	virtual_mechanic_orientation: 'Virtual Mechanic Orientation',
	virtual_motorcycle_agenda: 'Virtual Motorcycle Agenda',
	virtual_vaccination_orientation: 'Virtual Vaccination Orientation',
	virtual_veterinary_consultation: 'Virtual Veterinary Consultation',
	visita_medica_a_domicilio: 'Visita medica a domicilio',
	visita_pc_a_domicilio: 'Visita PC a Domicilio',
	web_support_for_homework: 'Web Support For Homework',
	wheelLift: 'Wheel Lift',
	vehicle_alignment_and_balancing_basic_brake_service: 'Vehicle alignment and balancing + basic brake service',
	motorcycle_alignment_and_balancing_basic_brake_service: 'Motorcycle alignment and balancing + basic brake service',
	truck_alignment_and_balancing_basic_brake_service: 'Truck alignment and balancing + basic brake service',
};

export const formatServiceLabel = (situation: string): string => {
	return serviceLabels[situation] ?? 'No Label';
};

export const branchCodes: { [branch: string]: string } = {
	'Puerto Rico': 'PRI',
	Panama: 'PTY',
	'Costa Rica': 'CRC',
	Colombia: 'COL',
	Mexico: 'MEX',
};

export const getBranchCode = (branch: string): string => {
	return branchCodes[branch] ?? 'PRI';
};

export const fetchService = async (
	id: string,
	setMapVisible: (value: boolean) => void,
	setService: (service: any) => void,
	setErrorMessage: (message: string) => void,
	setLoading: (value: boolean) => void
) => {
	try {
		const {
			data: { data, status },
		} = await new HeliosClient().getServiceById(id);

		if (data && status) {
			if (data.status === 'HoldInspection') {
				setMapVisible(false);
			}
			setService(data);
			await new HeliosClient().markAsRead(data._id, 'Eos');
			setLoading(false);
		} else {
			setErrorMessage('No service found');
			setLoading(false);
		}
	} catch (error) {
		setErrorMessage('Error fetching service');
		setLoading(false);
	}
};

export const fetchDriversByProviderId = async ({
	providerId,
	setDriversByProvider,
}: {
	providerId?: string;
	setDriversByProvider: (drivers: Driver[]) => void;
}): Promise<void> => {
	try {
		if (!providerId) return;
		const {
			data: { data, status },
		} = await new HeliosClient().getDriversByProviderId(providerId);

		if (!data || !status) {
			throw new Error('Failed to fetch drivers');
		}

		setDriversByProvider(data);
	} catch (error) {
		console.error(error);
		throw new Error('Something went wrong fetching drivers, please try again');
	}
};

export const canConfirmThisService = (serviceWarnings?: string[]): boolean => {
	if (!serviceWarnings) return false;
	const confirmed = serviceWarnings.indexOf(WARNING_TYPES.NOT_CONFIRMED) < 0;
	return !confirmed;
};

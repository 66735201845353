import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import localforage from 'localforage';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import Navbar from '../../components/Navbar';
import ServicesTable from '../../components/ServicesTable';
import DashboardSearch from '../../components/Dashboard/DashboardSearch';
import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import DashboardFilter from '../../components/Dashboard/DashboardFilter';
import DashboardMultipleFilters from '../../components/Dashboard/DashboardMultipleFilters';
import Pagination from '../../components/Pagination';
import Drawer from '../../components/Commons/Drawer';
import BillingPreOrder from '../../components/Billing/BillingPreOrder';
import Modal from '../../components/Commons/Modal';
import RejectServiceModal from './RejectServiceModal';
import { setCurrentPage, setSelectedEosInvoices } from '../../context/actions';
import GenerateInvoicesButton from './components/GenerateInvoicesButton';
import HeliosClient from '../../api/HeliosClient';
import EosClient from '../../api/EosClient';
import { Context } from '../../context/Context';

import { parseSearchTerm, parseSearchType, setUpInfo, getServicesElapsedTime, SEARCHES } from './Dashboard.utils';
import {
	AppContext,
	Service,
	EosService,
	DownloadButtonProps,
	reportType,
	Invoice,
	Provider,
	DropDownOption,
} from '../../interfaces';

import {
	DASHBOARD_SECTION,
	EOS_STATUS,
	EOS_STATUSES,
	EOS_OTHER_STATUSES,
	USER_TYPE,
	ROLE,
	searchType as typeMaping,
	DRAWER_DIRECTION_DEFAULT_STATE,
	BRANCH,
} from '../../utils/constants';

import {
	setSearchTerm,
	setSearchType,
	setSelectedSocialReason,
	setSelectedProviders,
	setSelectedSituations,
	setShowPreOrderDrawer,
	setSelectedEosServices,
	setSelectedEosStatus,
	setSelectedEosOtherStatus,
	setSelectedDivisions,
	setSelectedPolicies,
} from '../../context/actions';

import './styles.scss';
import Feature from '../../utils/FeatureFlags';
import { FEATURE_FLAG } from '../../utils/FeatureFlags/constants';
import { getEndOfDayForCountry, getStartOfDayForCountry, isValidDate } from 'utils/datetime.utils';

const DIVISIONS = [
	{ name: 'home', label: 'Home' },
	{ name: 'road', label: 'Auto' },
	{ name: 'concierge', label: 'Concierge' },
	{ name: 'claims', label: 'Claims' },
];

const DashboardScreen: FunctionComponent = (): JSX.Element => {
	const {
		status,
		selectedSituations,
		selectedDivisions,
		selectedProviders,
		selectedSocialReason,
		searchType,
		searchTerm,
		currentPage,
		userType,
		user,
		provider,
		showPreOrderDrawer,
		selectedEosStatus,
		selectedEosOtherStatus,
		situationTypes,
		selectedPolicies,
		dispatch,
	} = useContext(Context) as AppContext;
	const [adminRole, setAdminRole] = useState<string>();
	const [services, setServices] = useState<Service[]>([]);
	const [eosServices, setEosServices] = useState<EosService[]>([]);
	const [eosInvoices, setEosInvoices] = useState<Invoice[]>([]);
	const [totalServices, setTotalServices] = useState(0);
	const [loading, setLoading] = useState(true);
	const [paging, setPaging] = useState(false);
	const [changingView, setChangingView] = useState(false);
	const [downloadingFile, setDownloadingFile] = useState(false);
	const [providers, setProviders] = useState([]);
	const [policyPin, setPolicyPin] = useState<DropDownOption[]>([]);
	const [socialReasons, setSocialReasons] = useState<Provider[]>([]);
	const [branch, setBranch] = useState('');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
	const [showConfirmRejectServiceModal, setShowConfirmRejectServiceModal] = useState(false);
	const [drawerDirection, setDrawerDirection] = useState(DRAWER_DIRECTION_DEFAULT_STATE);
	const [searches, setSearches] = useState(SEARCHES.DEFAULT_SEARCHES);
	const history = useHistory();

	const [showRejectServiceButtons, setShowRejectServiceButtons] = useState(false);
	const [rejectedService, setRejectedService] = useState<EosService>();
	const [rejectReason, setRejectReason] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);

	const pageLimit = 51;
	let oldServices: any = [];

	useEffect(() => {
		setChangingView(true);
		getServices(); // for inmediate call when view first fires
		const timer = setInterval(() => {
			getServices(); // every 10 seconds
		}, 10000); // 10 seconds
		return () => clearInterval(timer);
	}, [
		status,
		currentPage,
		selectedSituations,
		selectedProviders,
		selectedSocialReason,
		selectedEosStatus,
		selectedEosOtherStatus,
		searchTerm,
		startDate,
		endDate,
		situationTypes,
		selectedDivisions,
		selectedPolicies,
	]);

	useEffect(() => {
		getProviders();
	}, []);

	useEffect(() => {
		let interval: any;
		if (status !== DASHBOARD_SECTION.BILLING && status !== DASHBOARD_SECTION.INVOICE) {
			interval = setInterval(() => getServicesElapsedTime(services, setServices, status), 1000);
		}
		return (): void => clearInterval(interval);
	});

	useEffect(() => {
		if (status === DASHBOARD_SECTION.INVOICE) {
			setSearches(SEARCHES.INVOICE_SEARCHES);
			setSearchType(dispatch, SEARCHES.INVOICE_SEARCHES[0]);
		} else if (status === DASHBOARD_SECTION.BILLING) {
			setSearches(SEARCHES.BILLING_SEARCHES);
			setSearchType(dispatch, SEARCHES.BILLING_SEARCHES[0]);
		} else {
			setSearches(SEARCHES.DEFAULT_SEARCHES);
			setSearchType(dispatch, SEARCHES.DEFAULT_SEARCHES[0]);
		}

		if (![DASHBOARD_SECTION.BILLING, DASHBOARD_SECTION.INVOICE].includes(status)) {
			pollingGetServices();
			const interval = setInterval(() => {
				pollingGetServices();
			}, 20000); //20 seconds
			return () => clearInterval(interval);
		}
	}, [status]);

	useEffect(() => {
		getUserInfo();
	}, []);

	useEffect(() => {
		getPolicyList();
	}, []);

	const getPolicyList = async () => {
		const sfId = await getAccountSfId();

		if (!sfId) return;

		const {
			data: { data: policyList },
		} = await new HeliosClient().getPolicyIdentifiersOfAccount(sfId);

		if (policyList) {
			const policyListMapped = policyList.map((pin: string) => ({
				label: pin,
				name: pin,
			}));
			setPolicyPin(policyListMapped);
		}
	};

	const handleCancelPreOrder = () => {
		setShowPreOrderDrawer(dispatch, false);
		setSelectedEosServices(dispatch, []);
		setSelectedEosInvoices(dispatch, []);
		setShowConfirmCancelModal(false);
		removeSelectedEosStatus(EOS_STATUS.PENDING);
		setShowRejectServiceButtons(false);
		setDrawerDirection(DRAWER_DIRECTION_DEFAULT_STATE);
	};

	const onDismissClick = (): void => {
		setShowConfirmCancelModal(false);
	};

	const handleDrawerDirection = (direction: string): void => {
		setDrawerDirection(direction);
	};

	const handleCancelModal = (): void => {
		setShowConfirmCancelModal(true);
		clearSearch();
	};

	const ConfirmCancelPreOrder: FunctionComponent = () => {
		return (
			<>
				<h6>Are you sure that you want to cancel this action?</h6>
				<button
					type="button"
					className="custom-modal__container__button--cancel"
					onClick={(): void => handleCancelPreOrder()}
				>
					Yes, I&apos;m sure
				</button>
				<button
					type="button"
					className="custom-modal__window__button--active"
					onClick={(): void => setShowConfirmCancelModal(false)}
				>
					No, go back
				</button>
			</>
		);
	};

	const confirmRejectService = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
		e.preventDefault();
		setIsSubmitting(true);

		try {
			const response = await new EosClient().upsertEosServiceStatusRejected({
				serviceNumber: rejectedService!.serviceNumber,
				provider,
				user,
				reason: rejectReason,
			});
			if (response.status === 200) {
				setSelectedEosServices(dispatch, []);
				getServices();
				toast.success('Services edited successfully');
			} else {
				toast.error('Something went wrong, please try again');
			}
		} catch (e) {
			toast.error('Something went wrong, please try again');
		}

		setIsSubmitting(false);
		setRejectReason('');
		setShowConfirmRejectServiceModal(false);
		setShowPreOrderDrawer(dispatch, false);
		setShowRejectServiceButtons(false);
		setSelectedEosStatus(dispatch, []);
	};

	const getProviders = async (): Promise<void> => {
		const {
			data: { data },
		} = await new HeliosClient().getProviders();
		setProviders(data);
		const socialReasonData = cloneDeep(data) as Provider[];
		setSocialReasons(
			socialReasonData.map((provider: Provider) => {
				provider.name = provider.socialReason ?? '';
				return provider;
			})
		);
	};

	const getUserInfo = async (): Promise<void> => {
		if (user) {
			setBranch(user?.branch);
			const adminRole = user?.roles.find((role: string) => role === ROLE.ADMIN_EOS);
			if (adminRole) {
				setAdminRole(adminRole);
			}
		} else {
			setBranch(provider?.branch);
		}
	};

	const getAccountSfId = async (): Promise<string> => {
		const user: any = await localforage.getItem(USER_TYPE.USER);

		if (user && !adminRole) return user.account;
		else return '';
	};

	const getUserBranch = async (): Promise<string> => {
		const user: any = await localforage.getItem(USER_TYPE.USER);
		const provider: any = await localforage.getItem(USER_TYPE.PROVIDER);

		if (user) return user?.branch;
		else return provider?.branch;
	};

	const setFilter = async () => {
		const filter: any = {};

		if (searchTerm) {
			const field = parseSearchType(searchType.toLocaleLowerCase(), typeMaping.EOS);
			if (field) filter[field] = ['serviceNumber', 'preorderNum'].includes(field) ? parseInt(searchTerm) : searchTerm;
		}

		if (selectedSituations && selectedSituations.length > 0)
			filter.situation = { in: selectedSituations.map((situation) => situation.name) };

		if (selectedProviders && selectedProviders.length > 0)
			filter.vendorId = { in: selectedProviders.map((provider) => provider.vendorId) };

		if (selectedSocialReason && selectedSocialReason.length > 0)
			filter.vendorId = { in: selectedSocialReason.map((provider) => provider.vendorId) };

		if (selectedDivisions && selectedDivisions.length > 0)
			filter.serviceType = { in: selectedDivisions.map((division) => division.name) };

		if (selectedPolicies && selectedPolicies.length > 0)
			filter.policyPin = { in: selectedPolicies.map((policy) => policy.label) };

		if (selectedEosStatus && selectedEosStatus.length > 0) {
			if (status === DASHBOARD_SECTION.BILLING)
				filter.eosStatus = { in: selectedEosStatus.map((eosStatus) => eosStatus.name) };
			else filter.status = { in: selectedEosStatus.map((eosStatus) => eosStatus.name) };
		}

		if (isValidDate(startDate) && isValidDate(endDate)) {
			const branch = (await getUserBranch()) as BRANCH;
			const startOfDayForCountry = getStartOfDayForCountry(startDate, branch).toISOString();
			const endOfDayForCountry = getEndOfDayForCountry(endDate, branch).toISOString();
			if (status === DASHBOARD_SECTION.INVOICE) {
				filter.createdDate = { gte: startOfDayForCountry, lte: endOfDayForCountry };
			} else {
				filter.closingDate = { gte: startOfDayForCountry, lte: endOfDayForCountry };
			}
		}

		return filter;
	};

	const getServices = async () => {
		setLoading(true);
		if ([DASHBOARD_SECTION.BILLING, DASHBOARD_SECTION.INVOICE].includes(status)) {
			const filter = await setFilter();

			const bodyEos: any = {
				branch: await getUserBranch(),
				page: currentPage,
				limit: pageLimit,
				sort: JSON.stringify({ date: -1 }),
			};

			if (userType === USER_TYPE.PROVIDER && provider) {
				bodyEos.vendorId = provider.vendorId;
			}

			if (Object.keys(filter).length > 0) bodyEos.filter = JSON.stringify(filter);

			if (status === DASHBOARD_SECTION.BILLING) {
				const {
					data: {
						data: { services, total },
					},
				} = await new EosClient().getEosServices(bodyEos);
				setEosServices(services);
				setTotalServices(total);
			} else if (status === DASHBOARD_SECTION.INVOICE) {
				const {
					data: {
						data: { invoices, total },
					},
				} = await new EosClient().getEosInvoices({ ...bodyEos, includeServiceNumbers: true });
				setEosInvoices(invoices);
				setTotalServices(total);
			}
			setLoading(false);
			setPaging(false);
			setChangingView(false);
			return;
		}
		let sortBy = ['date'];
		let sortDirection = [-1];
		if (status === DASHBOARD_SECTION.HOLD_INSPECTION || status === DASHBOARD_SECTION.ACTIVE) {
			sortBy = ['schedule'];
			sortDirection = [1];
		}

		const body: any = {
			status: status,
			branch: await getUserBranch(),
			page: currentPage,
			limit: pageLimit,
			sortBy,
			sortDirection,
			sfId: await getAccountSfId(),
		};

		if (searchTerm)
			// If search type is 'Policy ID', then skip search term parsing
			body.term = parseSearchTerm(searchTerm) || '';
		if (searchType) body.searchType = parseSearchType(searchType.toLocaleLowerCase()) || '';
		if (selectedSituations) body.situations = selectedSituations.map((situation) => situation.name) || [];
		if (selectedPolicies) body.policyType = selectedPolicies.map((pin) => pin.name) || [];
		if (startDate) body.date = dayjs(startDate).unix() * 1000;

		if (status === DASHBOARD_SECTION.OTHER_STATUS && selectedEosOtherStatus?.length === 1) {
			body.status = selectedEosOtherStatus[0]?.name;
		}

		const {
			data: {
				data: { services, total },
			},
		} = await new HeliosClient().getServices(body);
		setTotalServices(total);
		setUpInfo(services, setServices, status);
		setLoading(false);
		setPaging(false);
		setChangingView(false);
	};

	const pollingGetServices = async () => {
		if (user) {
			if (user.account && !adminRole) {
				const providerAccount: any = await localforage.getItem(USER_TYPE.PROVIDER);

				const body: any = {
					status: DASHBOARD_SECTION.ACTIVE,
					branch: user ? user?.branch : providerAccount?.branch,
					page: 0,
					limit: 0,
					sort: JSON.stringify({ date: -1 }),
					sfId: user ? user?.account : undefined,
				};

				body.term = '';
				body.searchType = '';
				body.situations = [];

				const result = await new HeliosClient().getServices(body);
				let count = 0;
				for (const service of result.data.data.services) {
					if (oldServices.length > 0) {
						const oldService = oldServices.find((val: any) => val._id == service._id);
						if (
							service?.notes?.length > oldService?.notes?.length ||
							(service?.notes?.length > 0 && oldService && !oldService.notes)
						) {
							toast.info(`New Message Service Number: ${service.serviceNumber}`, { onClick: clickEvent });
							const audio = new Audio('/audio/notification-audio.mp3');
							audio.play();
						}
					}
					if (service.notes && service.hasUnreadNotes) {
						count++;
					}
				}

				oldServices = result.data.data.services;
				const countMessages = `(${count})`;

				document.title = count > 0 ? `${countMessages} Eos - Connect Assistance` : 'Eos - Connect Assistance';
			}
		}
	};

	const clickEvent = (obj: any): void => {
		const serviceNumber: string = obj?.target?.innerText?.split(':')[1].trim();
		if (serviceNumber) goToService(Number(serviceNumber));
	};

	const goToService = (serviceNumber: number | undefined): void => {
		history.push(`/services/${serviceNumber}`);
	};

	const clearSearch = (): void => {
		if (status === DASHBOARD_SECTION.INVOICE) {
			setSearchType(dispatch, SEARCHES.INVOICE_SEARCHES[0]);
		} else if (status === DASHBOARD_SECTION.BILLING) {
			setSearchType(dispatch, SEARCHES.BILLING_SEARCHES[0]);
		} else {
			setSearchType(dispatch, SEARCHES.DEFAULT_SEARCHES[0]);
		}

		setShowRejectServiceButtons(false);
		setSearchTerm(dispatch, '');
		setSelectedSituations(dispatch, []);
		setSelectedSocialReason(dispatch, []);
		setSelectedProviders(dispatch, []);
		setSelectedEosStatus(dispatch, []);
		setSelectedPolicies(dispatch, []);
		setSelectedDivisions(dispatch, []);
		setStartDate('');
		setEndDate('');
	};

	const onSearchClick = (search: string) => {
		if (search.length === 0) return;
		setSearchTerm(dispatch, search);
	};

	const onProviderClick = (providerName: string, vendorId: string): void => {
		const found = selectedProviders.find(({ name }) => name === providerName);
		if (found) {
			const newProvidersArray = selectedProviders.filter(({ name }) => name !== found.name);
			setSelectedProviders(dispatch, [...newProvidersArray]);
		} else {
			setSelectedProviders(dispatch, [...selectedProviders, { name: providerName, vendorId }]);
		}
	};

	const onSocialReasonClick = (socialReason: string, vendorId: string): void => {
		const found = selectedSocialReason.find(({ name }) => name === socialReason);
		if (found) {
			const newProvidersArray = selectedSocialReason.filter(({ name }) => name !== found.name);
			setSelectedSocialReason(dispatch, [...newProvidersArray]);
		} else {
			setSelectedSocialReason(dispatch, [...selectedSocialReason, { name: socialReason, vendorId }]);
		}
	};

	const onSituationClick = (situationLabel: string, situationName: string): void => {
		const found = selectedSituations.find(({ label }) => label === situationLabel);
		if (found) {
			const newSituationsArray = selectedSituations.filter(({ name }) => name !== found.name);
			setSelectedSituations(dispatch, [...newSituationsArray]);
		} else {
			setSelectedSituations(dispatch, [...selectedSituations, { label: situationLabel, name: situationName }]);
		}
	};

	const onDivisionClick = (divisionLabel: string, divisionName: string): void => {
		const found = selectedDivisions.find(({ name }) => name === divisionName);
		if (found) {
			const newSituationsArray = selectedDivisions.filter(({ name }) => name !== found.name);
			console.log('newSituationsArray', newSituationsArray);
			setSelectedDivisions(dispatch, [...newSituationsArray]);
		} else {
			console.log('en el else');
			setSelectedDivisions(dispatch, [...selectedDivisions, { label: divisionLabel, name: divisionName }]);
		}
	};

	const onSelectedEosStatusClick = (statusLabel: string): void => {
		const found = selectedEosStatus.find(({ name }) => name === statusLabel);
		if (found) {
			const newEosStatusArray = selectedEosStatus.filter(({ name }) => name !== found.name);
			setSelectedEosStatus(dispatch, [...newEosStatusArray]);
		} else {
			setSelectedEosStatus(dispatch, [...selectedEosStatus, { name: statusLabel }]);
		}
	};

	const onSelectedEosOtherStatusClick = (statusLabel: string): void => {
		const found = selectedEosOtherStatus.find(({ name }) => name === statusLabel);
		if (found) {
			const newEosStatusArray = selectedEosOtherStatus.filter(({ name }) => name !== found.name);
			setSelectedEosOtherStatus(dispatch, [...newEosStatusArray]);
		} else {
			setSelectedEosOtherStatus(dispatch, [...selectedEosOtherStatus, { name: statusLabel }]);
		}
	};

	const onPolicyClick = (situationLabel: string): void => {
		const found = selectedPolicies.find(({ label }) => label === situationLabel);
		if (found) {
			const newPolicies = selectedPolicies.filter(({ label }) => label !== found.label);
			setSelectedPolicies(dispatch, [...newPolicies]);
		} else {
			setSelectedPolicies(dispatch, [...selectedPolicies, { label: situationLabel, name: situationLabel }]);
		}
	};

	const removeSelectedEosStatus = (name: string): void => {
		const newArray = selectedEosStatus.filter((eosStatus: any) => eosStatus.name !== name);
		setSelectedEosStatus(dispatch, [...newArray]);
	};

	const removeSelectedEosOtherStatus = (name: string): void => {
		const newArray = selectedEosOtherStatus.filter((eosStatus: any) => eosStatus.name !== name);
		setSelectedEosOtherStatus(dispatch, [...newArray]);
	};

	const removeSelectedSituation = (label: string): void => {
		const newArray = selectedSituations.filter((situation: any) => situation.label !== label);
		setSelectedSituations(dispatch, [...newArray]);
	};

	const removeSelectedDivision = (label: string): void => {
		const newArray = selectedDivisions.filter((division) => division.label !== label);
		setSelectedDivisions(dispatch, [...newArray]);
	};

	const removeSelectedProvider = (name: string): void => {
		const newArray = selectedProviders.filter((provider: any) => provider.name !== name);
		setSelectedProviders(dispatch, [...newArray]);
	};

	const removeSelectedSocialReason = (name: string): void => {
		const newArray = selectedSocialReason.filter((provider: any) => provider.name !== name);
		setSelectedSocialReason(dispatch, [...newArray]);
	};

	const removePolicy = (name: string): void => {
		const newPolicies = selectedPolicies.filter((pin: any) => pin.label !== name);
		setSelectedPolicies(dispatch, [...newPolicies]);
	};

	const handleDownload = async (from: string, filename: string, type: reportType) => {
		try {
			if (downloadingFile) return;
			setDownloadingFile(true);

			const filter = await setFilter();
			const bodyEos: any = {
				status: status,
				branch: await getUserBranch(),
				sort: JSON.stringify({ date: -1 }),
				limit: 500,
			};

			if (userType === USER_TYPE.PROVIDER && provider) {
				bodyEos.vendorId = provider.vendorId;
			}

			if (Object.keys(filter).length > 0) bodyEos.filter = JSON.stringify(filter);

			let blob = null;
			switch (from) {
				case 'invoice':
					if (type === 'report') {
						blob = await new EosClient().getInvoiceReport(bodyEos);
					} else {
						blob = await new EosClient().getInvoicesAsAPdf(bodyEos);
					}
					break;
				case 'billing':
					if (type === 'report') {
						blob = await new EosClient().getBillingReport(bodyEos);
					} else {
						blob = await new EosClient().getEosServicesAsACsv(bodyEos);
					}
					break;
			}
			if (!blob) throw 'Download file error';
			return saveAs(blob.data, filename);
		} catch (error) {
			toast.error('Download file error');
		} finally {
			setDownloadingFile(false);
		}
	};

	const DownloadButton: FunctionComponent<DownloadButtonProps> = ({ from, filename, type }): JSX.Element => {
		return (
			<button
				className="dashboard dashboard__btn dashboard__btn--primary"
				onClick={() => {
					if (!startDate && !endDate) {
						toast.error('Filters are required');
						return false;
					} else if (eosServices.length === 0) {
						toast.error('No data found');
						return false;
					} else {
						handleDownload(from, filename, type);
					}
				}}
			>
				{downloadingFile ? (
					<i className="dashboard dashboard__btn dashboard__btn--loading"></i>
				) : (
					<i className="fa fa-download mx-1"></i>
				)}
				Download
			</button>
		);
	};

	const PreOrderButton: FunctionComponent = () => {
		return (
			<button className="dashboard dashboard__btn dashboard__btn--primary" onClick={() => showPreOrderSection()}>
				Create invoice
			</button>
		);
	};

	const RejectServiceButton: FunctionComponent = () => {
		return (
			<button className="dashboard dashboard__btn dashboard__btn--primary" onClick={() => showRejectServiceSection()}>
				Reject Service
			</button>
		);
	};

	const showRejectServiceSection = (): void => {
		if (showRejectServiceButtons) {
			setShowRejectServiceButtons(false);
			setSelectedEosStatus(dispatch, []);
		} else {
			setShowRejectServiceButtons(true);
			setSelectedEosStatus(dispatch, [{ name: EOS_STATUS.PENDING }, { name: EOS_STATUS.VALIDATED }]);
		}
	};

	const showPreOrderSection = (): void => {
		clearSearch();
		setShowPreOrderDrawer(dispatch, true);
		setSelectedEosStatus(dispatch, [{ name: EOS_STATUS.PENDING }, { name: EOS_STATUS.VALIDATED }]);
	};

	if (paging)
		Promise.resolve().then(() =>
			window.scrollTo({
				top: document.body.scrollHeight,
				behavior: 'smooth',
			})
		);

	const getStatusOptionsForFilter = () => {
		const billingStatuses = EOS_STATUSES.filter((status) => status !== EOS_STATUS.REJECTED).map((status) => ({
			name: status,
		}));
		const invoiceStatuses = EOS_STATUSES.filter(
			(status) => ![EOS_STATUS.VALIDATED, EOS_STATUS.APPROVED].includes(status)
		).map((status) => ({ name: status }));

		return status === DASHBOARD_SECTION.BILLING ? billingStatuses : invoiceStatuses;
	};

	const getOtherStatusOptionsForFilter = () => {
		const otherStatuses = EOS_OTHER_STATUSES.map((status) => ({
			name: status,
		}));

		return status === DASHBOARD_SECTION.OTHER_STATUS && otherStatuses;
	};

	return (
		<div className="dashboard">
			<Navbar />
			<>
				<DashboardHeader status={status} clearSearch={clearSearch} />
				{branch === BRANCH.PRI && status === DASHBOARD_SECTION.BILLING && user?.email && (
					<GenerateInvoicesButton branch={branch} />
				)}
				<div className="dashboard__search">
					<DashboardFilter items={searches} />
					<DashboardSearch searchTerm={searchTerm} clearSearch={clearSearch} onSearchClick={onSearchClick} />
					<div className="dashboard__btns">
						{status === DASHBOARD_SECTION.BILLING && userType === USER_TYPE.PROVIDER && (
							<DownloadButton from="billing" filename={`billing-data-${startDate}-to-${endDate}.xlsx`} type="data" />
						)}
						{status === DASHBOARD_SECTION.BILLING && (
							<>
								<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__PREORDER_BUTTON}>
									<PreOrderButton />
								</Feature>
								<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__DOWNLOAD_REPORT_BUTTON}>
									<DownloadButton
										from="billing"
										filename={`billing-report-${startDate}-to-${endDate}.xlsx`}
										type="report"
									/>
								</Feature>
							</>
						)}
						{status === DASHBOARD_SECTION.INVOICE && (
							<Feature name={FEATURE_FLAG.DASHBOARD_INVOICE__DOWNLOAD_REPORT_BUTTON}>
								<DownloadButton
									from="invoice"
									filename={`invoice-report-${startDate}-to-${endDate}.xlsx`}
									type="report"
								/>
							</Feature>
						)}
						{status === DASHBOARD_SECTION.INVOICE && userType === USER_TYPE.PROVIDER && (
							<Feature name={FEATURE_FLAG.DASHBOARD_INVOICE__DOWNLOAD_DATA_BUTTON}>
								<DownloadButton from="invoice" filename={`invoice-data-${startDate}-to-${endDate}.pdf`} type="data" />
							</Feature>
						)}
						{status === DASHBOARD_SECTION.BILLING && (
							<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__REJECT_SERVICE_BUTTON}>
								<RejectServiceButton />
							</Feature>
						)}
					</div>
				</div>
				<div className="dashboard__filters">
					{![DASHBOARD_SECTION.INVOICE].includes(status) && (
						<DashboardMultipleFilters
							label="Situations"
							items={situationTypes}
							selectedItems={selectedSituations}
							onItemClick={onSituationClick}
							removeSelectedItem={removeSelectedSituation}
						/>
					)}
					{[DASHBOARD_SECTION.BILLING].includes(status) && (
						<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_DIVISION_FILTER}>
							<DashboardMultipleFilters
								label="Division"
								items={DIVISIONS}
								selectedItems={selectedDivisions}
								onItemClick={onDivisionClick}
								removeSelectedItem={removeSelectedDivision}
							/>
						</Feature>
					)}
					{[DASHBOARD_SECTION.BILLING, DASHBOARD_SECTION.INVOICE].includes(status) && (
						<>
							<DashboardMultipleFilters
								label="Status"
								items={getStatusOptionsForFilter()}
								selectedItems={selectedEosStatus}
								onItemClick={onSelectedEosStatusClick}
								removeSelectedItem={removeSelectedEosStatus}
							/>
							{userType !== USER_TYPE.PROVIDER && (
								<DashboardMultipleFilters
									label="Providers"
									items={providers}
									selectedItems={selectedProviders}
									onItemClick={onProviderClick}
									removeSelectedItem={removeSelectedProvider}
								/>
							)}
							<Feature name={FEATURE_FLAG.DASHBOARD_BILLING_INVOICE__SHOW_SOCIAL_REASONS_FILTER}>
								<DashboardMultipleFilters
									label="Social Reason"
									items={socialReasons}
									selectedItems={selectedSocialReason}
									onItemClick={onSocialReasonClick}
									removeSelectedItem={removeSelectedSocialReason}
								/>
							</Feature>
							<input
								value={startDate}
								onChange={(evt): void => setStartDate(evt.target.value)}
								className="dashboard__date-input"
								type="date"
							/>
							{startDate && <button className="dashboard__close-icon" onClick={() => setStartDate('')}></button>}
							<input
								value={endDate}
								onChange={(evt): void => setEndDate(evt.target.value)}
								className="dashboard__date-input"
								type="date"
							/>
							{endDate && <button className="dashboard__close-icon" onClick={() => setEndDate('')}></button>}
						</>
					)}
					{userType === USER_TYPE.PROVIDER && status === DASHBOARD_SECTION.HOLD_INSPECTION && (
						<>
							<input
								value={startDate}
								onChange={(evt): void => setStartDate(evt.target.value)}
								className="dashboard__date-input"
								type="date"
							/>
							{startDate && <button className="dashboard__close-icon" onClick={() => setStartDate('')}></button>}
						</>
					)}
					{[DASHBOARD_SECTION.OTHER_STATUS].includes(status) && (
						<>
							<DashboardMultipleFilters
								label="Status"
								items={getOtherStatusOptionsForFilter()}
								selectedItems={selectedEosOtherStatus}
								onItemClick={onSelectedEosOtherStatusClick}
								removeSelectedItem={removeSelectedEosOtherStatus}
							/>
						</>
					)}
					{policyPin.length > 0 && (
						<DashboardMultipleFilters
							label="Policy Pin"
							items={policyPin}
							selectedItems={selectedPolicies}
							onItemClick={onPolicyClick}
							removeSelectedItem={removePolicy}
						/>
					)}
				</div>
				<ServicesTable
					status={status}
					services={services}
					eosServices={eosServices}
					eosInvoices={eosInvoices}
					userAccount={user}
					adminRole={adminRole}
					showRejectServiceButtons={showRejectServiceButtons}
					onRejectServiceClick={(service) => {
						setShowConfirmRejectServiceModal(true);
						setRejectedService(service);
					}}
					paging={paging}
					changingView={changingView}
				/>

				<Pagination
					currentPage={currentPage}
					pageLimit={pageLimit}
					totalServices={totalServices}
					onPrevious={(): void => {
						setPaging(true);
						setCurrentPage(dispatch, currentPage - 1);
					}}
					onNext={(): void => {
						setPaging(true);
						setCurrentPage(dispatch, currentPage + 1);
					}}
					onReturn={(): void => {
						setPaging(true);
						setCurrentPage(dispatch, 0);
					}}
					loading={loading}
				/>
				<Drawer direction={drawerDirection} size="lg" show={showPreOrderDrawer}>
					{showPreOrderDrawer && (
						<BillingPreOrder
							drawerDirection={drawerDirection}
							handleDrawerDirection={(direction): void => handleDrawerDirection(direction)}
							showConfirmCancelModal={(): void => handleCancelModal()}
							closePreOrder={handleCancelPreOrder}
							branch={branch as BRANCH}
						/>
					)}
				</Drawer>
				{showConfirmCancelModal && (
					<Modal dismiss={onDismissClick}>
						<ConfirmCancelPreOrder />
					</Modal>
				)}
				{showConfirmRejectServiceModal && (
					<Modal dismiss={onDismissClick}>
						<RejectServiceModal
							onRejectServiceSubmit={(event: React.FormEvent<HTMLFormElement>) => confirmRejectService(event)}
							onReasonChange={(e) => setRejectReason(e.target.value)}
							onCancelButtonClick={() => {
								setRejectReason('');
								setShowConfirmRejectServiceModal(false);
							}}
							rejectReason={rejectReason}
							isSubmitting={isSubmitting}
						/>
					</Modal>
				)}
			</>
		</div>
	);
};

export default DashboardScreen;

import Axios, { AxiosRequestHeaders, AxiosStatic } from 'axios';
import HttpClient from '../../api/HttpClient';
import { S3_MANAGER_SERVICE_API_KEY, S3_MANAGER_SERVICE_URL } from '../constants';
import { GenerateUploadUrlResponse, GenerateDownloadUrlResponse } from './types';

export default class S3ManagerService extends HttpClient {
	private headers: AxiosRequestHeaders;

	constructor(axios: AxiosStatic = Axios) {
		super(axios, S3_MANAGER_SERVICE_URL);
		this.headers = { 'Content-Type': 'application/json', 'x-api-key': S3_MANAGER_SERVICE_API_KEY };
	}

	public async uploadFile(
		bucketName: string,
		bucketRegion: string,
		fileName: string,
		fileExtension: string,
		fileFolder: string | undefined,
		file: File
	): Promise<string> {
		fileName = fileName.replace(/\s/g, '-');
		const data = JSON.stringify({
			BucketName: bucketName,
			BucketRegion: bucketRegion,
			FileName: fileName,
			FileExtension: fileExtension,
			FolderRoot: fileFolder,
		});

		const response = await this.post<GenerateUploadUrlResponse>('/generate-upload-url', data, {
			headers: this.headers,
		});

		if (response.status >= 400 || !response.data?.uploadUrl) {
			throw new Error(response.data?.message);
		}

		/**
		 *  Remove white spaces in the file name.
		 * 	Avoiding unexpected results when downloading files with blank spaces in the file name.
		 * 	Remove this when the bug in the microservice is resolved.
		 */
		Object.defineProperty(file, 'name', {
			writable: true,
			value: fileName,
		});

		const uploadResponse = await Axios.put(response.data.uploadUrl, file);

		if (uploadResponse.status >= 400) {
			throw new Error('Unable to upload file');
		}

		return response.data.uploadUrl.split('?')[0];
	}

	public async generateDownloadUrl(url: string): Promise<string> {
		const body = JSON.stringify({
			url,
		});

		const response = await this.post<GenerateDownloadUrlResponse>('/generate-download-url', body, {
			headers: this.headers,
		});

		if (response.status >= 400 || !response.data?.signedUrl) {
			throw new Error(response.data?.message);
		}

		return response.data?.signedUrl;
	}
}

// External imports
import styled from 'styled-components';
// @ts-expect-error: @carbon/react doesn't have full typescript support yet
import { TextArea as BaseTextArea } from '@carbon/react';
// Local imports
import { IStyledInputProps } from './types';
import { ClearInputIcon, InputContainer } from '../CustomCarbonCommons';

const TextArea = styled(BaseTextArea)<IStyledInputProps>`
	position: relative;

	& > .cds--text-area__label-wrapper {
		position: absolute;
		margin: 0;
		line-height: 0;
		height: 0;
		z-index: 1;
		top: 17.5px;
		left: 20px;
		pointer-events: none;
		transition: transform 0.3s;

		& > .cds--label {
			color: ${({ theme }) => theme.colors.textoGrisPrimario};
			${({ theme }) => theme.typography.p3};

			transition: color 0.3s;

			&::after {
				content: '*';
				color: ${({ theme }) => theme.colors.textoNaranjaSecundario};
				margin-left: 5px;
				display: ${({ $required }) => ($required ? 'inline-block' : 'none')};
			}
		}
	}

	&.filled > .cds--text-area__label-wrapper {
		transform: translate(-7.3%, -0.5rem) scale(0.85);
		& > .cds--label {
			color: ${({ theme }) => theme.colors.textoGrisTerciario};
		}
	}

	&.invalid > .cds--text-area__label-wrapper {
		& > .cds--label {
			color: ${({ theme }) => theme.colors.alertasError};
			&::after {
				color: ${({ theme }) => theme.colors.alertasError};
			}
		}
	}

	& .cds--text-area__wrapper {
		& > svg {
			// hide error icon
			display: none;
		}

		& > textarea.cds--text-area {
			padding: 25px 55px 0 20px;
			resize: none;
			background: ${({ theme }) => theme.colors.white};
			color: ${({ theme }) => theme.colors.textoGrisPrimario};
			border-radius: 10px;
			border: 1px solid ${({ theme }) => theme.colors.azul05};
			caret-color: ${({ theme }) => theme.colors.azul02};

			&:focus {
				outline: 2px solid ${({ theme }) => theme.colors.azul02};
				outline-offset: -2px;
			}

			&.cds--text-input--invalid {
				background: ${({ theme }) => theme.colors.alertasErrorClaro};
			}
		}
	}

	&.disabled {
		& > .cds--text-area__label-wrapper > .cds--label {
			color: ${({ theme }) => theme.colors.textoGrisTerciario};
			&::after {
				color: ${({ theme }) => theme.colors.textoGrisTerciario};
			}
		}

		& .cds--text-area__wrapper > textarea.cds--text-area {
			border: 1px solid ${({ theme }) => theme.colors.gris};
			color: ${({ theme }) => theme.colors.textoGrisTerciario};
			outline: unset;
			background: ${({ theme }) => theme.colors.white};
		}
	}
`;

const S = {
	InputContainer,
	ClearInputIcon,
	TextArea,
};

export default S;

import React, { FunctionComponent, useEffect, useState } from 'react';

interface DashboardSearchProps {
	searchTerm: string;
	clearSearch: any;
	onSearchClick: any;
}

import './DashboardSearch.scss';

const DashboardSearch: FunctionComponent<DashboardSearchProps> = ({
	searchTerm,
	clearSearch,
	onSearchClick,
}): JSX.Element => {
	const [search, setSearch] = useState<string>(searchTerm);

	useEffect(() => {
		setSearch(searchTerm);
	}, [searchTerm]);
	return (
		<div className="search-container">
			<input
				onChange={(evt): void => {
					setSearch(evt.target.value);
				}}
				className="search-container__input"
				value={search}
				type="text"
				placeholder="Search"
			/>
			<button onClick={() => onSearchClick(search)} className="search-container__btn  search-container__btn--primary">
				Search
			</button>
			{searchTerm !== '' && (
				<button
					onClick={() => {
						setSearch('');
						clearSearch();
					}}
					className="search-container__btn  search-container__btn--danger"
				>
					Clear
				</button>
			)}
		</div>
	);
};

export default DashboardSearch;

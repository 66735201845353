import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';

import { Context } from '../../context/Context';
import { AppContext, EosService } from '../../interfaces';
import { setSelectedEosServices } from '../../context/actions';

import TableRow from './TableRow';

import { BRANCH, EOS_STATUS, USER_TYPE } from '../../utils/constants';
import { evaluateInspectionLabel } from './Table.utils';
import {
	centsToCurrency,
	getBranchFromCountryCode,
	getProviderRetentionBreakdown,
	getRetentionsByBranch,
	getSituationType,
	isDateInPastOrEarlierPeriod,
	retentionLabels,
	getIVALabel,
} from '../../utils/Utils';

import AlertTag from '../DesignSystem/AlertTag';
import Feature from '../../utils/FeatureFlags';
import { FEATURE_FLAG } from '../../utils/FeatureFlags/constants';
import CheckboxDisabled from '../../assets/imgs/checkbox-disabled.png';

interface BillingTableProps {
	eosServices?: EosService[];
	showRejectServiceButtons: boolean;
	adminRole?: string;
	onRejectServiceClick: (service: EosService) => void;
}

const BillingTable: FunctionComponent<BillingTableProps> = ({
	eosServices,
	showRejectServiceButtons,
	adminRole,
	onRejectServiceClick,
}) => {
	const { provider, user } = useContext(Context) as AppContext;
	const [branch] = useState<BRANCH>((provider?.branch as BRANCH) || (user?.branch as BRANCH));
	const [allSelected, setAllSelected] = useState<boolean>(true);

	const { userType, showPreOrderDrawer, selectedEosServices, dispatch, situationTypes } = useContext(
		Context
	) as AppContext;

	const history = useHistory();
	const goToService = (serviceNumber: number | undefined): void => {
		history.push(`/services/${serviceNumber}`);
	};

	const handlePreOrderService = (service: EosService): void => {
		const { serviceNumber } = service;

		if (!selectedEosServices[serviceNumber]) {
			selectedEosServices[serviceNumber] = { service: service };
		} else {
			delete selectedEosServices[serviceNumber];
		}
		setSelectedEosServices(dispatch, { ...selectedEosServices });
	};

	useEffect(() => {
		if (
			eosServices?.every(
				(eosService) =>
					!isDateInPastOrEarlierPeriodForBranch(eosService.closingDate!) ||
					!(eosService.eosStatus === EOS_STATUS.PENDING || eosService.eosStatus === EOS_STATUS.VALIDATED) ||
					selectedEosServices[eosService.serviceNumber]
			)
		) {
			setAllSelected(true);
		} else {
			setAllSelected(false);
		}
	}, [selectedEosServices]);

	const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const selected = e.target?.checked;
		setAllSelected(selected);
		eosServices?.map((service) => {
			if (
				selected &&
				isDateInPastOrEarlierPeriodForBranch(service.closingDate!) &&
				(service.eosStatus === EOS_STATUS.PENDING || service.eosStatus === EOS_STATUS.VALIDATED)
			) {
				selectedEosServices[service.serviceNumber] = { service: service };
			} else {
				delete selectedEosServices[service.serviceNumber];
			}
		});
		setSelectedEosServices(dispatch, { ...selectedEosServices });
	};

	const handleRowClick = (service: EosService) => {
		if (showPreOrderDrawer && !isDateInPastOrEarlierPeriodForBranch(service.closingDate!)) {
			return;
		}
		!showPreOrderDrawer && !showRejectServiceButtons
			? goToService(service.serviceNumber)
			: service.eosStatus === EOS_STATUS.PENDING || service.eosStatus === EOS_STATUS.VALIDATED
			? handlePreOrderService(service)
			: null;
	};

	const selectedCheckMark = (serviceNumber: number): boolean => {
		return selectedEosServices[serviceNumber] !== undefined;
	};

	const isDateInPastOrEarlierPeriodForBranch = (dispatchDate: Date): boolean => {
		if (![BRANCH.MEX, BRANCH.PAN].includes(branch)) {
			return true;
		}
		return isDateInPastOrEarlierPeriod(dispatchDate);
	};

	return (
		<div className="eos-table eos-table--hover">
			<div className="table-head">
				<div className="table-row">
					{(showPreOrderDrawer || showRejectServiceButtons) && (
						<div className="eos-table__header">
							{(showPreOrderDrawer || !showRejectServiceButtons) && (
								<label>
									Select All
									<input checked={allSelected} type="checkbox" onChange={handleSelectAll} />
								</label>
							)}
						</div>
					)}
					<div className="eos-table__header">Service #</div>
					<div className="eos-table__header">Situation</div>
					<div className="eos-table__header">Status</div>
					<div className="eos-table__header">Review Status</div>
					<Feature name={FEATURE_FLAG.SERVICE_DETAIL__PREPAID_BUTTON}>
						<div className="eos-table__header">Prepaid</div>
					</Feature>
					<div className="eos-table__header">Finished Date</div>
					<div className="eos-table__header">Subtotal</div>
					<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_BREAKDOWN}>
						{getRetentionsByBranch(branch).map((retention) => (
							<div key={retention} className="eos-table__header">
								{retentionLabels[retention]}
							</div>
						))}
					</Feature>
					<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_TOTAL}>
						<div className="eos-table__header">Retention</div>
					</Feature>
					<div className="eos-table__header">{getIVALabel(branch)}</div>
					<div className="eos-table__header">Total</div>
					{(adminRole || userType === USER_TYPE.PROVIDER) && <div className="eos-table__header">Driver</div>}
					{adminRole && <div className="eos-table__header">Account</div>}
					{adminRole && <div className="eos-table__header">Provider</div>}
					<div className="eos-table__header">City</div>
					<div className="eos-table__header">Plate</div>
				</div>
			</div>
			{eosServices?.slice(0, 51).map((service: EosService) => (
				<TableRow key={service.id} onClick={() => handleRowClick(service)}>
					{showPreOrderDrawer && !showRejectServiceButtons && (
						<div className="table-cell">
							{(service.eosStatus === EOS_STATUS.PENDING || service.eosStatus === EOS_STATUS.VALIDATED) && (
								<>
									{isDateInPastOrEarlierPeriodForBranch(service.closingDate!) ? (
										<input
											disabled={!isDateInPastOrEarlierPeriodForBranch(service.closingDate!)}
											type="checkbox"
											checked={selectedCheckMark(service.serviceNumber)}
											readOnly
											aria-labelledby={`checkService-${service.id}`}
										/>
									) : (
										<div className="eos-table__tooltip cursor-no-drop">
											<img src={CheckboxDisabled} />
											<span className="eos-table__tooltipcheck">PO fuera del periodo de facturación</span>
										</div>
									)}
								</>
							)}
						</div>
					)}
					{showRejectServiceButtons && (
						<div className="table-cell eos-table__rejectTD">
							{(service.eosStatus === EOS_STATUS.PENDING || service.eosStatus === EOS_STATUS.VALIDATED) && (
								<button
									className="eos-table__rejectServiceButton"
									type="button"
									onClick={() => {
										onRejectServiceClick(service);
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="bi bi-x-square-fill rejectIcon"
										viewBox="0 0 16 16"
									>
										<path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
									</svg>
								</button>
							)}
						</div>
					)}
					<div data-label="Service Number" className="table-cell">
						{service.serviceNumber}
					</div>
					<div data-label="Situation" className="table-cell">
						<div className="eos-table__tooltip">
							<img className="eos-table__icon" src={evaluateInspectionLabel(service)} />
							<span className="eos-table__tooltiptext">{getSituationType(service.situation, situationTypes)}</span>
						</div>
					</div>
					<div data-label="Status" className="table-cell">
						{service.eosStatus}
					</div>
					<div data-label="Review Status" className="table-cell">
						<AlertTag text={service.reviewed ? 'YES' : 'NO'} variant={service.reviewed ? 'success' : 'warning'} />
					</div>
					<Feature name={FEATURE_FLAG.SERVICE_DETAIL__PREPAID_BUTTON}>
						<div data-label="Prepaid" className="table-cell">
							{service.isPrepaid ? 'Yes' : 'No'}
						</div>
					</Feature>
					<div data-label="Finished Date" className="table-cell">
						{dayjs.utc(service.closingDate).local().format('DD/MM/YYYY')}
					</div>
					<div data-label="Provider Payment" className="table-cell">
						{centsToCurrency(service.providerPaymentSubTotal || 0, getBranchFromCountryCode(service.countryCode))}
					</div>
					<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_BREAKDOWN}>
						{getRetentionsByBranch(branch).map((retention) => (
							<div key={retention} data-label={retention} className="table-cell">
								{getProviderRetentionBreakdown(service.providerRetentions, branch)?.[retention].currencyValue}
							</div>
						))}
					</Feature>
					<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_TOTAL}>
						<div data-label="Retention" className="table-cell">
							{centsToCurrency(service.providerPaymentRetention || 0, getBranchFromCountryCode(service.countryCode))}
						</div>
					</Feature>
					<div data-label="IVA" className="table-cell">
						{centsToCurrency(service.providerPaymentTax || 0, getBranchFromCountryCode(service.countryCode))}
					</div>
					<div data-label="Total" className="table-cell">
						{centsToCurrency(service.providerPaymentTotal || 0, getBranchFromCountryCode(service.countryCode))}
					</div>
					{(adminRole || userType === USER_TYPE.PROVIDER) && (
						<div data-label="Driver" className="table-cell">
							{service.driverName}
						</div>
					)}
					{adminRole && (
						<div data-label="Account" className="table-cell">
							{service.insuranceName}
						</div>
					)}
					{adminRole && (
						<div data-label="Provider" className="table-cell">
							{service.providerName}
						</div>
					)}
					<div data-label="City" className="table-cell">
						{service.serviceCity ? service.serviceCity : ''}
					</div>
					<div data-label="Plate" className="table-cell">
						{service.plate}
					</div>
				</TableRow>
			))}
		</div>
	);
};

export default BillingTable;

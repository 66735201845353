import React, { FunctionComponent, useContext, useState } from 'react';

import { BRANCH, MAX_QUANTITY_FILES } from '../../../../utils/constants';
import FileUploader from '../../../Commons/FileUploader';
import {
	fileErrorMessage,
	fileUploaderLabelPdf,
	fileUploaderLabelXml,
	fileUploaderTypesPdf,
	fileUploaderTypesXml,
} from '../../../Commons/FileUploader.utils';
import { FileTypeError } from '../../../../utils/Utils';
import { toast } from 'react-toastify';
import EosClient from '../../../../api/EosClient';
import AttachmentList from './AttachmentList';
import Feature from '../../../../utils/FeatureFlags';

import './index.scss';
import { FEATURE_FLAG } from '../../../../utils/FeatureFlags/constants';
import { Context } from '../../../../context/Context';
import { AppContext } from '../../../../interfaces';

const BRANCHES_TO_ALLOW_MULTIPLES_XML = [BRANCH.CRC];

interface TableProps {
	branch: BRANCH;
	files: File[];
	setFiles: (file: File[]) => void;
	xmlFile: File | null;
	setXmlFile: (file: File | null) => void;
	setXmlString: (xmlString: string) => void;
	xmlErrors: string[];
	setXmlErrors: (errors: string[]) => void;
	paymentTotal: number;
	paymentTaxTotal: number;
	paymentSubTotal: number;
	setExternalInvoiceId: (externalInvoiceId: string) => void;
	paymentRetentionTotal: number;
}

const Attachments: FunctionComponent<TableProps> = ({
	branch,
	files,
	setFiles,
	xmlFile,
	setXmlFile,
	setXmlString,
	xmlErrors,
	setXmlErrors,
	paymentTotal,
	paymentTaxTotal,
	paymentSubTotal,
	paymentRetentionTotal,
	setExternalInvoiceId,
}): JSX.Element => {
	const { provider } = useContext(Context) as AppContext;

	const [isValidatingXml, setIsValidatingXml] = useState(false);

	const handleFile = (filesToUpload: File[], errors: FileTypeError[]) => {
		if (files.length + filesToUpload.length > MAX_QUANTITY_FILES) {
			toast.error(`Exceeded the maximum number of files: ${MAX_QUANTITY_FILES}`);
			return;
		}
		files.push(...filesToUpload);
		setFiles([...files]);
		if (errors.length > 0) {
			const errorMessage = fileErrorMessage(errors);
			toast.error(errorMessage);
		}
	};

	const isValidXmlFileForBranch = (branch: BRANCH, xmlData: string) => {
		if (branch === BRANCH.CRC) {
			const regex = /MensajeHacienda/;
			return regex.test(xmlData);
		}
		return true;
	};

	const handleXmlFile = async (filesToUpload: File[], errors: FileTypeError[]) => {
		setIsValidatingXml(true);
		if (errors.length > 0) {
			const errorMessage = fileErrorMessage(errors);
			setIsValidatingXml(false);
			toast.error(errorMessage);
		} else {
			const xmlData = await getXmlData(filesToUpload[0]);

			if (!isValidXmlFileForBranch(branch, xmlData) && BRANCHES_TO_ALLOW_MULTIPLES_XML.includes(branch)) {
				files.push(...filesToUpload);
				setIsValidatingXml(false);
				return;
			}

			setXmlString('');
			setXmlErrors([]);

			try {
				setXmlString(xmlData);
				setXmlFile(filesToUpload[0]);

				await validateXml(xmlData);
			} catch (e) {
				toast.error(e.message);
			} finally {
				setIsValidatingXml(false);
			}
		}
	};

	const getXmlData = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsText(file);

			reader.onload = function () {
				resolve(reader.result as string);
			};

			reader.onerror = function () {
				reject(reader.error);
			};
		});
	};

	const validateXml = async (xml: string) => {
		try {
			const response = await new EosClient().validateXmlOfEosInvoice(
				xml,
				branch,
				paymentTotal,
				paymentTaxTotal,
				provider,
				paymentRetentionTotal,
				paymentSubTotal
			);
			if (response?.data?.errors?.length) {
				setXmlErrors(response.data.errors);
				toast.error('The XML contains errors');
			} else if (response?.data?.externalInvoiceId) {
				setExternalInvoiceId(response.data.externalInvoiceId);
			}
		} catch (e) {
			toast.error(e.message);
		}
	};

	const handleRemoveFile = (index: number) => {
		files.splice(index, 1);
		setFiles([...files]);
	};

	const handleRemoveXmlFile = () => {
		setXmlString('');
		setXmlFile(null);
		setXmlErrors([]);
		setExternalInvoiceId('');
	};

	return (
		<div className="billing-preorder-attachments">
			<div className="billing-preorder-attachments__header">
				<h6 className="mt-2">Attachments</h6>
				<div>
					<Feature name={FEATURE_FLAG.INVOICE_DETAIL__UPLOAD_XML}>
						<FileUploader
							handleFile={(filesToUpload, errors) => handleXmlFile(filesToUpload, errors)}
							label={fileUploaderLabelXml()}
							fileTypes={fileUploaderTypesXml()}
							multiple={BRANCHES_TO_ALLOW_MULTIPLES_XML.includes(branch)}
							handleFileLoading={isValidatingXml}
						/>
					</Feature>
					<FileUploader
						handleFile={(filesToUpload, errors) => handleFile(filesToUpload, errors)}
						label={fileUploaderLabelPdf()}
						fileTypes={fileUploaderTypesPdf()}
					/>
				</div>
			</div>
			<hr className="header-body-division" />
			<AttachmentList
				files={files}
				xmlFile={xmlFile}
				handleRemoveFile={handleRemoveFile}
				handleRemoveXmlFile={handleRemoveXmlFile}
				xmlErrors={xmlErrors}
			/>
		</div>
	);
};

export default Attachments;

import HeliosClient from '../../api/HeliosClient';

import VehicleVial from '../../assets/imgs/iconsets/default/vial.svg';
import WheelLift2DoorIcon from '../../assets/imgs/wheelLift2DoorIcon.svg';
import WheelLift4DoorIcon from '../../assets/imgs/wheelLift4DoorIcon.svg';
import FlatBedSingleIcon from '../../assets/imgs/flatBedSingleIcon.svg';
import FlatBedDoubleIcon from '../../assets/imgs/flatBedDoubleIcon.svg';
import MotoIcon from '../../assets/imgs/motoIcon.svg';
import StoreIcon from '../../assets/imgs/iconsets/default/shops.svg';

import SuraVialIcon from '../../assets/imgs/iconsets/sura/vial.svg';
import SuraWheelLift2DoorIcon from '../../assets/imgs/iconsets/sura/wheelLift2D.svg';
import SuraWheelLift4DoorIcon from '../../assets/imgs/iconsets/sura/wheelLift4D.svg';
import SuraFlatBed2Door from '../../assets/imgs/iconsets/sura/sura_flatBedSingleIcon.svg';
import SuraFlatBed4Door from '../../assets/imgs/iconsets/sura/sura_flatBedDoubleIcon.svg';
import SuraMotoIcon from '../../assets/imgs/iconsets/sura/moto.svg';

import VialConnect from '../../assets/imgs/iconsets/connect/vial.svg';
import WheelLift2DConnect from '../../assets/imgs/iconsets/connect/wheelLift2D.svg';
import WheelLift4DConnect from '../../assets/imgs/iconsets/connect/wheelLift4D.svg';
import FlatBed2DConnect from '../../assets/imgs/iconsets/connect/flatbed2D.svg';
import FlatBed4DConnect from '../../assets/imgs/iconsets/connect/flatbed4D.svg';
import MotoConnect from '../../assets/imgs/iconsets/connect/moto.svg';

import VantiMotoIcon from '../../assets/imgs/iconsets/vanti/moto.svg';
import { HttpDriver, ActiveService } from '../../interfaces';

export const setTimeToSituation = (service: ActiveService): string | null => {
	if (service.trip.live) {
		const date = new Date(+service.trip.live.eta * 1000);
		return date.toISOString().substr(11, 5);
	} else return null;
};

export const setTimeToDestination = async (service: ActiveService, driver: HttpDriver): Promise<string | null> => {
	if (service.locations.situation.lat && service.locations.destination.lat) {
		try {
			const ttd = await getTimeToDestination(service, driver);
			if (!ttd) return null;

			const minutes = ttd.value;
			const hrs = minutes / 3600 > 1 ? Math.floor(minutes / 3600) : 0;
			const mins = Math.round(((minutes / 3600) % 1) * 60);
			return `${hrs}:${mins < 10 ? '0' + mins : mins}`;
		} catch (error) {
			console.error('##getTTDError', error);
			return null;
		}
	} else return null;
};

export const getTimeToDestination = async (service: ActiveService, driver: HttpDriver): Promise<any> => {
	try {
		const body = {
			origin: `${driver.lat},${driver.lng}`,
			destination: `${service.locations.destination.lat},${service.locations.destination.lng}`,
		};
		const response = await new HeliosClient().getTTD(body);

		return response.data.ttd;
	} catch (error) {
		console.error('##getTTDError', error);
		return null;
	}
};

export const getServicesCount = (driver: HttpDriver, activeServices: ActiveService[]): number => {
	return activeServices.filter((service: any) => service.driver.id === driver._id).length;
};

export const VEHICLE_ICONS: any = {
	vial: {
		default: VehicleVial,
		connect: VialConnect,
		sura: SuraVialIcon,
	},
	wheellift2door: {
		default: WheelLift2DoorIcon,
		connect: WheelLift2DConnect,
		sura: SuraWheelLift2DoorIcon,
	},
	wheellift4door: {
		default: WheelLift4DoorIcon,
		connect: WheelLift4DConnect,
		sura: SuraWheelLift4DoorIcon,
	},
	flatbed2door: {
		default: FlatBedSingleIcon,
		connect: FlatBed2DConnect,
		sura: SuraFlatBed2Door,
	},
	flatbed4door: {
		default: FlatBedDoubleIcon,
		connect: FlatBed4DConnect,
		sura: SuraFlatBed4Door,
	},
	moto: {
		default: MotoIcon,
		connect: MotoConnect,
		sura: SuraMotoIcon,
		vanti: VantiMotoIcon,
	},
	stores: {
		default: StoreIcon,
		connect: StoreIcon,
		sura: StoreIcon,
	},
};

export const getVehicleIcon = (vehicleType?: string, iconset?: string) => {
	vehicleType = vehicleType?.toLowerCase() || undefined;
	iconset = iconset?.toLowerCase() || undefined;
	if (vehicleType && iconset) {
		return VEHICLE_ICONS[vehicleType][iconset];
	}
	return undefined;
};

import React, { FunctionComponent } from 'react';
import cn from 'classnames';

import './Drawer.scss';

interface DrawerProps {
	show: boolean;
	direction?: string;
	size?: string;
	children: React.ReactNode;
	windowClassName?: string;
	containerClassName?: string;
}

const Drawer: FunctionComponent<DrawerProps> = ({
	show,
	direction,
	size,
	children,
	windowClassName,
	containerClassName,
}): JSX.Element => {
	const drawerClass = 'drawer';
	const drawerDirection = `drawer__${direction}`;
	const drawerDirectionOpen = show ? drawerDirection + '--open' : '';
	const drawerSize = size ? `${drawerClass}--${size}` : `${drawerClass}--md`;
	const classNameWindow = cn('drawer__window', windowClassName);
	const classNameContainer = cn('drawer__container', containerClassName);

	return (
		<div className={`${drawerClass} ${drawerSize} ${drawerDirection} ${drawerDirectionOpen}`}>
			<div className={classNameWindow}>
				<div className={classNameContainer}>{children}</div>
			</div>
		</div>
	);
};

export default Drawer;

import React, { useState } from 'react';
import S from './styles';
import { IProps } from './types';

const TextArea = ({
	id,
	labelText,
	value,
	onChange,
	disabled,
	error,
	helperText,
	required,
	className,
}: IProps): React.ReactElement => {
	const [focused, setFocused] = useState(false);

	const onFocus = () => {
		setFocused(true);
	};

	const onBlur = () => {
		if (!value) {
			setFocused(false);
		}
	};

	const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(id, e.target.value);
	};

	const clear = () => {
		if (!disabled) {
			onChange(id, '');
			setFocused(false);
		}
	};

	let filledInputClassName = '';
	if (focused || value) {
		filledInputClassName = 'filled';
	}
	let statusClassName = '';
	if (error) {
		statusClassName += ' invalid';
	}
	if (disabled) {
		statusClassName += ' disabled';
	}

	return (
		<S.InputContainer className={className + statusClassName}>
			{value && <S.ClearInputIcon size={20} onClick={clear} className={statusClassName} />}
			<S.TextArea
				id={id}
				labelText={labelText}
				value={value}
				onChange={_onChange}
				disabled={disabled}
				invalid={Boolean(error)}
				invalidText={error}
				helperText={helperText}
				$required={required}
				className={filledInputClassName + statusClassName}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
		</S.InputContainer>
	);
};

export default TextArea;

import styled from 'styled-components';

const LoadingContainer = styled.span`
	margin-right: 10px;
	& > .cds--loading--small > .cds--loading__svg > .cds--loading__stroke {
		stroke: ${({ theme }) => theme.colors.textoGrisTerciario};
	}
`;

const S = { LoadingContainer };

export default S;

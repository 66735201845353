import React, { FunctionComponent, useState } from 'react';
import cn from 'classnames';

import './ServiceFooter.scss';

import Gallery from './Gallery';
import ServiceNotes from './Notes';
import Timestamps from './Timestamp';
import { CSM_INSPECTIONS } from '../../utils/constants';
import { EosService, Service } from '../../interfaces';
import FileUploader from '../Commons/FileUploader';
import { fileUploaderTypesPODetail } from '../Commons/FileUploader.utils';
import { FileTypeError } from '../../utils/Utils';
import Feature from '../../utils/FeatureFlags';
import { FEATURE_FLAG } from '../../utils/FeatureFlags/constants';
import ContactAttempts from './ContactAttempts/contactattempts';
import { mustShowContactAttempts } from './ContactAttempts/utils';

enum SelectedTab {
	GALLERY = 'gallery',
	NOTES = 'notes',
}
interface ServiceFooterProps {
	service: Service;
	serviceImages: any;
	handleFile: (files: Array<File>, errors: Array<FileTypeError>) => void;
	handleFileLoading: boolean;
	handleFileDownload: () => void;
	eosService: EosService;
}

const ServiceFooter: FunctionComponent<ServiceFooterProps> = ({
	service,
	serviceImages,
	handleFile,
	handleFileLoading,
	handleFileDownload,
	eosService,
}): JSX.Element => {
	const [selectedTab, setSelectedTab] = useState<SelectedTab>();

	const hideGallery = () => {
		return [CSM_INSPECTIONS].includes(service.sfIdAccount) && service.situation === 'inspections';
	};

	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="tab-container mt-3">
						<Feature name={FEATURE_FLAG.SERVICE_DETAIL__NOTES_SECTION__SHOW} accountId={service.sfIdAccount}>
							<button
								className={cn('btn btn-round btn-outline-light', {
									active: selectedTab === SelectedTab.NOTES,
								})}
								onClick={(): void => setSelectedTab(SelectedTab.NOTES)}
							>
								NOTES
							</button>
						</Feature>
						<button
							hidden={hideGallery()}
							className={cn('btn btn-round btn-outline-light', {
								active: selectedTab === SelectedTab.GALLERY,
							})}
							onClick={(): void => setSelectedTab(SelectedTab.GALLERY)}
						>
							GALLERY
						</button>
					</div>
				</div>
			</div>

			<div className="row mt-3">
				{selectedTab === SelectedTab.GALLERY &&
					!([CSM_INSPECTIONS].includes(service.sfIdAccount) && service.situation === 'inspections') && (
						<>
							<Gallery images={serviceImages} />
							<div className="btn-group">
								<FileUploader
									handleFile={(files, errors) => handleFile(files, errors)}
									label="Upload Media"
									fileTypes={fileUploaderTypesPODetail()}
									handleFileLoading={handleFileLoading}
								/>
								{serviceImages.length > 0 && (
									<button
										className="btn-group__btn"
										type="button"
										title="Download Media"
										onClick={() => handleFileDownload()}
									>
										<i className="fa fa-download"></i> Download Media
									</button>
								)}
							</div>
						</>
					)}

				{selectedTab === SelectedTab.NOTES && (
					<Feature name={FEATURE_FLAG.SERVICE_DETAIL__NOTES_SECTION__SHOW} accountId={service.sfIdAccount}>
						<ServiceNotes service={service} allowAddNotes={!eosService?.status} />
					</Feature>
				)}

				{service && service.trip && service.status !== 'Hold' && service.status !== 'HoldInspection' && (
					<Timestamps service={service} />
				)}

				{service && mustShowContactAttempts(service) && (
					<Feature name={FEATURE_FLAG.SERVICE_DETAIL__CONTACT_ATTEMPT__SHOW} accountId={service.sfIdAccount}>
						<ContactAttempts service={service} />
					</Feature>
				)}
			</div>
		</>
	);
};

export default ServiceFooter;

// External imports
import React from 'react';
// Global imports
import Navbar from 'components/Navbar';
// Local imports
import S from './styles';
import { FormContextProvider } from './context/Context';
import MainContent from './components/MainContent';

const CreateServiceView = (): React.ReactElement => {
	return (
		<S.CreateServiceContainer>
			<Navbar />
			<S.MainContentContainer>
				<FormContextProvider>
					<MainContent />
				</FormContextProvider>
			</S.MainContentContainer>
		</S.CreateServiceContainer>
	);
};

export default CreateServiceView;

import React, { FunctionComponent } from 'react';
import './RejectServiceModal.scss';

interface RejectServiceModalProps {
	onRejectServiceSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
	onReasonChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onCancelButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
	rejectReason: string;
	isSubmitting: boolean;
}

const RejectServiceModal: FunctionComponent<RejectServiceModalProps> = ({
	onRejectServiceSubmit,
	onReasonChange,
	onCancelButtonClick,
	rejectReason,
	isSubmitting,
}): JSX.Element => (
	<form onSubmit={onRejectServiceSubmit} className="reject-service-modal__form">
		<div className="reject-service-modal__reason-textarea">
			<label htmlFor="reasonTextarea" className="reject-service-modal__label">
				Why do you want to reject this service?
			</label>
			<textarea
				className="reject-service-modal__textarea form-control"
				value={rejectReason}
				onChange={onReasonChange}
				disabled={isSubmitting}
				required
				id="reasonTextarea"
				maxLength={250}
				rows={4}
			/>
		</div>
		<div className="reject-service-modal__btns">
			{!isSubmitting && (
				<button
					type="button"
					className="reject-service-modal__btn reject-service-modal__cancel-btn"
					onClick={onCancelButtonClick}
				>
					Cancel
				</button>
			)}
			<button
				type="submit"
				disabled={isSubmitting}
				className="reject-service-modal__btn reject-service-modal__reject-btn"
			>
				{isSubmitting ? <div className="btn-loader" /> : 'Reject Service'}
			</button>
		</div>
	</form>
);

export default RejectServiceModal;

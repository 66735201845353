/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Axios, { AxiosResponse, AxiosStatic, AxiosRequestConfig } from 'axios';
import { HELIOS_REGULAR_KEY } from '../utils/constants';
import { getAuthToken } from './HeliosClient.utils';

export const getAxiosConfig = async () => {
	const token = await getAuthToken();

	if (token) {
		return { headers: { Authorization: token } };
	} else {
		return { header: { Authorization: HELIOS_REGULAR_KEY } };
	}
};

export default class HttpClient {
	constructor(private axios: AxiosStatic = Axios, private baseUrl: string) {}

	protected async get<T>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return this.axios.get(`${this.baseUrl}${url}`, options || (await getAxiosConfig()));
	}
	protected async post<T>(url: string, data: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return this.axios.post(`${this.baseUrl}${url}`, data, options || (await getAxiosConfig()));
	}
	protected async put<T>(url: string, data?: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return this.axios.put(`${this.baseUrl}${url}`, data, options || (await getAxiosConfig()));
	}
	protected async patch<T>(url: string, data: any, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return this.axios.patch(`${this.baseUrl}${url}`, data, options || (await getAxiosConfig()));
	}
	protected async delete<T>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return this.axios.delete(`${this.baseUrl}${url}`, options || (await getAxiosConfig()));
	}
}

import { Provider, User } from 'interfaces';
import { PENDO_APIKEY, ROLE, ROLE_TYPE } from '../utils/constants';

export const loadPendoScript = (user: User, provider: Provider): Promise<void> => {
	return new Promise((resolve, reject) => {
		const cleanup = () => {
			if (script && script.parentNode) {
				script.parentNode.removeChild(script);
			}
		};

		if (window.pendo?.isReady()) {
			initializePendo(user, provider);
			resolve();
			return;
		}

		const script = document.createElement('script');
		script.src = `https://cdn.pendo.io/agent/static/${PENDO_APIKEY}/pendo.js`;
		script.async = true;

		script.onload = () => {
			initializePendo(user, provider);
			console.log('Pendo script loaded and initialized.');
			resolve();
		};

		script.onerror = () => {
			cleanup();
			console.error('Failed to load the Pendo script.');
			reject(new Error('Failed to load the Pendo script'));
		};

		window.addEventListener('unload', cleanup);
		document.body.appendChild(script);
	});
};

interface PendoVisitorData {
	id: string;
	email: string;
	phone: string;
	branch: string;
	fullName: string;
	roles: string[];
	userType: ROLE_TYPE;
	[key: string]: string | string[] | ROLE_TYPE;
}

const initializePendo = (user: User, provider: Provider): void => {
	try {
		if (!user?._id && !provider?._id) {
			console.error('No user or provider data available for Pendo initialization');
			return;
		}

		let visitor: PendoVisitorData | undefined;
		let accountId: string | undefined;

		if (user?._id) {
			visitor = {
				id: user._id,
				email: user.email,
				phone: user.phone || '',
				branch: user.branch,
				fullName: `${user.firstname} ${user.lastname}`,
				roles: user.roles,
				userType: user.roles.includes(ROLE.ADMIN_EOS) ? ROLE_TYPE.ADMIN : ROLE_TYPE.CLIENT,
			};
			accountId = user.account;
		} else if (provider?._id) {
			visitor = {
				id: provider._id,
				email: provider.email,
				phone: provider.phone || '',
				branch: provider.branch,
				fullName: provider.name,
				roles: provider.roles,
				userType: ROLE_TYPE.PROVIDER,
			};
		}

		if (visitor) {
			window.pendo.initialize({
				apiKey: PENDO_APIKEY,
				visitor,
				account: {
					id: accountId || '',
				},
			});
			console.log('Pendo initialized successfully.');
		}
	} catch (error) {
		console.error('Error initializing Pendo', error instanceof Error ? error.message : error);
	}
};

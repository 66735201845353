// External imports
import { useRef, useState, useEffect, useCallback, useContext } from 'react';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
// Global imports
import { FixedLocation } from 'interfaces';
import { geoJsonToLatLngLiteral } from 'utils/Utils';
import { BRANCH, branchAlpha2CodeMap } from 'utils/constants';
// Local imports
import {
	PlacesService,
	AutocompleteService,
	Geocoder,
	AutocompleteSessionToken,
	AutocompletePrediction,
	IUseLocationReturnValue,
} from './types';
import T from '../../texts';
import { DispatchFormContext } from '../../context/Context';

const GOOGLE_PLACE_DETAILS_FIELDS = ['geometry.location', 'place_id', 'formatted_address'];

const useLocation = (
	locationType: 'situation' | 'destination',
	branch: BRANCH,
	mapsLoaded: boolean,
	placesService: PlacesService | null,
	autocompleteService: AutocompleteService | null,
	geocoder: Geocoder | null
): IUseLocationReturnValue => {
	const { setLocationsAttribute, locations } = useContext(DispatchFormContext);
	const [selectedFixedLocation, setSelectedFixedLocation] = useState<FixedLocation | null>(null);
	const autoCompleteTokenRef = useRef<AutocompleteSessionToken | null>(null);
	const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompletePrediction[]>([]);
	const [selectedAutocompleteOption, setSelectedAutocompleteOption] = useState<AutocompletePrediction | null>(null);
	const [loadingPlaceData, setLoadingPlaceData] = useState<boolean>(false);

	useEffect(() => {
		if (mapsLoaded) {
			autoCompleteTokenRef.current = new window.google.maps.places.AutocompleteSessionToken();
		}
	}, [mapsLoaded]);

	const onAutocompleteError = useCallback(
		debounce(() => {
			toast.error(T.locations.locationAutoCompleteError);
		}, 1000),
		[]
	);

	const searchAutocompleteOptions = async (input: string) => {
		if (input.length > 0 && autocompleteService && autoCompleteTokenRef.current) {
			try {
				const response = await autocompleteService.getPlacePredictions({
					input,
					componentRestrictions: { country: branchAlpha2CodeMap[branch] },
					sessionToken: autoCompleteTokenRef.current,
				});
				setAutocompleteOptions(
					response.predictions.map((pred) => ({ description: pred.description, place_id: pred.place_id }))
				);
			} catch (error) {
				onAutocompleteError();
			}
		}
	};

	const selectAutocompleteOption = async (selectedItem: AutocompletePrediction | null) => {
		setSelectedAutocompleteOption(selectedItem);
		if (selectedItem && placesService && autoCompleteTokenRef.current) {
			setLoadingPlaceData(true);
			placesService.getDetails(
				{
					placeId: selectedItem.place_id,
					fields: GOOGLE_PLACE_DETAILS_FIELDS,
					sessionToken: autoCompleteTokenRef.current,
				},
				(place, status) => {
					if (place && status == google.maps.places.PlacesServiceStatus.OK) {
						const lat = place.geometry?.location?.lat();
						const lng = place.geometry?.location?.lng();
						const address = place.formatted_address;
						const placeId = place.place_id;
						if (lat && lng && address && placeId) {
							const location = {
								lat,
								lng,
								address,
								placeId,
							};
							setLocationsAttribute(locationType, location);
						}
					}
					autoCompleteTokenRef.current = new window.google.maps.places.AutocompleteSessionToken();
					setLoadingPlaceData(false);
				}
			);
		}
	};

	const getServiceLocationFromLatLng = async (latLng: google.maps.LatLng | google.maps.LatLngLiteral) => {
		try {
			setLoadingPlaceData(true);
			const response = await geocoder?.geocode({ location: latLng });
			if (response?.results?.[0]) {
				const place = response?.results?.[0];
				const lat = place.geometry?.location?.lat();
				const lng = place.geometry?.location?.lng();
				const address = place.formatted_address;
				const placeId = place.place_id;
				if (lat && lng && address && placeId) {
					const location = {
						lat,
						lng,
						address,
						placeId,
					};
					return location;
				}
			}
		} catch (error) {
			toast.error(T.locations.locationAutoCompleteError);
		} finally {
			setLoadingPlaceData(false);
		}
	};

	const updateLocationAndComboBoxPredictions = async (e: google.maps.MapMouseEvent) => {
		if (e.latLng?.lat() && e.latLng?.lng()) {
			const serviceLocation = await getServiceLocationFromLatLng(e.latLng);
			if (serviceLocation) {
				const prediction = { description: serviceLocation.address, place_id: serviceLocation.placeId };
				setLocationsAttribute(locationType, serviceLocation);
				setAutocompleteOptions([prediction]);
				setSelectedAutocompleteOption(prediction);
			}
		}
	};

	const updateLocationFromFixedLocation = async (_: string, fixedLocation: FixedLocation | null) => {
		if (!fixedLocation) {
			setSelectedFixedLocation(null);
			setLocationsAttribute(locationType, null);
		} else {
			const latLng = geoJsonToLatLngLiteral(fixedLocation.coordinates);
			const serviceLocation = await getServiceLocationFromLatLng(latLng);
			if (serviceLocation) {
				setSelectedFixedLocation(fixedLocation);
				setLocationsAttribute(locationType, serviceLocation);
			}
		}
	};

	return {
		autocompleteOptions,
		searchAutocompleteOptions,
		selectedAutocompleteOption,
		selectAutocompleteOption,
		selectedFixedLocation,
		updateLocationFromFixedLocation,
		location: locations[locationType],
		loading: loadingPlaceData,
		updateLocationAndComboBoxPredictions,
	};
};

export default useLocation;

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { BRANCH, Timezones } from './constants';

dayjs.extend(utc);
dayjs.extend(timezone);

type DateTimeStyle = 'full' | 'long' | 'medium' | 'short';

interface FormatDateTimeProps {
	date: Date;
	locale?: string | string[];
	dateStyle?: DateTimeStyle;
	timeStyle?: DateTimeStyle;
}

const formatDateTime = ({ date, locale = 'es-ES', dateStyle = 'full', timeStyle = 'short' }: FormatDateTimeProps) => {
	return new Intl.DateTimeFormat(locale, {
		dateStyle,
		timeStyle,
	}).format(date);
};

const transformDateToISOString = (dateString: string): string | null => {
	const date = new Date(dateString);
	if (isNaN(date.getTime())) {
		return null;
	}
	return date.toISOString();
};

function getStartOfDayUTC(date: string): Date {
	const startDate = dayjs.utc(date).startOf('day');
	return startDate.toDate();
}

function getEndOfDayUTC(date: string): Date {
	const endDate = dayjs.utc(date).endOf('day');
	return endDate.toDate();
}

function getStartOfDayForCountry(date: string, branch: BRANCH): Date {
	const startDate = dayjs.tz(date, Timezones[branch]).startOf('day').utc();
	return startDate.toDate();
}

function getEndOfDayForCountry(date: string, branch: BRANCH): Date {
	const endDate = dayjs.tz(date, Timezones[branch]).endOf('day').utc();
	return endDate.toDate();
}

function formatTimestampToCountryTz(utcTimestamp: string, branch: BRANCH, format: string): string {
	return dayjs(utcTimestamp).tz(Timezones[branch]).format(format);
}

function formatUTC(date: string, format: string): string {
	return dayjs.utc(date).format(format);
}

function isValidDate(date?: string | number | Date | null): boolean {
	const dateObj = new Date(date as any);
	return Boolean(date) && !Number.isNaN(dateObj.getTime());
}

export {
	formatDateTime,
	transformDateToISOString,
	getStartOfDayUTC,
	getEndOfDayUTC,
	getStartOfDayForCountry,
	getEndOfDayForCountry,
	formatTimestampToCountryTz,
	formatUTC,
	isValidDate,
};

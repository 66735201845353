import styled from 'styled-components';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

export const FlexCenterItems = styled.div`
	align-items: center;
	display: flex;
`;

export const Title = styled.span`
	font-size: 1.063rem;
	font-weight: 500;
	flex: 1;
`;

export const AddButton = styled(Button)`
	margin-left: 0.75rem;
	backgroud-color: #ff5100;
	& > .pi {
		color: white;
	}
`;

export const List = styled.div`
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
`;

export const Time = styled.span`
	font-size: 1.063rem;
	font-weight: 500;
`;

export const Container = styled(Card)`
	margin-bottom: 12px;
	flex: 1;
`;

import React, { FunctionComponent } from 'react';
import './ConfirmInvoiceActionModal.scss';

interface ConfirmApproveInvoiceModalProps {
	onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
	onCancelButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
	isSubmitting: boolean;
	title: string;
	okText: string;
}

const ConfirmInvoiceActionModal: FunctionComponent<ConfirmApproveInvoiceModalProps> = ({
	onSubmit,
	onCancelButtonClick,
	isSubmitting,
	title,
	okText,
}): JSX.Element => {
	return (
		<div className="confirm-invoice-action-modal">
			<p className="confirm-invoice-action-modal__text">{title}</p>
			<div className="confirm-invoice-action-modal__btns">
				<button
					type="button"
					className="confirm-invoice-action-modal__btn confirm-invoice-action-modal__cancel-btn"
					onClick={onCancelButtonClick}
				>
					No, go back
				</button>
				<button
					type="submit"
					disabled={isSubmitting}
					className="confirm-invoice-action-modal__btn confirm-invoice-action-modal__approve-invoice-btn"
					onClick={onSubmit}
				>
					{isSubmitting ? <div className="btn-loader" /> : okText}
				</button>
			</div>
		</div>
	);
};

export default ConfirmInvoiceActionModal;

// External imports
import styled from 'styled-components';
// @ts-expect-error: @carbon/react doesn't have full typescript support yet
import { ComboBox as BaseComboBox } from '@carbon/react';
// Local imports
import { IStyledInputProps } from './types';

const ComboBox = styled(BaseComboBox)<IStyledInputProps>`
	position: relative;

	& > .cds--label {
		position: absolute;
		margin: 0;
		line-height: 0;
		height: 2rem;
		width: calc(100% - 60px);
		z-index: 1;
		top: 17.5px;
		left: 20px;
		pointer-events: none;
		transition: transform 0.3s, color 0.3s, width 0.3s;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		color: ${({ theme }) => theme.colors.textoGrisPrimario};
		${({ theme }) => theme.typography.p3};

		&::after {
			content: '*';
			color: ${({ theme }) => theme.colors.textoNaranjaSecundario};
			margin-left: 5px;
			display: ${({ $required }) => ($required ? 'inline-block' : 'none')};
		}
	}

	&.filled > .cds--label {
		transform: translate(-7.4%, -8px) scale(0.85);
		width: calc(100% - 35px);
		color: ${({ theme }) => theme.colors.textoGrisTerciario};
	}

	& > .cds--combo-box {
		max-height: unset;
		height: 55px;
		background: ${({ theme }) => theme.colors.white};
		border-radius: 10px;
		border: 1px solid ${({ theme }) => theme.colors.azul05};
		color: ${({ theme }) => theme.colors.textoGrisPrimario};

		& > .cds--list-box__field {
			padding: 18px 55px 0px 20px;
			height: 3.44rem;

			& > svg {
				// hide error icon
				display: none;
			}

			& > .cds--list-box__menu-icon {
				margin-top: -17px;
			}

			& > .cds--list-box__selection {
				display: none;
			}

			& > .cds--text-input {
				${({ theme }) => theme.typography.p3};
				color: ${({ theme }) => theme.colors.textoGrisPrimario};
				border: none;
				padding: 0;
			}
		}

		&.cds--list-box--expanded {
			border: 1px solid ${({ theme }) => theme.colors.azul02};

			& > .cds--list-box__field {
				& > .cds--list-box__menu-icon > svg > path {
					stroke: ${({ theme }) => theme.colors.azul02};
				}
			}
		}

		& > .cds--list-box__menu {
			background: white;
			margin-top: 5px;
			border-radius: 8px;

			& > .cds--list-box__menu-item > .cds--list-box__menu-item__option {
				${({ theme }) => theme.typography.buttonMedium};
				margin: 0 1px;
				padding: 0.6875rem 2.5rem 0.6875rem 1.5rem;
				border-top-color: ${({ theme }) => theme.colors.azul05};
			}

			& > .cds--list-box__menu-item--highlighted {
				background: ${({ theme }) => theme.colors.azul05};
				& > .cds--list-box__menu-item__option {
					border-top-color: transparent;
				}
			}

			& > .cds--list-box__menu-item--active {
				background: ${({ theme }) => theme.colors.azul02};
				& > .cds--list-box__menu-item__option {
					color: ${({ theme }) => theme.colors.white};
					& > svg > path {
						stroke: ${({ theme }) => theme.colors.white};
						fill: ${({ theme }) => theme.colors.white};
					}
				}
			}

			&::-webkit-scrollbar {
				width: 5px;
				border-radius: 8px;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 8px;
				background: ${({ theme }) => theme.colors.gris};
			}
			&::-webkit-scrollbar-track {
				border-radius: 8px;
				background: ${({ theme }) => theme.colors.azul05};
			}
		}
	}

	&.invalid {
		& > .cds--label {
			color: ${({ theme }) => theme.colors.alertasError};
			&::after {
				color: ${({ theme }) => theme.colors.alertasError};
			}
		}
		& > .cds--combo-box {
			background: ${({ theme }) => theme.colors.alertasErrorClaro};
			& > .cds--list-box__field {
				& > .cds--text-input {
					outline: none;
				}
				& > .cds--list-box__menu-icon {
					margin-top: 0;
					& > svg > path {
						stroke: ${({ theme }) => theme.colors.alertasError};
					}
				}
			}
		}
	}

	&.disabled {
		& > .cds--label {
			color: ${({ theme }) => theme.colors.textoGrisTerciario};
			&::after {
				color: ${({ theme }) => theme.colors.textoGrisTerciario};
			}
		}

		& > .cds--combo-box {
			background: ${({ theme }) => theme.colors.white};
			border: 1px solid ${({ theme }) => theme.colors.gris};
			outline: none;
			& > .cds--list-box__field > .cds--list-box__menu-icon > svg > path {
				stroke: ${({ theme }) => theme.colors.gris};
			}
		}
	}
`;

const S = {
	ComboBox,
};

export default S;

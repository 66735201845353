import { FunctionComponent, useContext, useEffect } from 'react';
import localforage from 'localforage';
import { useHistory } from 'react-router';
import { ActionType, AppContext } from 'interfaces';
import { Context } from 'context/Context';
import { fetchFeatureFlags } from './FeatureFlags/utils';
import { toast } from 'react-toastify';

export const AuthGuard: FunctionComponent<{ component: JSX.Element }> = ({ component }): JSX.Element => {
	const { dispatch } = useContext(Context) as AppContext;
	const history = useHistory();

	const doesTokenExist = async (): Promise<boolean> => {
		const token: any = await localforage.getItem('token');
		return Boolean(token) && Boolean(token.length);
	};

	const isTokenExpired = async (): Promise<boolean> => {
		const expires: any = await localforage.getItem('expires');
		if (!expires || typeof expires !== 'number') {
			return true;
		}
		return Date.now() > expires;
	};

	const logout = (): void => {
		localforage.clear().then(() => {
			history.replace('/login');
			dispatch({ type: ActionType.LOGOUT });
			fetchFeatureFlags(dispatch);
		});
	};

	const checkAuthorizationToken = async (): Promise<void> => {
		const [tokenExists, tokenIsExpired] = await Promise.all([doesTokenExist(), isTokenExpired()]);
		if (!tokenExists || tokenIsExpired) {
			toast.error('Su sesión ha expirado, por favor inicie sesión nuevamente');
			logout();
		}
	};

	useEffect(() => {
		const interval = setInterval(checkAuthorizationToken, 30000);
		return () => clearInterval(interval);
	}, []);

	return component;
};

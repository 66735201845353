// CheckboxButton.jsx

import React from 'react';
import './styles.scss';

interface CheckboxButtonProps {
	checked: boolean;
	label: string;
	onChange: (checked: boolean) => void;
	isLoading?: boolean;
}

const CheckboxButton = ({ checked, label, onChange, isLoading }: CheckboxButtonProps) => {
	const containerClassName = checked ? 'success' : 'warning';

	const handleCheckboxChange = () => {
		const newChecked = !checked;
		onChange(newChecked);
	};

	return (
		<div className={`checkbox-button ${containerClassName}`}>
			<div className={isLoading ? 'visibility-hidden' : ''} onClick={handleCheckboxChange}>
				<div className={`checkbox-icon ${checked ? 'checked' : ''}`} />
				<label className="label">{label}</label>
			</div>
			{isLoading ? <div className="btn-loader" /> : null}
		</div>
	);
};

export default CheckboxButton;

import React, { useContext, FunctionComponent, useEffect, useState } from 'react';
import Feature from '../../../../utils/FeatureFlags';
import { AppContext, ProviderRetentionBreakdown, ProviderRetentionNames } from '../../../../interfaces';
import { Context } from '../../../../context/Context';
import {
	centsToCurrency,
	getProviderRetentionBreakdown,
	getRetentionsByBranch,
	getSituationType,
	transformDistance,
} from '../../../../utils/Utils';
import { PreOrderService } from '../../../../interfaces';
import { BRANCH } from '../../../../utils/constants';
import { FEATURE_FLAG } from '../../../../utils/FeatureFlags/constants';

interface TableDetailProps {
	service: PreOrderService;
	branch: BRANCH;
	showIVAColumn: boolean;
}

const TableDetail: FunctionComponent<TableDetailProps> = ({ service, branch, showIVAColumn }) => {
	const { situationTypes } = useContext(Context) as AppContext;

	const [situation, setSituation] = useState<string>();
	const [providerDistance, setProviderDistance] = useState<string>();
	const [situationDistance, setSituationDistance] = useState<string>();
	const [providerPaymentSubTotal, setProviderPaymentSubTotal] = useState<string>();
	const [providerPaymentTax, setProviderPaymentTax] = useState<string>();
	const [providerPaymentTotal, setProviderPaymentTotal] = useState<string>();
	const [providerRetentionTotal, setProviderRetentionTotal] = useState<string>();
	const [providerRetentionBreakdown, setProviderRetentionBreakdown] = useState<Record<
		ProviderRetentionNames,
		ProviderRetentionBreakdown
	> | null>();

	useEffect(() => {
		setSituation(() => getSituationType(service.service.situation, situationTypes));
	}, [service.service.situation]);

	useEffect(() => {
		setProviderRetentionBreakdown(() => getProviderRetentionBreakdown(service.service.providerRetentions, branch));
		setProviderRetentionTotal(() => centsToCurrency(service.service.providerPaymentRetention || 0, branch));
	}, [service.service.providerRetentions]);

	useEffect(() => {
		setProviderDistance(() => transformDistance(service.service.providerDistance || 0, branch));
	}, [service.service.providerDistance]);

	useEffect(() => {
		setSituationDistance(() => transformDistance(service.service.situationDistance || 0, branch));
	}, [service.service.situationDistance]);

	useEffect(() => {
		setProviderPaymentSubTotal(() => centsToCurrency(service.service.providerPaymentSubTotal || 0, branch));
		setProviderPaymentTax(() => centsToCurrency(service.service.providerPaymentTax || 0, branch));
		setProviderPaymentTotal(() => centsToCurrency(service.service.providerPaymentTotal || 0, branch));
	}, [
		service.service.providerPaymentSubTotal,
		service.service.providerPaymentTax,
		service.service.providerPaymentRetention,
		service.service.providerPaymentTotal,
	]);

	return (
		<div key={service.service.id} className="table__row">
			<span>{service.service.serviceNumber}</span>
			<span>{service.service.providerName}</span>
			<span>{situation}</span>
			<span>{providerDistance}</span>
			<span>{situationDistance}</span>
			<span>{providerPaymentSubTotal}</span>
			<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_BREAKDOWN}>
				{getRetentionsByBranch(branch).map((retention) => (
					<span key={retention}>{providerRetentionBreakdown?.[retention].currencyValue}</span>
				))}
			</Feature>
			<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_TOTAL}>
				<span>{providerRetentionTotal}</span>
			</Feature>
			{showIVAColumn ? <span>{providerPaymentTax}</span> : null}
			<span>{providerPaymentTotal}</span>
		</div>
	);
};

export default TableDetail;

import { BRANCH } from 'utils/constants';

const DEFAULT_MAP_CENTER: Record<BRANCH, google.maps.LatLngLiteral> = {
	[BRANCH.PRI]: { lat: 18.389978, lng: -66.092863 },
	[BRANCH.PAN]: { lat: 8.96646, lng: -79.539568 },
	[BRANCH.CRC]: { lat: 9.958079, lng: -84.109421 },
	[BRANCH.COL]: { lat: 4.651495, lng: -74.09238 },
	[BRANCH.MEX]: { lat: 19.448045, lng: -99.126447 },
};

export { DEFAULT_MAP_CENTER };

// External imports
import React from 'react';
import styled from 'styled-components';
// Global imports
import startLocationPin from 'assets/imgs/start-location-pin.svg';
import endLocationPin from 'assets/imgs/end-location-pin.svg';
import dashedLine from 'assets/imgs/dashed-line.svg';

interface IProps {
	destinationFieldVisible: boolean;
	secondaryInputsVisible: boolean;
}

interface IStyledContainerProps {
	$height: string;
}

const Container = styled.div<IStyledContainerProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: ${({ $height }) => $height};
	width: 30px;
	gap: 8px;
`;

const StartLocationLegend = styled.div`
	width: 25px;
	& > img {
		width: 100%;
	}
`;

const LocationsLegendConnectionLine = styled.div`
	flex-grow: 1;
	overflow: hidden;
	& > img {
		height: 500px;
	}
`;

const EndLocationLegend = styled.div`
	width: 30px;
	& > img {
		width: 100%;
	}
`;

const DecorativeLocationsLegend = ({ destinationFieldVisible, secondaryInputsVisible }: IProps): React.ReactElement => {
	let height = 'unset';
	if (destinationFieldVisible) {
		if (secondaryInputsVisible) {
			height = '375px';
		} else {
			height = '255px';
		}
	}

	return (
		<Container $height={height}>
			{destinationFieldVisible && (
				<>
					<StartLocationLegend>
						<img src={startLocationPin} />
					</StartLocationLegend>
					<LocationsLegendConnectionLine>
						<img src={dashedLine} />
					</LocationsLegendConnectionLine>
				</>
			)}
			<EndLocationLegend>
				<img src={endLocationPin} />
			</EndLocationLegend>
		</Container>
	);
};

export default DecorativeLocationsLegend;
